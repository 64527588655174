import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';

import styled from 'styled-components';
import {withSocialAuth} from "../../../helpers/SocialAuthHelper";
import iconFb from './../../../assets/images/bt_icon_face.svg'
import iconGoogle from './../../../assets/images/bt_icon_google.svg'

function SocialAuthForm({error, signInWithFacebook, signInWithGoogle, user, ...props}) {
    const {
        onLoaded, onClickSocial = () => {

        }
    } = props;
    const [clicked, setClicked] = useState(false);

    const getUserInfo = (user, type = null) => {
        return {
            ...user.providerData[0]
        }
    }
    useEffect(() => {
        // if(user != null) onLoaded(getUserInfo(user));
        if (user != null && clicked == true) {
            console.log('!!! useEffect: SocialAuthForm: clicked, user =  = ', clicked, user);

            onLoaded(getUserInfo(user));
        }
        console.log('SocialAuthForm: useEffect: user = ', user);
    }, [user, clicked])

    return (
        <Wrapper>
            <ButtonWrapper>
                <Button icon={iconFb} onClick={() => {
                    setClicked(true);
                    onClickSocial();
                    setTimeout(() => {
                        signInWithFacebook();
                    }, 200);
                }}>Continue with Facebook</Button>
                <Button icon={iconGoogle} onClick={() => {
                    setClicked(true);
                    onClickSocial();
                    setTimeout(() => {
                        signInWithGoogle();
                    }, 200);
                }}>Continue with Google</Button>
            </ButtonWrapper>

            <div className={'decor'}>
                <span>or</span>
            </div>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.070613);
  margin-bottom: 30px;
  padding-bottom: 40px;
  
  .decor {
    position: absolute;
    bottom: -10px;
    color: #545454;
    font-size: 15px;
    width: 100%;
    left: 0;
    text-align: center
    
    span {
    background-color: #171922;
    padding: 15px;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;
const Button = styled.div`
    padding: 12px 10px;
    flex: 1;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 5px;
    position: relative;
    
    :first-child {
      margin-right: 15px;
    }
    
    :after {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      background-image: url(${props => props.icon});
      position: absolute;
      top: 11px;
      right: 8px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    
    :hover{
      background-color: rgba(255, 255, 255, 0.15);
      cursor: pointer;
      transition: 0.25s;
    }
`;

export default withSocialAuth(SocialAuthForm);
