import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import ProjectPanel from "../projects/panels/ProjectPanel";
import UpdateArtCodePanel from "../arts/panels/UpdateArtCodePanel";
import WaveTemplatePanel from "../wave/panels/WaveTemplatePanel";

export default function WaveTemplateApp(props) {
    let {id} = props.match.params;

    return (
        <SubtitlesTemplate active={`wave_template/${id}`}>

            <WaveTemplatePanel id={id} />

        </SubtitlesTemplate>
    );
}

const Wrapper = styled.div`
    
`;
