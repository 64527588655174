import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import UserSettingsPanel from "../settings/panels/UserSettingsPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserProfilePanel from "../profile/panels/UserProfilePanel";
import FacebookPlugin from "../ui/FacebookPlugin";

export default function UserProfileApp() {

    return (
        <SubtitlesTemplate active={'profile'}>

            <UserProfilePanel/>
            {/* <FacebookPlugin/> */}

        </SubtitlesTemplate>
    );
}
