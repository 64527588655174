import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import WaveLayer from "./WaveLayer";

import SimpleFabricCanva from '../../fabric/tools/SimpleFabricCanva'
import DraggableRectCanva from '../../fabric/tools/DraggableRectCanva'

export default function DraggableWaveLayer(props) {
    const {
        backgroundImage = undefined,
        width = 500,
        height = 500,
        id = 'aaad_sd',

        onChangeEnd = a => {

        }

    } = props;

    const [top, setTop] = useState(props.top == undefined ? 0 : props.top);
    const [left, setLeft] = useState(props.left == undefined ? 0 : props.left);
    const [waveWidth, setWaveWidth] = useState(props.waveWidth == undefined ? 300 : props.waveWidth);
    const [waveHeight, setWaveHeight] = useState(props.waveHeight == undefined ? 300 : props.waveHeight);

    console.log('DraggableWaveLayer: render: width, height = ', width, height);

    return (
        <Wrapper {...props} >
            <BottomLayer >
                <WaveLayer {...props}
                           iniId={id}
                           top={top} left={left} waveWidth={waveWidth} waveHeight={waveHeight} />
            </BottomLayer>
            <TopLayer >
                <DraggableRectCanva
                    {...props}
                    top={top} left={left} waveWidth={waveWidth} waveHeight={waveHeight}
                    onChange={a => {
                        let dd = {
                            left: a.left,
                            top: a.top,
                            width: a.width * a.scaleX,
                            height: a.height * a.scaleY
                        };
                        setLeft(dd.left);
                        setTop(dd.top);
                        setWaveWidth(dd.width);
                        setWaveHeight(dd.height);
                    }}
                    onChangeEnd={a => {
                        let dd = {
                            left: a.left,
                            top: a.top,
                            width: a.width * a.scaleX,
                            height: a.height * a.scaleY
                        };
                        onChangeEnd(dd);
                    }}
                />
            </TopLayer>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: 0 auto;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    position: relative;
    background: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${props => (props.backgroundImage == undefined ? 'none' : `url(${props.backgroundImage})`)};
`;

const BottomLayer = styled.div`
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
`;

const TopLayer = styled.div`
    position: absolute;
    z-index: 2;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.1);
`;
