import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import SimpleFabricCanva from './SimpleFabricCanva'
import useComponentSize from "@rehooks/component-size/index";

import ProportionsSelector from './ProportionsSelector'
import WavesSelector from "./WavesSelector";
import ImageSelector from "./ImageSelector";
import CommonHelper from "../../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../../constants/config";

const C_ITEMS = [
    {
        image: require('../../../assets/images/slider.svg'),
        label: 'Setup',
        name: 'setup'
    },
    {
        image: require('../../../assets/images/sound.svg'),
        label: 'Wave',
        name: 'wave'
    },
    {
        image: require('../../../assets/images/background.svg'),
        label: 'Background',
        name: 'background'
    },
    {
        image: require('../../../assets/images/progress-bar.svg'),
        label: 'Progress',
        name: 'progress'
    }
];


export default function SimpleFabricEditor(props) {
    const editorRef = useRef();
    const {
        selectedProportion = 1.0,
        onImageSave = u => {

        },
        backgroundImageUrl,
        loading = false
    } = props;
    const contRef = useRef();
    const placeholderRef = useRef();
    let size = useComponentSize(contRef);
    let {width, height} = size;
    const [currentImageUrl, setCurrentImageUrl] = useState();

    useOnClickOutside(placeholderRef, () => {
        console.log('outside click!');
        try {
            editorRef.current.flushFocus();
        } catch (e) {

        }
    });

    let fWidth = width * 1.0;
    let fHeight = height * 1.0;
    let containerRatio = 1.0 * fWidth / fHeight;

    let editorSize = {
        width: width,
        height: height
    }

    if (containerRatio > selectedProportion) { // wider
        editorSize.height = fHeight;
        editorSize.width = 1.0 * fHeight * selectedProportion;
    } else { // narrower
        editorSize.width = fWidth;
        editorSize.height = 1.0 * fWidth / selectedProportion
    }

    console.log('SimpleFabricEditor: render: backgroundImageUrl = ', backgroundImageUrl);

    return (
        <Wrapper>

            <MainContent ref={contRef}>
                <EditorPlaceholder {...editorSize} backgroundImageUrl={backgroundImageUrl} ref={placeholderRef}>
                    <SimpleFabricCanva ref={editorRef}/>
                </EditorPlaceholder>
            </MainContent>

            {backgroundImageUrl != undefined ? null :
                <BottomPlaceholder>

                    <ImageSelector onUploaded={async (imgUrl) => {
                        try {
                            let cachedUrl = await CommonHelper.getCachedUrl(imgUrl);
                            editorRef.current.setBackground(imgUrl, cachedUrl);
                            setCurrentImageUrl(imgUrl);
                            // editorRef.current.setBackground(imgUrl, cachedUrl);
                        } catch (exc) {

                        }
                    }}/>

                    {currentImageUrl == undefined ? null :
                        <SlideItem onClick={async () => {
                            let dataURL = editorRef.current.getDataUrl();
                            const data = new FormData()
                            data.append('file', base64ToBlob(dataURL));
                            let pld = (await axios.post(`${API_ENDPOINT}/upload_image`, data)).data;
                            console.log('pld = ', pld);
                            let url = pld.result.url;
                            console.log('uploaded: url = ', url);
                            onImageSave(url);
                        }}>
                            <Spin visible={loading}/>
                            {loading == true ? null :
                                <span>
                            {' save'}
                        </span>
                            }
                        </SlideItem>
                    }


                </BottomPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const MainContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgb(20, 20, 56, 0.4);
`;

const EditorPlaceholder = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background: rgb(255, 255, 255, 0.4);
    background-image: ${props => (props.backgroundImageUrl != undefined ? `url(${props.backgroundImageUrl})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const BottomPlaceholder = styled.div`
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
`;

const SlideItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 35px;
    margin-right: 35px;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    width: 120px;
    color: white;
    :hover{
      opacity: 0.8;
      background: rgba(211,211,211,0.5);
    }
`;

const SlideImg = styled.img`
    height: 30px;
    margin-bottom: 10px;
`;

const SlideLabel = styled.div`
    text-align: center;
    font-size: 22px;
`;

const ControlsListPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, handler]
    );
}

const base64ToBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1]
    const sliceSize = 1024;
    const byteChars = window.atob(arr[1]);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        let slice = byteChars.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: mime});
}
