import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled, {css} from 'styled-components';
import useComponentSize from '@rehooks/component-size'
import CommonHelper from "../../../helpers/CommonHelper";
import SubtitlesHelper from "../../../helpers/SubtitlesHelper";

import TimelineEditor from "./TimelineEditor";

import VideoStyleEditor from "./VideoStyleEditor";
import FormattedSubtitle from "./FormattedSubtitle";
import ReactHelper from "../../../helpers/ReactHelper";
import SubsTextsEditor from "./SubsTextsEditor";
import {useDebouncedCallback} from "use-debounce";
import ReactSlider from "react-slider";

const playIcon = require('../images/play_vect.svg');
const stopIcon = require('../images/pau_vect.svg');
const mutedIcon = require('../images/mute_q.svg');
const notMutedIcon = require('../images/no_mute_.svg');

const getProportion = (apOptions) => {
    if (apOptions == undefined || apOptions.proportion == undefined) {
        return 1;
    }
    let [a, b] = apOptions.proportion.split(':');
    return (1.0 * (+a) / +b);
}

const getNum = a => {
    if (a == undefined || window.isNaN(`${a}`.trim())) {
        return a;
    }
    return (+a);
}

const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

const scrollAndFocus = (elId) => {
    console.log('scrollAndFocus: elId = ', elId);
    try {
        // document.getElementById(elId).scrollIntoView({behavior: 'smooth', inline: 'center'});
        document.getElementById(elId).scrollIntoView({behavior: 'smooth'});
        document.getElementById(elId).focus();
    } catch (e) {

    }
}

const TABS_OPTIONS = [
    {
        label: 'Subtitles',
        icon: require('../images/sub_mdi.svg'),
        value: 'subtitles'
    },
    {
        label: 'Style',
        icon: require('../images/style_mdi.svg'),
        value: 'style'
    }
]

async function playVideo(videoElem) {
    console.log('playVideo occured! videoElem = ', videoElem);
    try {
        await videoElem.play();
    } catch (err) {
        console.log('playVideo err = ', err);
    }
}


const anyTextareaHasFocus = () => {
    try {
        let activeElement = document.activeElement;
        if (activeElement == undefined || activeElement.id == undefined || activeElement.id.indexOf('area_') == -1) {
            return false;
        }
    } catch (e) {
        return false;
    }
    return true;
};

function isVisible(e) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}

function isScrolledIntoView(el) {
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    return isVisible;
}

const setActiveTextBox = (id) => {
    console.log('setActiveTextBox: id = ', id);
    try {
        let cls = `active-left-box`;
        let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        Array.from(document.getElementsByClassName("active-left-box")).forEach(
            (ele, index, array) => {
                ele.className = ele.className.replace(reg, ' ');
            }
        );
        let fEl = document.getElementById(`text_box_${id}`);
        let bEl = document.getElementById(`box_${id}`);
        fEl.className += ` ${cls}`;
        if (!isScrolledIntoView(fEl)) {
            fEl.scrollIntoView();
        }
        if (!isScrolledIntoView(bEl)) {
            bEl.scrollIntoView();
        }
    } catch (e) {
        console.log('setActiveTextBox: exc = ', e);
    }
}

const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export default function ClideoEditor(props) {
    const {

        timerMs = 20,

        maxWidthPerSecond = 100,

        optionsData = {
            proportion: '1:1',
            fontWeight: 'normal',
            fontStyle: 'normal',
            textAlign: 'center',
            bottomOffset: 0, // in percent
            lineHeight: 6, // in percent
            fontSize: 5, // in percent

            videoScale: 'fit',
            color: '#FFFFFF',
            backgroundColor: '#291C1C',

            backgroundType: 'full',
            overlayBackground: 'rgba(0, 0, 0, 0.7)'

        },

        subtitles = [],
        duration = 10,
        videoUrl = undefined,
        onSubtitlesChange = (newSubtitles) => {

        },
        onAppearanceChange = app => {

        },
        onBack = () => {

        }
    } = props;

    const timelineRef = useRef();
    const videoRef = useRef();
    const currTimeSpan = useRef();
    const playerPositionRef = useRef(0);
    const subtitlesRef = useRef(subtitles);
    const subTextBoxRef = useRef();
    const textsEditorRef = useRef();
    const beforeActiveIdRef = useRef();
    const [canPlay, setCanPlay] = useState(false);

    const [widthPerSecond, setWidthPerSecond] = useState(Math.round(Math.max((1.0 * window.innerWidth / duration + maxWidthPerSecond) / 2.0, 1.0, window.innerWidth / duration)));
    const [subtitlesExpanded, setSubtitlesExpanded] = useState(false);
    const [tabMode, setTabMode] = useState('subtitles');
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(isSafari());
    const [editorData, setEditorData] = useState(optionsData);

    const playerContRef = useRef(undefined);
    const timelinePlaceholderRef = useRef(undefined);
    let playerPlaceholderSize = useComponentSize(playerContRef);
    let timelinePlaceholderSize = useComponentSize(timelinePlaceholderRef);
    const userVideoSize = CommonHelper.getContainerDimensions(playerPlaceholderSize, getProportion(editorData));
    const hasHeight = !(userVideoSize == undefined || userVideoSize.height == undefined);

    const totalDurString = SubtitlesHelper.toHHMMSS(duration);

    useEffect(() => {
        console.log('videoRef.current = ', videoRef.current);
        if (subtitles.length > 0) {
            videoRef.current.currentTime = subtitles[0].from + 0.05;
        }
    }, []);

    useEffect(() => {
        try {
            let t = videoRef.current.currentTime;
            let isPlaying = isVideoPlaying(videoRef.current);
            videoRef.current.load();
            videoRef.current.currentTime = t;
            if (isPlaying) {
                playVideo(videoRef.current).then(a => {

                });
                // videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        } catch (e) {

        }
    }, [videoUrl]);

    useEffect(() => {
        if (playing == true) {
            try {
                playVideo(videoRef.current).then(a => {

                });
            } catch (e) {

            }
        } else {
            try {
                videoRef.current.pause();
            } catch (e) {

            }
        }
    }, [playing]);

    ReactHelper.useInterval(() => {
        let t = 0;
        let isPlaying = isVideoPlaying(videoRef.current);
        try {
            let t = videoRef.current.currentTime;
            playerPositionRef.current = t;
            currTimeSpan.current.innerText = `${SubtitlesHelper.toHHMMSS(t)} `;
            // if (isPlaying) {
            timelineRef.current.setTimePosition(t);
            // }
            let currSubs = SubtitlesHelper.getCurrentSubtitles(t, subtitlesRef.current);
            let currText = currSubs.map(a => a.text.replace(/\n/g, '<br/>')).join('<br/>');
            let currSub = (currSubs.length == 0) ? undefined : currSubs[0]; // first one
            let currSubId = (currSub == undefined) ? undefined : currSub.id;
            // subTextBoxRef.current.setText(currSub == undefined ? '' : currSub.text);
            subTextBoxRef.current.setText(currSubs.length == 0 ? '' : currText);

            if (currSubId != beforeActiveIdRef.current && currSubId != undefined) {
                if (!anyTextareaHasFocus()) {
                    scrollAndFocus(`area_${currSubId}`);
                }
                setActiveTextBox(currSubId);
            }

            beforeActiveIdRef.current = currSubId;
        } catch (e) {

        }
    }, timerMs);

    const [onDebouncedSubtitlesChange] = useDebouncedCallback(
        () => {
            onSubtitlesChange(subtitlesRef.current);
        },
        1000
    );
    const [onDebouncedAppearanceChange] = useDebouncedCallback(
        () => {
            onAppearanceChange(editorData);
        },
        500
    );

    let t = (videoRef == undefined || videoRef.current == undefined) ? 0 : videoRef.current.currentTime;
    console.log('tabMode = ', tabMode);

    let minWidthPerSecond = Math.floor(1.0 * timelinePlaceholderSize.width / duration);

    let zoomIsDisabled = (widthPerSecond > maxWidthPerSecond + 1 || widthPerSecond < minWidthPerSecond - 1);

    return (
        <Wrapper>
            <Top>

                <TopRight>

                    <TopTabsPlaceholder>
                        <BackButtonPlaceholder>
                            <BackButtonImg src={require('../images/back_img.svg')}
                                           onClick={() => {
                                               onBack();
                                           }}
                            />
                        </BackButtonPlaceholder>
                        {TABS_OPTIONS.map((a, i) => {
                            return (
                                <TabNameItem selected={(a.value == tabMode)} key={i} onClick={() => {
                                    setTabMode(a.value);
                                }}>
                                    <TabNameIcon src={a.icon}/>
                                    <TabLabel>
                                        {a.label}
                                    </TabLabel>
                                </TabNameItem>
                            )
                        })}
                        <div style={{width: 28, position: 'absolute', right: 0}}>

                        </div>
                    </TopTabsPlaceholder>

                    <TopRightContent>
                        {tabMode != 'style' ? null :
                            <VideoStyleEditor {...editorData}
                                              originalWidth={props.width}
                                              originalHeight={props.height}
                                              viewVideoHeight={userVideoSize.height}
                                              onChange={a => {
                                                  setEditorData({...editorData, ...a});
                                                  onDebouncedAppearanceChange();
                                              }}/>
                        }

                        {tabMode != 'subtitles' ? null :
                            <SubsTextsEditor
                                totalDuration={duration}
                                expanded={subtitlesExpanded}
                                ref={textsEditorRef}
                                subtitles={subtitlesRef.current}
                                onChange={newSubs => {
                                    subtitlesRef.current = newSubs;
                                    timelineRef.current.setSubtitles(newSubs);
                                    onDebouncedSubtitlesChange();
                                }}
                                onSeek={tt => {
                                    try {
                                        videoRef.current.currentTime = tt;
                                    } catch (e) {

                                    }
                                }}
                                onPause={() => {
                                    setPlaying(false);
                                }}
                                onExpand={() => {
                                    setSubtitlesExpanded(!subtitlesExpanded);
                                }}
                                onBoxSelect={subId => {
                                    console.log('onBoxSelect: subId = ', subId);
                                    try {
                                        timelineRef.current.scrollToBox(subId);
                                    } catch (e) {

                                    }
                                }}
                            />
                        }
                    </TopRightContent>


                </TopRight>

                <TopLeft>

                    <SubPlayerPlaceholder>
                        {props.topRightContent}
                    </SubPlayerPlaceholder>

                    <PlayerPlaceholder ref={playerContRef}>
                        <PlayerBox {...userVideoSize} backgroundColor={editorData.backgroundColor}>
                            <PlayerBoxInner>
                                <VideoPlayerPlaceholder>
                                    <VideoPlayer

                                        preload={'auto'}
                                        videoScale={editorData.videoScale}
                                        muted={muted}
                                        onClick={() => {
                                            // setPlaying(!playing);
                                        }}
                                        ref={videoRef}
                                        onEnded={() => {
                                            console.log('ended!');
                                            setPlaying(false);
                                            try {
                                                videoRef.current.currentTime = duration;
                                            } catch (e) {

                                            }
                                        }}

                                        onCanPlay={evt => {
                                            console.log('VideoPlayer: onCanPlay occured! evt = ', evt);
                                            if (canPlay == false) {
                                                setCanPlay(true);
                                            }
                                        }}
                                    >
                                        <source src={videoUrl}/>
                                    </VideoPlayer>
                                </VideoPlayerPlaceholder>
                                <SubtitlesLayer>
                                    <SubtitlesLayerInner>
                                        <FormattedSubtitle {...editorData}
                                                           bottom={!hasHeight ? 0 : userVideoSize.height * getNum(editorData.bottomOffset) / 100.0}
                                                           fontSize={!hasHeight ? 0 : userVideoSize.height * getNum(editorData.fontSize) / 100.0}
                                                           lineHeight={!hasHeight ? 0 : userVideoSize.height * getNum(editorData.lineHeight) / 100.0}
                                                           ref={subTextBoxRef}
                                        />
                                    </SubtitlesLayerInner>
                                </SubtitlesLayer>
                            </PlayerBoxInner>
                        </PlayerBox>
                        {canPlay == true ? null :
                            <PlayerCanNotLoadPlaceholder>
                                <PlayerLoadingMessagePlaceholder>
                                    Initializing player...
                                </PlayerLoadingMessagePlaceholder>
                            </PlayerCanNotLoadPlaceholder>
                        }
                    </PlayerPlaceholder>
                    <PlayerControlsPlaceholder>
                        <PlayerControlsBox>
                            <PlayerBoxSide style={{justifyContent: 'flex-start'}}>
                                <PlayerButtonNarrowWrapper onClick={() => {
                                    setMuted(!muted);
                                }}>
                                    <PlayerButton src={muted ? mutedIcon : notMutedIcon}/>
                                </PlayerButtonNarrowWrapper>
                            </PlayerBoxSide>
                            <PlayerBoxCenter>

                                <PlayerButtonNarrowWrapper onClick={() => {
                                    try {
                                        let prevSub = SubtitlesHelper.getPrevSubtitle(videoRef.current.currentTime, subtitlesRef.current);
                                        if (prevSub == undefined) {
                                            // let currSub = SubtitlesHelper.getCurrentSubtitle(videoRef.current.currentTime, subtitlesRef.current);
                                            // if (currSub != undefined) {
                                            //     videoRef.current.currentTime = currSub.from;
                                            //     timelineRef.current.setTimePosition(currSub.from);
                                            // }
                                            videoRef.current.currentTime = 0;
                                            timelineRef.current.setTimePosition(0);
                                        } else {
                                            videoRef.current.currentTime = prevSub.from;
                                            timelineRef.current.setTimePosition(prevSub.from);
                                        }

                                    } catch (e) {

                                    }
                                }}>
                                    <PlayerButton src={require('../images/back_rew.svg')}/>
                                </PlayerButtonNarrowWrapper>

                                <PlayerButtonWrapper onClick={() => {
                                    setPlaying(!playing);
                                }}>
                                    <PlayerButton
                                        style={{height: 14}}
                                        key={`${playing}_df`} src={playing ? stopIcon : playIcon}/>
                                </PlayerButtonWrapper>

                                <PlayerButtonNarrowWrapper onClick={() => {
                                    try {
                                        let prevSub = SubtitlesHelper.getNextSubtitle(videoRef.current.currentTime, subtitlesRef.current);
                                        videoRef.current.currentTime = prevSub.from;
                                        timelineRef.current.setTimePosition(prevSub.from);
                                    } catch (e) {

                                    }
                                }}>
                                    <PlayerButton src={require('../images/back_rew.svg')}
                                                  style={{transform: `rotate(180deg)`}}/>
                                </PlayerButtonNarrowWrapper>

                            </PlayerBoxCenter>
                            <PlayerBoxSide>
                                <span style={{fontWeight: 'bold'}} ref={currTimeSpan}></span>
                                <span style={{marginLeft: 3, marginRight: 3}}>
                                    {' / '}
                                </span>
                                {`${totalDurString}`}
                            </PlayerBoxSide>
                        </PlayerControlsBox>
                    </PlayerControlsPlaceholder>
                </TopLeft>

            </Top>
            <Bottom>
                <TimeLinePlaceholder ref={timelinePlaceholderRef}>
                    <TimelineEditor totalDuration={duration}
                                    playing={playing}
                                    widthPerSecond={widthPerSecond}
                                    subtitles={subtitlesRef.current}
                                    ref={timelineRef}
                                    onChange={newSubtitles => {
                                        try {
                                            subtitlesRef.current = newSubtitles;
                                            timelineRef.current.setSubtitles(newSubtitles);
                                            textsEditorRef.current.setSubtitles(newSubtitles);
                                            onDebouncedSubtitlesChange();
                                        } catch (e) {

                                        }
                                    }}
                                    onStartChange={() => {
                                        // try {
                                        //     setPlaying(false);
                                        //     videoRef.current.pause();
                                        // } catch (e) {
                                        //
                                        // }
                                    }}
                                    onSeek={(t, shouldPlay = false) => {
                                        try {
                                            // setPlaying(false);
                                            videoRef.current.currentTime = t;
                                            timelineRef.current.setTimePosition(t);
                                            if (shouldPlay) {
                                                setTimeout(() => {
                                                    try {
                                                        setPlaying(true);
                                                        // videoRef.current.play();
                                                    } catch (e) {

                                                    }
                                                }, 30);
                                            }
                                        } catch (e) {

                                        }
                                    }}
                    />
                </TimeLinePlaceholder>
                <VeryBottomControlsPlaceholder>
                    <BottomContentPlaceholder>
                        <BottomLeft>
                            <ZoomPlaceholder disabled={zoomIsDisabled}>
                                <ContImg disabled={zoomIsDisabled} src={require('../images/zoom_out.svg')}
                                         onClick={() => {
                                             if (zoomIsDisabled == true) {
                                                 return;
                                             }
                                             setWidthPerSecond(Math.max(minWidthPerSecond, widthPerSecond - 1));
                                         }}/>
                                <ZoomRangePlaceholder>
                                    <StyledSlider
                                        value={widthPerSecond}
                                        renderTrack={Track}
                                        renderThumb={Thumb}
                                        max={maxWidthPerSecond}
                                        min={minWidthPerSecond}
                                        invert={false}
                                        onChange={a => {
                                            if (zoomIsDisabled == true) {
                                                return;
                                            }
                                            setWidthPerSecond(a);
                                        }}
                                    />
                                </ZoomRangePlaceholder>
                                <ContImg disabled={zoomIsDisabled} src={require('../images/zoom_in.svg')}
                                         onClick={() => {
                                             if (zoomIsDisabled == true) {
                                                 return;
                                             }
                                             setWidthPerSecond(Math.min(maxWidthPerSecond, widthPerSecond + 1));
                                         }}/>
                            </ZoomPlaceholder>
                        </BottomLeft>
                        <BottomRight>
                            {props.bottomRightContent}
                        </BottomRight>
                    </BottomContentPlaceholder>
                </VeryBottomControlsPlaceholder>
            </Bottom>
        </Wrapper>
    );
}

const ZoomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  justify-content: space-between;
  opacity: ${props => (props.disabled == true ? 0.3 : 1)}
`;

const ZoomRangePlaceholder = styled.div`
  width: 100px;
`;

const ContImg = styled.img`
  height: 18px;
  width: 18px;
  cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.5;

  :hover {
    opacity: ${props => (props.disabled == true ? 0.5 : 0.9)};
  }
`;

const sliderHeight = 10;
const thumbHeight = sliderHeight * 2.4;


const StyledSlider = styled(ReactSlider)`
  height: ${sliderHeight}px;
`;


const StyledThumb = styled.div`
  margin-top: ${-((thumbHeight - sliderHeight) / 2.0)}px;
  width: ${thumbHeight}px;
  height: ${thumbHeight}px;
  line-height: ${thumbHeight}px;
  height: ${thumbHeight}px;
  text-align: center;
  //background-color: #000;
  background-color: #FFFFFF;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  font-size: ${thumbHeight * 0.5}px;
  box-sizing: border-box;
  border: 1px solid whitesmoke;
`;

const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
  background: #3F435A;
  top: 0;
  bottom: 0;
  background: ${props => props.index === 2 ? '#f00' : props.index === 1 ? '#2C2C2E' : '#FFAC30'};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index}/>;


const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: #171822;
`;

const bottomHeight = 160 + 16;

const Top = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const Bottom = styled.div`
  background: #202022;
  width: 100%;
  box-sizing: border-box;
  height: ${bottomHeight}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
`;

const TopRight = styled.div`
  width: 380px;
  background: #171822;
  max-height: calc(100vh - ${bottomHeight}px);
  overflow-y: hidden;
  border-right: 1px solid #3a3a3c;
  box-sizing: border-box;
`;

const TopRightContent = styled.div`
  max-height: calc(100vh - ${bottomHeight}px - 62px);
  height: calc(100vh - ${bottomHeight}px - 62px);
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
`;

const TopTabsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #212330;
  border: 2px solid rgba(255, 255, 255, 0.03);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
`;

const BackButtonPlaceholder = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BackButtonImg = styled.img`
  height: 30px;
  width: 18px;
  cursor: pointer;
`;

const TabNameItem = styled.div`
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
  background: ${props => (props.selected ? '#00A3AB' : '#212330')};
  border-radius: 6px;
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};

  :hover {
    opacity: 0.8;
  }
`;

const TabNameIcon = styled.img`
  margin-right: 5px;
  height: 20px;
  width: 20px;
`;

const TabLabel = styled.div`
  font-weight: bold;
`;

const TopLeft = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0px;
  //background: orange;
  flex-direction: column;
  align-items: stretch;
`;


const PlayerPlaceholder = styled.div`
  width: 100%;
  //background: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
`;

const PlayerCanNotLoadPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const PlayerLoadingMessagePlaceholder = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 21px;
`;

const PlayerControlsPlaceholder = styled.div`
  height: 60px;
  //background: wheat;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubPlayerPlaceholder = styled.div`
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const PlayerControlsBox = styled.div`
  box-sizing: border-box;
  padding: 5px;
  //background: gray;
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 920px) {
    width: 100%;
  }
`;

const TimeLinePlaceholder = styled.div`
  width: 100%;
  height: ${110 + 16}px;
  overflow-y: hidden;
`;

const VeryBottomControlsPlaceholder = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
`;

const PlayerBox = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  background-color: ${props => props.backgroundColor};
`;

const PlayerBoxInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const VideoPlayerPlaceholder = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const SubtitlesLayer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const SubtitlesLayerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const PlayerBoxCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PlayerBoxSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 120px;
  color: white;
  font-size: 17px;
`;

const PlayerButton = styled.img`
  height: 14px;
  cursor: pointer;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const PlayerButtonWrapper = styled.div`
  width: 80px;
  height: 43px;
  background: #FF356B;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  :hover {
    opacity: 0.9;
  }
`;

const PlayerButtonNarrowWrapper = styled.div`
  width: 43px;
  height: 43px;
  background: #212330;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  :hover {
    opacity: 0.9;
  }
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;

  ${props => props.config && css`
    background: ${props.config.panelBg};
  `}

  ${props => props.videoScale && props.videoScale === 'zoom' && css`
    object-fit: cover;
    object-position: center;
  `}
`;


const BottomContentPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
`;

const BottomLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const BottomRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
