import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import * as actions from "../../../redux/actions/ProjectsActions";
import ProjectItemView from "../tools/ProjectItemView";
import CommonHelper, {downloadS3VideoAsset} from "../../../helpers/CommonHelper";
import * as download from 'downloadjs'
import CoolModal from '../../modals/CoolModal'
import UpdateProjectNameForm from "../forms/UpdateProjectNameForm";
import UploadVideoPanelWithConfirmation from "../../upload/UploadVideoPanelWithConfirmation";
import {PUBLIC_URL} from "../../../constants/config";
import ProjectsAPI from "../../../api/ProjectsAPI";
import SubtitlesHelper from "../../../helpers/SubtitlesHelper";
import UserPlansStatsPanel from "./UserPlansStatsPanel";
import StateHelper from "../../../helpers/StateHelper";
import AnalyticsAPI from "../../../api/AnalyticsAPI";
import * as plansActions from "../../../redux/actions/PlansActions";
import TelegramAPI from "../../../api/TelegramAPI";
import UploadSrtForm from "../forms/UploadSrtForm";

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export default function UserWorkspacePanel(props) {
    const {
        loading, projects, isAdmin, currentUserId, getProjectJobs,
        currentUser,
        balance,
        leftNumber, currentPlan, videoBalance
    } = useMappedState(useCallback(state => {
        let allJobs = state.jobs.jobsMap.toArray();
        let getProjectJobs = prId => allJobs.filter(a => (a.projectId == prId));
        return {
            loading: state.users.loading || state.projects.loading,
            balance: state?.users?.balance || 0,
            projects: state.projects.projectsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            getProjectJobs: getProjectJobs,
            leftNumber: StateHelper.getAcaliableVideoCredits(state),
            currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
            videoBalance: state.users.videoBalance
        }
    }, []));
    const dispatch = useDispatch();
    const [progressData, setProgressData] = useState(undefined);
    const [uploading, setUploading] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [selectedImportId, setSelectedImportId] = useState(undefined);


    useEffect(() => {
        dispatch(actions.loadUserProjects(currentUserId));
        dispatch(plansActions.loadAllPlans());
    }, [currentUserId]);

    let selectedProject = (selectedId == undefined) ? undefined : projects.filter(a => (a.id == selectedId))[0];
    let selectedImportProject = (selectedImportId == undefined) ? undefined : projects.filter(a => (a.id == selectedImportId))[0]

    console.log('render: currentPlan = ', currentPlan);

    // let hasWatermark = (currentPlan == undefined);
    let hasWatermark = (+videoBalance.subscribed <= 0);

    if (isMobileDevice()) {
        return (
            <MobileOverlay>
                <MobileOverlayInner>

                    <img src={require('../../../assets/images/mobile_face.png')} style={{width: 178, height: 178}}/>

                    <MobileText>
                        Subtitles.Love works best on desktop, so we're looking forward to seeing you on desktop version.
                    </MobileText>
                    <BottomRedMobileButton onClick={() => {

                    }}>
                        OK
                    </BottomRedMobileButton>

                </MobileOverlayInner>
            </MobileOverlay>
        )
    }
    // let userTotalProjectsNumber = projects.length;
    // let canNotUpload = (userTotalProjectsNumber >= 3 && window.IS_FREE_USER);
    return (
        <Wrapper>
            <TopPlaceholder>
                <TopName>
                    Workspace
                </TopName>
                <TopRight>
                    <UserPlansStatsPanel/>
                </TopRight>
            </TopPlaceholder>

            <Content>
                <InlineYellowBox>
                    <UploadVideoPanelWithConfirmation
                        userId={currentUserId}
                        currentUser={currentUser}
                        quotaOver={(balance <= 0)}
                        hasWatermark={false}
                        onUploaded={async pld => {
                            console.log('onUploaded: pld = ', pld);
                            dispatch(actions.createProjectSuccess(pld))
                            ProjectsAPI.updateBalance().then((data) => {
                                console.log('Log:> [UserWorkspacePanel.js]-----balance :=', data);
                            })
                            // let pr = await dispatch(actions.createProject({
                            //     userId: currentUserId,
                            //     originalUrl: pld.url,
                            //     originalAwsKey: pld.awsKey,
                            //     name: `${pld.name}`,
                            //     // name: `${pld.name} - ${moment().format('DD.MM.YYYY HH:mm:ss')}`,
                            //     language: pld.lang
                            // }));
                            AnalyticsAPI.reachGoal('create_project');
                            // CommonHelper.linkTo(`/project/${pr.project.id}`);
                            CommonHelper.linkTo(`/project/${pld.id}`);
                        }}
                        onStart={() => {

                            setUploading(true);
                        }}
                        onProgress={d => {
                            setProgressData(d);
                        }}
                    />
                </InlineYellowBox>

                {uploading == true ?
                    <UploadingModal>
                        <UploadingMessage>
                            {progressData == undefined ? null :
                                <>
                                <UploadingPercentsPlaceholder>
                                    {`${(+progressData.progress).toFixed(0)}%`}
                                </UploadingPercentsPlaceholder>
                                <UploadingText>
                                    {Math.round(+progressData.progress) == 100.0 ? `Processing video...` : `Uploading video...`}
                                </UploadingText>
                                </>
                            }
                        </UploadingMessage>
                    </UploadingModal> :
                    projects.filter(a => (a.deleted != true)).map((pr, j) => {
                        let jobs = getProjectJobs(pr.id).sort((a, b) => (+b.timestamp - +a.timestamp));
                        let latestJob = (jobs.length == 0) ? undefined : jobs[0];
                        let allJobsHaveWatermark = jobs.reduce((f, jb) => (f && (jb.hasWatermark == true)), true);
                        return (
                            <InlineBox key={`${pr.id}_${j}`}>
                                <ProjectItemView {...pr} onGo={() => {
                                    if (jobs.length == 0 || allJobsHaveWatermark == true) {
                                        CommonHelper.linkTo(`project/${pr.id}`);
                                    } else {
                                        CommonHelper.linkTo(`project/${pr.id}/view`);
                                    }
                                }}
                                                 onDelete={async () => {
                                                     if (window.confirm('Are you sure?') == false) {
                                                         return;
                                                     }
                                                     await dispatch(actions.updateProject({id: pr.id, deleted: true}))
                                                     // await dispatch(actions.deleteProject(pr.id))
                                                     await TelegramAPI.sendMessageToAdmins(`[DELETE]: User ${currentUser.email} deleted project`);
                                                 }}
                                                 onDuplicate={async () => {
                                                     if (loading == true) {
                                                         return;
                                                     }
                                                     let pld = await dispatch(actions.cloneProject(pr.id));
                                                     let newProject = pld.project;
                                                     if (newProject != undefined) {
                                                         window.location.href = `${PUBLIC_URL}/#/project/${newProject.id}`;
                                                     }
                                                 }}



                                                 onShare={() => {

                                                 }}
                                                 onEdit={() => {
                                                     CommonHelper.linkTo(`project/${pr.id}`);
                                                 }}
                                                 onDownload={() => {
                                                     if (latestJob == undefined) {
                                                         return;
                                                     }
                                                     downloadS3VideoAsset(latestJob?.resultUrl, `${pr?.name.split('.')[0]}_${pr?.width}x${pr?.height}`)


                                                 }}
                                                 paid={!hasWatermark}
                                                 onDownloadSrt={async () => {
                                                     if (pr.hasWatermark == true) {
                                                         CommonHelper.linkTo(`/pricing`);
                                                         return;
                                                     }
                                                     try {
                                                         let subs = await ProjectsAPI.getProjectSubtitles(pr.id);
                                                         let srcText = SubtitlesHelper.getSrtString(subs);
                                                         CommonHelper.downloadFile(`${pr.name}.srt`, srcText);
                                                     } catch (e) {

                                                     }
                                                     await TelegramAPI.sendMessageToAdmins(`[SRT DOWNLOAD]: User ${currentUser.email} downloaded SRT file`);
                                                 }}
                                                 onRename={() => {
                                                     setSelectedId(pr.id);
                                                 }}
                                                 onImportSrt={() => {
                                                     setSelectedImportId(pr.id);
                                                 }}
                                />
                            </InlineBox>
                        )
                    })
                }

            </Content>

            {selectedProject == undefined ? null :
                <CoolModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <UpdateProjectNameForm {...selectedProject} loading={loading} onSave={async d => {
                        await dispatch(actions.updateProject({id: selectedId, ...d}));
                        setSelectedId(undefined);
                    }}/>

                </CoolModal>
            }

            {selectedImportProject == undefined ? null :
                <CoolModal width={720} onClose={() => {
                    setSelectedImportId(undefined);
                }}>

                    <UploadSrtForm onSubtitlesUpdate={async subs => {
                        console.log('onSubtitlesUpdate: subs = ', subs);
                        await ProjectsAPI.updateProjectSubtitles(selectedImportProject.id, subs);
                        CommonHelper.linkTo(`/project/${selectedImportProject.id}`);
                        setSelectedImportId(undefined);
                        // window.location.href = `${PUBLIC_URL}/#/project/${newProject.id}`;
                    }}/>

                </CoolModal>
            }

        </Wrapper>
    );
}

const MobileOverlay = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    box-sizing: border-box;
    overflow: hidden !important;
    background: #1E2235;
`;

const MobileText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 44px;
`;

const BottomRedMobileButton = styled.div`
    background: #EB5E4F;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    width: 170px;
    height: 46px;
    border-radius: 5px;
`;

const MobileOverlayInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden !important;
`;

const Wrapper = styled.div`
    position: relative;
`;

const UploadingModal = styled.div`
    position: fixed;
    top: 0px; 
    left: 0px;
    right: 0px;
    bottom: 0px;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #181923;
`;


const UploadingPercentsPlaceholder = styled.div`
    text-align: center;
    color: white;
    align-items: center;
    letter-spacing: -1.25px;
    font-size: 64px;
    line-height: 48px;
    font-weight: bold;
`;

const UploadingText = styled.div`
    margin-top: 40px;
    text-align: center;
    color: white;
`;

const UploadingMessage = styled.div`
    
`;

const Content = styled.div`
    margin-top: 40px;
    margin-left: -10px;
`;

const InlineBox = styled.div`
    width: calc(33% - 20px);
    box-sizing: border-box;
    margin: 10px;
    padding: 20px;
    background: #212330;
    border-radius: 12px;
    display: inline-block;
    color: white;
    vertical-align: top;
    height: 230px;
`;

const InlineYellowBox = styled(InlineBox)`
    background: #FFAC30;
    box-shadow: 0px 0px 40px rgba(255, 172, 48, 0.25);
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopName = styled.div`
    font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 46px;
/* identical to box height, or 135% */


color: #FFFFFF;

`;

const TopRight = styled.div`
    padding-right: 20px;    
`;
