import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import SimpleUploadPanel from "../upload/SimpleUploadPanel";
import UploadVideoPanel from "../upload/UploadVideoPanel";
import SimpleFabricEditor from "../fabric/tools/SimpleFabricEditor";
import SimpleWaveEditor from "../wave/tools/SimpleWaveEditor";
import CachedAnimationPlayer from "../wave/tools/CachedAnimationPlayer";
import WaveLayer from "../arts/view/WaveLayer";
import DraggableWaveLayer from "../arts/view/DraggableWaveLayer";
import UploadVideoPanelWithConfirmation from "../upload/UploadVideoPanelWithConfirmation";
import TimelineEditor from "../clideo/tools/TimelineEditor";
import FakeLogsTool from "../fake/FakeLogsTool";
import StepsTool from "../workspace/tools/StepsTool";
import SubtitlesHelper from "../../helpers/SubtitlesHelper";
import FakeProgressBar from "../fake/FakeProgressBar";
import CommonHelper from "../../helpers/CommonHelper";
import ProjectsAPI from "../../api/ProjectsAPI";
import PaddleCheckoutTool from "../paddle/tools/PaddleCheckoutTool";

export default function DevApp() {
    const [elapsed, setElapsed] = useState(10);
    const max = 100;
    let perc = (window.isNaN(elapsed)) ? 'N/A' : SubtitlesHelper.getFakeProgressPercents(+elapsed, max);

    return (
        <Wrapper>

            <PaddleCheckoutTool/>

            {/*<div style={{width: 800, height: 500}}>*/}
            {/*<StepsTool/>*/}
            {/*</div>*/}

            {/*<input value={elapsed} onChange={evt => {*/}
                {/*setElapsed(evt.target.value);*/}
            {/*}}/>*/}
            {/*<div style={{color: 'white'}}>*/}
                {/*{`perc = ${perc}`}*/}
            {/*</div>*/}

            {/*<div style={{width: '60vw', height: 20}}>*/}
                {/*<FakeProgressBar duration={120}/>*/}
            {/*</div>*/}

            {/*<div style={{width: '100vw', height: 100}}>*/}
            {/*<TimelineEditor/>*/}
            {/*</div>*/}

            {/*<div onClick={async () => {*/}
            {/*    let prId = 'hygbIVy4JI';*/}
            {/*    let subs = await ProjectsAPI.getProjectSubtitles(prId);*/}
            {/*    let srcText = SubtitlesHelper.getSrtString(subs);*/}
            {/*    CommonHelper.downloadFile(`file.srt`, srcText);*/}
            {/*}} >*/}
            {/*    hac*/}
            {/*</div>*/}

            {/*<UploadVideoPanelWithConfirmation/>*/}

            {/*<UploadVideoPanel onUploaded={url => {*/}
            {/*console.log('uploaded: url = ', url);*/}
            {/*}}>*/}
            {/*<div>*/}
            {/*upload shit*/}
            {/*</div>*/}
            {/*</UploadVideoPanel>*/}

            {/*<CachedAnimationPlayer width={500} height={500} />*/}

            {/*<EditorPlaceholder>*/}
            {/*<DraggableWaveLayer*/}
            {/*backgroundImage={'https://images3.alphacoders.com/772/772829.jpg'}*/}
            {/*width={500} height={500} waveWidth={300} waveHeight={300} />*/}
            {/*</EditorPlaceholder>*/}


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const EditorPlaceholder = styled.div`
    height: 100vh;
    width: 100vw;
`;

const CanvPlaceholder = styled.div`
    background: brown;
    width: 500px;
    height: 500px;
    margin: 0 auto;
    margin-top: 40px;
`;
