import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/PlansActions";
import PricingTool from "../tools/PricingTool";

import BillingAPI from '../../../api/BillingAPI'
import UserBalancePanel from "../../billing/panels/UserBalancePanel";

export default function UserSubscriptionsPanel(props) {
    const {loading, plans, isAdmin, currentUser, currentPlanId, currentPlan} = useMappedState(useCallback(state => {
        return {
            loading: state.users.loading || state.plans.loading,
            plans: state.plans.plansMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentPlanId: state.plans.currentPlanId,
            currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
        }
    }, []));
    const [payments, setPayments] = useState([]);
    const [paymentsLoading, setPaymentsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllPlans());
        setPaymentsLoading(true);
        BillingAPI.getCurrentUserPayments().then(arr => {
            setPayments(arr);
            setPaymentsLoading(false);
        })
    }, []);
    if (plans.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    if (currentUser == undefined) {
        return null;
    }

    console.log('all plans = ', plans);

    return (
        <Wrapper>

            {/*<CurrentPlanSection>*/}
            {/*    <CurrentPlanName>*/}
            {/*        {currentPlan == undefined ? 'Free' : currentPlan.label}*/}
            {/*    </CurrentPlanName>*/}
            {/*    <CurrentPlanDetails>*/}
            {/*        <CurrentPlanPrice>*/}
            {/*            {`$${currentPlan == undefined ? 0 : currentPlan.price}`}*/}
            {/*        </CurrentPlanPrice>*/}
            {/*        <CurrentPriceLabel>*/}
            {/*            PER MONTH*/}
            {/*        </CurrentPriceLabel>*/}
            {/*    </CurrentPlanDetails>*/}
            {/*    <UserBalancePanel/>*/}
            {/*</CurrentPlanSection>*/}
            {/*<CurrentPlanName>*/}
            {/*    Transactions*/}
            {/*</CurrentPlanName>*/}
            <HistoryBlock>

                <PaymentsList>

                    <PaymentItemHeading>
                        <Field>
                            Date
                        </Field>
                        <Field>
                            Total
                        </Field>
                        <Field>
                            Purchase
                        </Field>
                        <Field>
                            Status
                        </Field>
                        <Field>
                            Invoice
                        </Field>
                    </PaymentItemHeading>

                    {payments.map((p, i) => {
                        let isPaid = (p.is_paid == 1);
                        return (
                            <PaymentItem key={`p_${p.id}`}>
                                <Field>
                                    {moment(p.payout_date).format('YYYY-MM-DD')}
                                </Field>
                                <Field>
                                    {`${p.amount} ${p.currency}`}
                                </Field>
                                <Field>
                                    {p.hasOwnProperty('isPrepaid') ? 'Prepaid' : 'Subscription'}
                                </Field>
                                <Field>
                                    {isPaid == false ? <NotPaidSpan>NOT PAID</NotPaidSpan> :
                                        <PayLink href={p.receipt_url} target={'_blank'}>
                                            PAID
                                        </PayLink>
                                    }
                                </Field>
                                <Field>
                                    {isPaid == false ? null :
                                        <PayLink href={p.receipt_url} target={'_blank'}>
                                            <InvoiceImg src={require('../../../assets/images/invoice_s.svg')}/>
                                        </PayLink>
                                    }
                                </Field>
                                {/*{JSON.stringify(p)}*/}
                            </PaymentItem>
                        )
                    })}
                </PaymentsList>
            </HistoryBlock>


        </Wrapper>
    );
}

const CurrentPlanSection = styled.div`
    width: 100%;
`;

const CurrentPriceLabel = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #FFFFFF;
`;

const CurrentPlanName = styled.div`
    color: #fff;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
`;

const CurrentPlanPrice = styled.div`
    font-size: 32px;
    line-height: 48px;
    color: white;
    font-weight: bold;
`;

const CurrentPlanDetails = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;


const Wrapper = styled.div`
    
`;

const InvoiceImg = styled.img`
    display: inline-block;
    margin-right: 13px;
`;

const InvoicePlaceholder = styled.div`
    text-align: center;
`;

const HistoryBlock = styled.div`
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.070613);
`;

const PaymentsList = styled.div`
    
`;

const PaymentItem = styled.div`
    margin-bottom: 20px;
    color: white;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
`;

const PaymentItemHeading = styled.div`
    margin-bottom: 20px;
    color: #7B7F9E;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
`;


const Field = styled.div`
    flex: 1;
    text-align: center;
    :first-of-type {
      text-align: left;
    }
    :last-of-type {
      text-align: right;
    }
`;

const PayLink = styled.a`
    text-decoration: none;
    color: #24B6BC;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
`;


const NotPaidSpan = styled.span`
    text-decoration: none;
    color: rgb(235, 94, 79);
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
`;

