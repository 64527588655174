import moment from "moment-timezone";
import { DEFAULT_TIMEZONE } from "../constants/config";
import axios from "axios";

import humanizeDuration from "humanize-duration";

export const RU_MONTHS_ARRAY = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const CommonHelper = {
  linkTo(patientUrl) {
    let url =
      window.location.origin + window.location.pathname + "#" + patientUrl;
    window.location.href = url;
  },

  getRuMonthByTimestamp(t) {
    let m = +moment(t).tz(DEFAULT_TIMEZONE).format("M") - 1;
    return RU_MONTHS_ARRAY[+m];
  },

  getEnMonthByTimestamp(t) {
    return moment(t).tz(DEFAULT_TIMEZONE).format("MMMM");
  },

  getCurrentUserRole(state) {
    if (state == undefined) {
      return false;
    }
    let { currentUserId, usersMap } = state.users;
    let currentUser = usersMap.get(currentUserId);
    if (currentUser == undefined) {
      return undefined;
    }
    return currentUser.userRole;
  },

  getUserName(user) {
    if (user == undefined) {
      return "Guest";
    }
    let { firstName, lastName, name } = user;
    if (firstName == undefined && lastName == undefined) {
      return `Carol Black`;
    }
    return `${firstName} ${lastName}`;
  },
  base64ToArrayBuffer(_base64Str) {
    var binaryString = window.atob(_base64Str);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  },
  downloadFile(filename, text) {
    const el = document.createElement("a");
    document.body.appendChild(el);
    const blob = new Blob([text]);
    el.href = URL.createObjectURL(blob);
    el.download = filename; //filename to download
    el.target = '_blank';
    el.click();
    document.body.removeChild(el);
    return;
    // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    // element.setAttribute('download', filename);
    // element.style.display = 'none';
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  },

  async getFile(url, onProgress) {
    let d = (
      await axios.get(url, {
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          let { loaded, total } = progressEvent;
          let progress = (100.0 * +loaded) / +total;
          window.cachingProgress = progress;
          onProgress(progress);
        },
      })
    ).data;
    const blob = new Blob([d]);
    return blob;
  },

  async getCachedUrl(url, onCachingProgress = (p) => {}) {
    let registry = window.cachedRegistry || {};
    if (registry[url] != undefined) {
      return registry[url];
    }
    let d = await this.getFile(url, onCachingProgress);
    // let objectUrl = window.URL.createObjectURL(new Blob(d, {type: "video/mp4"}));
    let objectUrl = window.URL.createObjectURL(d);
    registry[url] = objectUrl;
    window.cachedRegistry = registry;
    return objectUrl;
  },

  getGradColor(ratio = 0.5) {
    let color1 = "27E634";
    let color2 = "F73434";
    let hex = function (x) {
      x = x.toString(16);
      return x.length == 1 ? "0" + x : x;
    };
    let r = Math.ceil(
      parseInt(color1.substring(0, 2), 16) * ratio +
        parseInt(color2.substring(0, 2), 16) * (1 - ratio)
    );
    let g = Math.ceil(
      parseInt(color1.substring(2, 4), 16) * ratio +
        parseInt(color2.substring(2, 4), 16) * (1 - ratio)
    );
    let b = Math.ceil(
      parseInt(color1.substring(4, 6), 16) * ratio +
        parseInt(color2.substring(4, 6), 16) * (1 - ratio)
    );
    return `#${hex(r)}${hex(g)}${hex(b)}`;
  },

  getElementCssProp(id, propName, defaultValue) {
    try {
      let elem = document.getElementById(id);
      let theCSSprop = window
        .getComputedStyle(elem, null)
        .getPropertyValue(propName);
      if (theCSSprop == undefined) {
        return defaultValue;
      }
      return theCSSprop;
    } catch (e) {
      return defaultValue;
    }
  },

  //proportion: w / h
  getContainerDimensions(containerSize = {}, proportion = 1.0) {
    console.log(
      "getContainerDimensions: containerSize, proportion = ",
      containerSize,
      proportion
    );
    let { width, height } = containerSize;
    let containerRatio = width == undefined ? 1 : (1.0 * width) / height;
    let videoWidth = width,
      videoHeight = height;
    if (proportion > containerRatio) {
      // wider
      videoWidth = width;
      videoHeight = Math.ceil((1.0 * width) / proportion);
    } else {
      // narrower
      videoHeight = height;
      videoWidth = Math.ceil(1.0 * height * proportion);
    }
    console.log(
      "returning videoWidth, videoHeight  = ",
      videoWidth,
      videoHeight
    );
    return {
      width: videoWidth,
      height: videoHeight,
    };
  },
};

export default CommonHelper;

const S3_VIDEO_SERVICE_DOWNLOADER = "https://api.subtitles.love/s3-prx";
export const downloadS3VideoAsset = (s3Url, fileName, fileFormat = "mp4") => {
  const reqData = window.btoa(
    JSON.stringify({
      s3url: s3Url,
      filename: encodeURIComponent(fileName),
      format: fileFormat,
    })
  );
  window.open(`${S3_VIDEO_SERVICE_DOWNLOADER}/get?data=${reqData}`);
};
