import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/WaveTemplatesActions";
import SizeSelector from "../editor/SizeSelector";
import SimpleFabricEditor from "../../fabric/tools/SimpleFabricEditor";

import Select from 'react-select'
import DraggableWaveLayer from "../../arts/view/DraggableWaveLayer";
import useComponentSize from "@rehooks/component-size/index";
import WaveHelper from "../../../helpers/WaveHelper";
import ParamsConfigurator from "../../arts/tools/ParamsConfigurator";

export default function WaveTemplatePanel(props) {
    const {id} = props;

    const {loading, template, isAdmin, currentUserId, waveTemplates, arts} = useMappedState(useCallback(state => {
        return {
            loading: state.waveTemplates.loading,
            template: state.waveTemplates.waveTemplatesMap.get(id),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            arts: state.arts.artsMap.toArray()
        }
    }, []));

    const draggablePlaceholderRef = useRef();
    const imageContainerRef = useRef();

    let imageContainerSize = useComponentSize(imageContainerRef);
    let draggablePlaceholderSize = useComponentSize(draggablePlaceholderRef);

    const [backgroundMode, setBackgroundMode] = useState();
    const defParams = props.params == undefined ? [] : props.params;
    const [customOptions, setCustomOptions] = useState({...WaveHelper.paramsToOptionsMap(defParams)});

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getWaveTemplate(id)).then(pld => {
            console.log('pld = ', pld);
            let {waveTemplates} = pld;
            if (waveTemplates.length == 0) {
                return;
            }
            let tm = waveTemplates[0];
            console.log('--->>> tm = ', tm);
            let bMode = ((tm.backgroundImageUrl == undefined || tm.backgroundImageUrl == '') ? 'edit' : undefined);
            console.log('--->>>    setting bMode = ', bMode);
            setBackgroundMode(bMode);
        });
    }, [id]);

    if (template == undefined) {
        return null;
    }

    const backgroundNotReady = (template.backgroundImageUrl == undefined || template.backgroundImageUrl == '');

    let canAdjustWave = (backgroundNotReady == false && template.width != undefined);

    const artsOptions = arts.map(a => ({label: a.name, value: a.id}));
    let selectedArt = (arts.filter(a => (a.id == template.artId)))[0];

    console.log('render: template = ', template);

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopName>
                    {template.name}
                </TopName>
                <TopRight>
                    <Button onClick={() => {

                    }}>
                        Save
                    </Button>
                </TopRight>
            </TopPlaceholder>

            <Section>
                <SectionLabel>
                    <span style={{opacity: 0.5, marginRight: 5}}>
                        {'Step 1. '}
                    </span>
                    {' Select size'}
                    {template.width == undefined ? null :
                        <CheckImg src={require('../../../assets/images/tick_white.svg')}/>
                    }
                </SectionLabel>
                <SectionContent>
                    <SizePlaceholder>
                        <SizeSelector {...template} onChange={async a => {
                            await dispatch(actions.updateWaveTemplate({id: id, ...a, backgroundImageUrl: ''}))
                            setBackgroundMode('edit');
                        }}/>
                    </SizePlaceholder>
                </SectionContent>
            </Section>

            {template.width == undefined ? null :
                <React.Fragment key={`sz_${template.width}_${template.height}`}>

                    <Section>
                        <SectionLabel style={{position: 'relative'}}>
                            <span style={{opacity: 0.5, marginRight: 5}}>
                                {'Step 2. '}
                            </span>
                            {' Upload background image'}
                            {backgroundNotReady ? null :
                                <>

                                {backgroundMode == 'edit' ? null :
                                    <CheckImg src={require('../../../assets/images/tick_white.svg')}/>
                                }

                                {!(backgroundMode != 'edit' && backgroundNotReady == false) ? null :
                                    <span style={{marginLeft: 10, position: 'absolute', right: 0, cursor: 'pointer'}}
                                          onClick={() => {
                                              setBackgroundMode('edit');
                                          }}>
                                        change
                                    </span>
                                }

                                </>
                            }
                        </SectionLabel>

                        <SectionContent key={`${template.backgroundImageUrl}`}>
                            <ImageEditorSectionPlaceholder>
                                <ImagePreviewPlaceholder ref={imageContainerRef}>
                                    {(backgroundMode != 'edit' && template.backgroundImageUrl != undefined) ?

                                        <PreviewDiv
                                            {...WaveHelper.getAreaSize(imageContainerSize, 1.0 * template.width / template.height)}
                                            image={template.backgroundImageUrl}
                                        >
                                        </PreviewDiv>

                                        :
                                        <SimpleFabricEditor
                                            backgroundImageUrl={backgroundMode == 'edit' ? undefined : template.backgroundImageUrl}
                                            loading={loading}
                                            selectedProportion={1.0 * template.width / template.height}
                                            onImageSave={async u => {
                                                console.log('onImageSave: u = ', u);
                                                await dispatch(actions.updateWaveTemplate({
                                                    id: id,
                                                    backgroundImageUrl: u
                                                }));
                                                setBackgroundMode(undefined);
                                            }}
                                        />
                                    }
                                </ImagePreviewPlaceholder>

                            </ImageEditorSectionPlaceholder>
                        </SectionContent>

                    </Section>

                    {(template.backgroundImageUrl == undefined || backgroundMode == 'edit') ? null :
                        <Section>
                            <SectionLabel style={{position: 'relative'}}>
                            <span style={{opacity: 0.5, marginRight: 5}}>
                                {'Step 3. '}
                            </span>
                                {'Select and adjust Wave'}
                            </SectionLabel>

                            <SectionContent>
                                <SelectPlaceholder>
                                    <Select options={artsOptions}
                                            value={artsOptions.filter(a => (a.value == template.artId))[0]}
                                            onChange={async a => {
                                                let art = arts.filter(aa => (a.value == aa.id));
                                                await dispatch(actions.updateWaveTemplate({
                                                    artId: a.value,
                                                    id: id,
                                                    optionsMap: WaveHelper.paramsToOptionsMap(art.params),
                                                    wavePositionOptions: {
                                                        waveWidth: 0.3,
                                                        waveHeight: 0.3,
                                                        top: 0.1,
                                                        left: 0.1
                                                    }
                                                }))
                                            }}/>
                                </SelectPlaceholder>
                                {selectedArt == undefined ? null :
                                    <React.Fragment>
                                        <ImageEditorSectionPlaceholder
                                            key={`${selectedArt.code}_${JSON.stringify(customOptions)}_${template.wavePositionOptions}`}>
                                            <DraggablePlaceholder ref={draggablePlaceholderRef}>
                                                {(() => {
                                                    let sz = WaveHelper.getAreaSize(draggablePlaceholderSize, 1.0 * template.width / template.height);
                                                    let wavePositionOptions = template.wavePositionOptions == undefined ? {
                                                        waveHeight: 0.3,
                                                        waveWidth: 0.3,
                                                        top: 0.1,
                                                        left: 0.1
                                                    } : template.wavePositionOptions;
                                                    let top_ = wavePositionOptions.top * sz.height;
                                                    let left_ = wavePositionOptions.left * sz.width;
                                                    let waveWidth_ = wavePositionOptions.waveWidth * sz.width;
                                                    let waveHeight_ = wavePositionOptions.waveHeight * sz.height;

                                                    return (
                                                        <div style={{width: '100%', height: '100%'}}>
                                                            <DraggableWaveLayer
                                                                {...sz}
                                                                backgroundImage={template.backgroundImageUrl}
                                                                drawFunction={window.eval(`(${selectedArt.code})`)}
                                                                options={{...WaveHelper.paramsToOptionsMap(selectedArt.params), ...customOptions}}
                                                                top={top_} left={left_}
                                                                waveWidth={waveWidth_} waveHeight={waveHeight_}

                                                                onChangeEnd={async a => {
                                                                    console.log('onChangeEnd occured!');
                                                                    let newWavePositionOptions = {
                                                                        left: a.left / sz.width,
                                                                        top: a.top / sz.height,
                                                                        waveWidth: a.width / sz.width,
                                                                        waveHeight: a.height / sz.height
                                                                    };
                                                                    await dispatch(actions.updateWaveTemplate({
                                                                        id: id,
                                                                        wavePositionOptions: newWavePositionOptions
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })()}

                                            </DraggablePlaceholder>
                                        </ImageEditorSectionPlaceholder>

                                        <div key={`${template.artId}`}>
                                            <ParamsConfigurator optionsData={customOptions}
                                                                params={selectedArt.params}
                                                                onUpdate={d => {
                                                                    console.log('ParamsConfigurator: d = ', d);
                                                                    setCustomOptions(d);
                                                                    // setAlliedCode(appliedCode + ' ');
                                                                }}/>
                                        </div>

                                    </React.Fragment>
                                }

                            </SectionContent>

                        </Section>
                    }

                </React.Fragment>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const PreviewDiv = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: white;
    background-image: url(${props => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
`;

const DraggablePlaceholder = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImagePreviewPlaceholder = styled.div`
    width: 100%;
    height: 100%;
`;

const SelectPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const ImageEditorSectionPlaceholder = styled.div`
    width: 100%;
    height: 80vh;
    margin-top: 10px;
`;

const Section = styled.div`
    margin-bottom: 60px;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.05);
`;

const SectionLabel = styled.div`
    font-weight: bold;
    font-size: 18px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SectionContent = styled.div`
    
`;

const SizePlaceholder = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const TopName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #FFFFFF;
`;

const TopRight = styled.div`
    
`;

const Button = styled.div`
    background: #24B6BC;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: pointer;
    
    :hover{
      opacity: 0.9;
    }
`;

const CheckImg = styled.img`
    height: 20px;
    width: 20px;
    margin-left: 5px;
`;
