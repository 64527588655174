import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import Spline from 'cubic-spline'
import WaveHelper from "../../../helpers/WaveHelper";

// import drawBarC from eval()
import ControledCanva from './ControledCanva'

export default function AnimationPlayer(props) {
    const {
        id = 'sabirdd',
        audioUrl,
        width,
        height,
        drawFunction = window.eval(`(${WaveHelper.deafultWaveCode})`),
        options = {
            h_mirror: true,
            v_mirror: false,
            reversed: false,
            top: false,
            width_p: 0.8,
            height_p: 0.8,
            downsample: 0.01,
            wave_color: `#7eff5e`,
            shadow: false,
            wave_shadow: `#7eff5e`,
            follow_inensity: false,
            scale: true,
            scale_k: 1,
            approximation: true,
            approximation_k: 10,
            clean_nuls: false,
            fill: true,
            scaleX: 1,
            scaleY: 1,
        }
    } = props;
    const audioRef = useRef();
    const aCtx = useRef();
    const canvaRef = useRef();

    // console.log('AnimationPlayer: render: audioUrl = ', audioUrl);

    useEffect(() => {
        if (audioUrl == undefined) {
            return;
        }
        aCtx.current = new (window.AudioContext || window.webkitAudioContext)();
        audioRef.current = new Audio(audioUrl);
        // audio.playbackRate = 10;
        audioRef.current.play();
        let start_timestamp = new Date().getTime();
        let analyser = aCtx.current.createAnalyser();
        analyser.minDecibels = -100;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.85;
        let source = aCtx.current.createMediaElementSource(audioRef.current);
        source.connect(analyser).connect(aCtx.current.destination);
        analyser.fftSize = 1024;
        // analyser.fftSize = 2048;
        // analyser.fftSize = 4096;
        // analyser.fftSize = 8192;
        let bufferLengthAlt = analyser.frequencyBinCount;
        let dataArrayAlt = new Uint8Array(bufferLengthAlt);
        let drawAlt = function () {
            let drawVisual = requestAnimationFrame(drawAlt);
            analyser.getByteFrequencyData(dataArrayAlt);
            if (audioRef.current.duration) {
                if (new Date().getTime() - start_timestamp > audioRef.current.duration * 1000) {
                    window.cancelAnimationFrame(drawVisual)
                }
            }
            let fft_arr = Array.from(dataArrayAlt);
            // console.log(fft_arr);
            try {
                let t = audioRef.current.currentTime;
                let dur = audioRef.current.duration;
                canvaRef.current.update({...options, array: array_processing(fft_arr, options), t: t, duration: dur});
                // aCtx.current.close().then(() => {
                //
                // });
            } catch (e) {

            }
            //run upd
        };
        drawAlt();
        return (() => {
            try {
                audioRef.current.pause()
            } catch (e) {

            }
        })
    }, [audioUrl]);


    return (
        <Wrapper width={width} height={height}  >
            <ControledCanva
                id={id}
                ref={canvaRef}
                drawFunction={drawFunction}
                width={width}
                height={height}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    box-sizing: border-box;
`;


let array_processing = (array, d) => {
    if (d.downsample) {
        array = downsample_array(array, array.length * d.downsample * 2)
    }
    let min = Math.min.apply(Math, array)
    if (min < 0) {
        array = array.map(i => i - min)
    }
    array.splice(0, 0, 0)
    array.splice(array.length, 0, 0)

    if (d.reversed) {
        array = array.reverse();
    }
    if (d.v_mirror) {
        array = array.concat([...array].reverse());
        array.splice(array.length / 2, 1)
    }
    // spline approximation
    if (d.approximation) {
        const spline = new Spline(array.map((a, b) => b), array);
        let grid_k = d.approximation_k
        let grid = new Array(array.length * grid_k).fill(0).map((a, b) => b / grid_k)
        // array = downsample_array(grid.map(i => spline.at(i)), grid.length / grid_k)
        array = grid.map(i => spline.at(i))
    }
    array = array.filter(i => !isNaN(i))
    return array
}

let downsample_array = (points, max = 100) => {
    if (points.length <= max) {
        return points;
    }
    let arr = [];
    let n = points.length;

    let step = 1.0 * n / max;
    for (let i = 0; i < max; i++) {
        let a = Math.ceil(step * i);
        let b = Math.floor(step * (i + 1));
        b = Math.min(b, n - 1);
        let sum = 0;
        let kk = 0;
        for (let j = a; j <= b; j++) {
            sum += +points[j];
            kk++;
        }
        let avr = 1.0 * sum / kk;
        arr.push(avr);
    }
    return arr;
}
