import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled, {css} from 'styled-components';
import {Code} from 'react-content-loader'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/ProjectsActions";
import * as jobsActions from "../../../redux/actions/JobsActions";
import CommonHelper, {downloadS3VideoAsset} from "../../../helpers/CommonHelper";
import ProjectsAPI from "../../../api/ProjectsAPI";
import * as download from 'downloadjs'
import ReactHelper from "../../../helpers/ReactHelper";
import iconDownload from '../../../assets/images/download.svg';
import FakeLogsTool from "../../fake/FakeLogsTool";
import FakeProgressBar from "../../fake/FakeProgressBar";
import {Analytics} from "../../../utils/AnalyticsUtility";


export default function ProjectViewPanel(props) {
    const {id} = props;
    const {loading, project, isAdmin, currentUserId, user, jobs, plans, maxProjectJobsNumber} = useMappedState(useCallback(state => {
        let currentPlan = state.plans.plansMap.get(state.plans.currentPlanId);
        let jobsPerProject = (currentPlan == undefined) ? 5 : currentPlan.jobsPerProject;
        if (jobsPerProject == undefined) {
            jobsPerProject = 5;
        }
        return {
            maxProjectJobsNumber: jobsPerProject,
            loading: state.users.loading || state.projects.loading,
            jobsLoading: state.jobs.loading,
            project: state.projects.projectsMap.get(id),
            user: state.users,
            plans: state.plans,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            jobs: state.jobs.jobsMap.toArray().filter(a => (a.projectId == id)).sort((a, b) => (+b.timestamp - +a.timestamp))
        }
    }, []));

    const subtitlesRef = useRef();

    const [subtitlesLoaded, setSubtitlesLoaded] = useState(false);
    const [rendersVisible, setRendersVisible] = useState(false);
    const [renderButtonLoading, setRenderButtonLoading] = useState(false);

    const dispatch = useDispatch();
    let finishedJobs = jobs.filter(a => (a.status == 'finished'));
    let notFinishedJobs = jobs.filter(a => (a.status != 'finished'));
    let projectStatus = (project == undefined) ? undefined : project.status;

    let hasNotFinishedJob = (notFinishedJobs.length > 0);

    ReactHelper.useInterval(() => {
        let status = (project == undefined) ? undefined : project.status;
        if (status == 'ready') {
            return;
        }
        dispatch(actions.getProject(id));
    }, 3000);

    ReactHelper.useInterval(() => {
        if (notFinishedJobs.length > 0) {
            dispatch(jobsActions.getProjectJobs(id));
        }
    }, 5000);


    useEffect(() => {
        dispatch(actions.getProject(id));
        dispatch(jobsActions.getProjectJobs(id));
    }, [projectStatus]);

    useEffect(() => {
        if (projectStatus == 'ready' && subtitlesLoaded == false) {
            ProjectsAPI.getProjectSubtitles(id).then(subs => {
                subtitlesRef.current = subs;
                setSubtitlesLoaded(true);
            });
        }
    }, [projectStatus]);


    const showWatermarkButton = () => {
        const isSample = project.hasOwnProperty('isSample') && project.isSample
        let hasWatermark = project.hasOwnProperty('hasWatermark') && project.hasWatermark;
        if (latestJob != undefined) {
            hasWatermark = latestJob.hasWatermark;
        }
        return !isSample && hasWatermark
    }

    if (project == undefined || jobs.length == 0) {
        return <Code/>;
    }
    let latestJob = jobs[0];
    let {optionsData} = latestJob;
    let proportion = (optionsData == undefined || optionsData.proportion == undefined) ? 'N/A' : optionsData.proportion;

    return (
        <Wrapper>

            <Heading>
                {project.name}
            </Heading>

            {hasNotFinishedJob == true ? null :
                <ContentPlaceholder>
                    <VideoPlaceholder>
                        <VideoPlayer preload={'auto'} controls={true}>
                            <source src={latestJob.resultUrl}/>
                        </VideoPlayer>
                    </VideoPlaceholder>

                    <ProjectInfoPlaceholder>
                        <DownloadButton onClick={() => {
                            Analytics.send('editor.download_video', 'editor');
                            downloadS3VideoAsset(latestJob?.resultUrl, `${project?.name.split('.')[0]}_${project?.width}x${project?.height}`)
            
                        }}>
                            Download video
                        </DownloadButton>
                        {showWatermarkButton() && (
                            <WatermarkButton onClick={() => {
                                CommonHelper.linkTo(`/pricing/`);
                            }}>
                                Remove watermark
                            </WatermarkButton>
                        )}


                        <EditButton onClick={() => {
                            CommonHelper.linkTo(`/project/${project.id}`);
                        }}>
                            Edit video
                        </EditButton>

                        <RowHeading>
                            <RowName>
                                Render History
                            </RowName>
                            <RowStatsNumber>
                            <span style={{color: '#FFAE36'}}>
                                {jobs.length}
                            </span>
                                <span style={{color: '#7B7F9E'}}>
                                {`/${maxProjectJobsNumber}`}
                            </span>
                            </RowStatsNumber>
                        </RowHeading>

                        {jobs.map((a, i) => {
                            let isLoading = (a.status != 'finished');
                            let proportion = (a.optionsData == undefined) ? 'N/A' : a.optionsData.proportion;
                            if (proportion == undefined) {
                                proportion = 'N/A';
                            }
                            return (
                                <HistoryItem key={`${a.id}`}>
                                    {/*<Resolution>Size {`${a.optionsData.width}x${a.optionsData.height}`}</Resolution>*/}
                                    <Resolution>Size {`${Math.round(+a.optionsData.width)}x${Math.round(a.optionsData.height)}`}</Resolution>
                                    <Date>{moment(a.timestamp).format('MM/DD/YYYY HH:mm')}</Date>
                                    {isLoading == true ? <Spin visible={true} theme={'alfa-on-color'}/> :
                                        <DownloadIcon wide={(jobs.length > 4)} onClick={() => {
                                            Analytics.send('editor.download_video', 'editor');
                                            downloadS3VideoAsset(a?.resultUrl, `${project?.name.split('.')[0]}_${a?.optionsData?.width}x${project?.optionsData?.height}`)

                                        }}/>
                                    }
                                </HistoryItem>
                            )
                        })}

                    </ProjectInfoPlaceholder>
                </ContentPlaceholder>
            }

            {hasNotFinishedJob == false ? null :
                <BigOverlay>
                    <ProcessingHeading>
                        Processing your video
                    </ProcessingHeading>
                    <ProcessingSubHeading>
                    Video quality in video editor is a preview only and not reflective of final quality
                    </ProcessingSubHeading>
                    <ProcessingSubHeading>
                        Please wait. It can take several minutes...
                    </ProcessingSubHeading>
                    <FakeProgressbarPlaceholder>
                        <FakeProgressBar duration={project.duration}/>
                    </FakeProgressbarPlaceholder>
                    <LoaderPlaceholder>
                        <FakeLogsTool/>
                    </LoaderPlaceholder>
                </BigOverlay>
            }

        </Wrapper>
    );
}

const WatermarkButton = styled.div`
 font-size: 17px;
 color: #FFAC30;
 border: 1px solid #FFAC30;
 text-align: center;
 height: 56px;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 5px;
 margin-bottom: 60px;
 cursor: pointer;
 :hover {
  opacity: 0.7;
 }
`;

const FakeProgressbarPlaceholder = styled.div`
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 0px;
    height: 8px;
    width: 520px;
`;

const LoaderPlaceholder = styled.div`
    width: 520px;
    height: calc(100% - 400px);
    text-align: center;
`;

const VideoPlayer = styled.video`
    width: 100%;
    height: 100%;
`;


const RowHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const RowName = styled.div`
    color: #7B7F9E;
    font-size: 16px;
`;

const RowStatsNumber = styled.div`
    font-weight: bold;
    font-size: 16px;
`;


const SampleVideo = styled.video`
    //width: 600px;
    //height: 50vh;
`;

const ContentPlaceholder = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    align-items: flex-start;
    display: flex;
`;

const VideoPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    height: 400px;
    box-sizing: border-box;
    padding-right: 20px;
`;

const ProjectInfoPlaceholder = styled.div`
    width: 360px;
`;

const Heading = styled.div`
    color: #FFFFFF;
    font-family: Avenir Next Cyr;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 40px;
    word-break: break-all;
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    background: #181923;
`;

export const Button = styled.div`
    height: 37px;
    background: #EA523D;
    border-radius: 5px;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled == true ? 0.5 : 1)};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    :hover{
      opacity: ${props => (props.disabled == true ? 0.5 : 0.8)};
    }
`;

const BigOverlay = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #171822;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;


const ProcessingHeading = styled.div`
    color: white;
    font-weight: bold;
    font-size: 21px;
    line-height: 29px;
`;

const ProcessingSubHeading = styled.div`
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
`;

const DownloadButton = styled.div`
    font-weight: bold;
    background: #EA523D;
    border-radius: 5px;
    color: white;
    height: 56px;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 25px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const EditButton = styled.div`
    font-weight: bold;
    background: #01939B;
    border-radius: 5px;
    color: white;
    height: 56px;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const Row = styled.div`
    
`;

const DownloadIcon = styled.div`
 background-image: url(${iconDownload});
 background-position: center;
 height: 14px;
 width: 14px;
 cursor: pointer;
 margin-right: ${props => (props.wide == true ? '20px' : '0px')};
`;


const HistoryItem = styled.div`
     color: #EFEFEF;
     font-size: 12px;
     display: flex;
     justify-content: space-between;
     padding: 5px 0;
     border-bottom: 1px solid #3a3a4a;
`;

const Resolution = styled.div`
  min-width: 110px;
`;

const Date = styled.div`
 flex: 1;
 
`;

function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
}
