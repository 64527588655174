const WaveHelper = {

    deafultWaveCode: `function draw(ctx, props) {
    let {array, scale, scale_k, wave_color, follow_inensity, shadow, width, height, t} = props;
    scale_k = 0.8 + 0.2 * array.filter(function (a, b) {
        return (b > array.length < 5)
    }).reduce(function (a, b) {
        return (a + b)
    }, 0) / array.length / 200;
    let phi0 = 360.0 * t / 10.0;

    let convertHex = function (hex, opacity) {
        hex = hex.replace('#', '');
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    };
    ctx.clearRect(0, 0, width, height);
    for (let i in array) {
        let width_k = 0.2
        let phi = phi0 + 360.0 * i / array.length + 90.0;
        let default_height = 1;
        let r1 = height / 4
        let r2 = r1 + (height - 2 * r1) / 2 * array[i] / 200 + default_height;
        if (scale) {
            r1 *= scale_k
            r2 *= scale_k
        }
        ctx.beginPath();
        ctx.lineWidth = 2 * Math.PI * r1 / array.length * width_k;
        ctx.strokeStyle = convertHex(wave_color, follow_inensity ? array[i] / 200 * 0.7 + 0.3 : 1)
        ctx.lineTo(width / 2 + Math.cos(phi * Math.PI / 180) * r1, height / 2 + Math.sin(phi * Math.PI / 180) * r1);
        ctx.lineTo(width / 2 + Math.cos(phi * Math.PI / 180) * r2, height / 2 + Math.sin(phi * Math.PI / 180) * r2);
        ctx.stroke();
        ctx.closePath();
    }
    if (shadow) {
        ctx.shadowColor = wave_color;
        ctx.shadowBlur = 20;
    }
    }`,

    paramsToOptionsMap(params) {
        let map = {};
        for (let i in params) {
            let p = params[i];
            if (p.defaultValue == undefined) {
                continue;
            }
            map[p.name] = p.defaultValue;
            if (p.type == 'number') {
                map[p.name] = +p.defaultValue;
            }
        }
        return map;
    },

    getAreaSize(contSize, ratio) {
        let contWidth = contSize.width;
        let contHeight = contSize.height;
        let res = {};
        let containerRatio = 1.0 * contWidth / contHeight;
        if (containerRatio > ratio) { // wider
            res.height = contHeight;
            res.width = 1.0 * contHeight * ratio;
        } else { // narrower
            res.width = contWidth;
            res.height = 1.0 * contWidth / ratio
        }
        return res;
    }

}

export default WaveHelper;
