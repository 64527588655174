/*
* ACTIONS FOR VIDEO REDUCER
* */

// updadete VIDEO
export const LOAD_VIDEO_SUBTITLE = 'LOAD_VIDEO_SUBTITLE';

export const SET_ACTIVE_FRAME = 'SET_ACTIVE_FRAME';
export const SET_MAIN_VIDEO_FRAME = 'SET_MAIN_VIDEO_FRAME';
export const MOVE_SUBTITLE_TO_FRAME = 'MOVE_SUBTITLE_TO_FRAME';

export const UPDATE_FRAME_SUBTITLE = 'UPDATE_FRAME_SUBTITLE';
export const SET_VIDEO_SIZE = 'SET_VIDEO_SIZE';
export const SET_VIDEO_PANEL_COLOR = 'SET_VIDEO_PANEL_COLOR';
export const SET_VIDEO_SCALE = 'SET_VIDEO_SCALE';
export const SET_SUBTITLE_TIMING = 'SET_SUBTITLE_TIMING';
export const REMOVE_SUBTITLE = 'REMOVE_SUBTITLE';


export const removeSubtitle = (id) => {
    return {
        type: REMOVE_SUBTITLE,
        data: id
    }
}


export const updateSubtitleTiming = (data) => {
    return {
        type: SET_SUBTITLE_TIMING,
        data: data
    }
}

export const loadSubtitle = (data) => {
    return {
        type: LOAD_VIDEO_SUBTITLE,
        data: data
    }
}

export const moveToNewFrame = (text, index) => {
    return {
        type: MOVE_SUBTITLE_TO_FRAME,
        index,
        text
    }
}

export const setActiveFrame = (data) => {
    return {
        type: SET_ACTIVE_FRAME,
        data: data
    }
}

export const updateFrameSubtitle = (data) => {
    return {
        type: UPDATE_FRAME_SUBTITLE,
        data: data
    }
}
export const setMainVideoFrame = (data) => {
    return {
        type: SET_MAIN_VIDEO_FRAME,
        data: data
    }
}

export const setVideoSize = (value) => {
    return {
        type: SET_VIDEO_SIZE,
        data: value
    }
}

export const setVideoScale = (value) => {
    return {
        type: SET_VIDEO_SCALE,
        data: value
    }
}

export const setVideoPanelColor = (value) => {
    return {
        type: SET_VIDEO_PANEL_COLOR,
        data: value
    }
}
