import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios'
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const getToken = () => {
    let tk = window.localStorage.getItem('auth_token');
    if (tk == undefined || tk == '') {
        return;
    }
    return tk;
};


axios.interceptors.request.use((config) => {
    let token = getToken();
    if (token != undefined){
        let url = new URL(config.url);
        url.searchParams.set('token', token);
        config.url = url.toString();
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.defaults.timeout = 10 * 60 * 1000;

Sentry.init({
    dsn: "https://70bccd0b56a74f818fb41a3e7790d5df@o444825.ingest.sentry.io/5420302",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});


ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
