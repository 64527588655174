const gtag = typeof window !== `undefined` ? window.gtag : null

const UAH_RATE = 27;
const START_CHECKOUT_FRAC = 0.05;
const CHECKOUT_SUCCESS = 0.4;

export class Analytics {

    static send(action = null, category = null, callback = () => {
    }, ...rest) {
        if (gtag != null) {
            gtag('event', action, {
                'event_category': category,
                'event_callback': () => callback(),
                ...rest
            });
        }
    }

    static sendStartCheckout(price, paddleId, tariffName) {
        if (gtag != null) {
            gtag('event', 'conversion', {
                'send_to': 'AW-954731457/Vhk9CNPGwfQBEMGXoMcD',
                'value': UAH_RATE * price * START_CHECKOUT_FRAC,
                'currency': 'UAH'
            });
        }

        gtag('event', 'begin_checkout', {
            "items": [
                {
                    "id": paddleId,
                    "name": tariffName,
                    "list_name": "Paddle",
                    "brand": "Paddle",
                    "category": "Subscription",
                    "variant": "Monthly/Subscription",
                    "quantity": 1,
                    "price": `${price}`
                }
            ],
            "coupon": ""
        });
    }

    static sendCheckoutSuccess(price, userId, paddleId, tariffName) {
        if (gtag != null) {
            const transactionId = `${userId}-${+new Date()}`;
            gtag('event', 'conversion', {
                'send_to': 'AW-954731457/0uAmCK_wwfQBEMGXoMcD',
                'value': UAH_RATE * price * CHECKOUT_SUCCESS,
                'currency': 'UAH',
                'transaction_id': transactionId
            });

            gtag('event', 'purchase', {
                "transaction_id":transactionId,
                "affiliation": "direct",
                "value": price,
                "currency": "USD",
                "tax": 0,
                "shipping": 0,
                "items": [
                    {
                        "id": paddleId,
                        "name": tariffName,
                        "list_name": "Paddle",
                        "brand": "Paddle",
                        "category": "Subscription",
                        "variant": "Monthly/Subscription",
                        "quantity": 1,
                        "price": `${price}`
                    },

                ]
            });
        }


    }


}
