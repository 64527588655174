import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function ParamView(props) {
    const {name, label, min, max, type, defaultValue} = props;

    return (
        <Wrapper>

            <Row>
                <Label>
                    Name
                </Label>
                <Value>
                    {name}
                </Value>
            </Row>

            <Row>
                <Label>
                    Label
                </Label>
                <Value>
                    {label}
                </Value>
            </Row>

            <Row>
                <Label>
                    Type
                </Label>
                <Value>
                    {type}
                </Value>
            </Row>

            <Row>
                <Label>
                    Default value
                </Label>
                <Value>
                    {`${defaultValue}`}
                </Value>
            </Row>

            {type != 'number' ? null :
                <>
                <Row>
                    <Label>
                        Min
                    </Label>
                    <Value>
                        {min}
                    </Value>
                </Row>
                <Row>
                    <Label>
                        Max
                    </Label>
                    <Value>
                        {max}
                    </Value>
                </Row>
                </>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
`;

const Label = styled.div`
    width: 120px;
    font-weight: bold;
    margin-right: 20px;
`;

const Value = styled.div`
    flex: 1;
`;
