import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import UpdateProfileForm from "../forms/UpdateProfileForm";

import * as actions from "../../../redux/actions/UsersActions";
import UpdateUserPasswordForm from "../forms/UpdateUserPasswordForm";
import { API_ENDPOINT } from "../../../constants/config";
import CoolModal from "../../modals/CoolModal";
import AnalyticsAPI from "../../../api/AnalyticsAPI";
import SidebarContentTemplate from "../../templates/SidebarContentTemplate";

export default function UserProfilePanel(props) {
  const { loading, currentUserId, currentUser } = useMappedState(
    useCallback((state) => {
      return {
        loading: state.users.loading,
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        currentUserId: state.users.currentUserId,
      };
    }, [])
  );
  const dispatch = useDispatch();
  const [changing, setChanging] = useState(false);
  const [error, setError] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);
console.log("currentUser", currentUser)
  return (
    <SidebarContentTemplate>
      <TopPlaceholder>
        <TopName>My profile</TopName>
        <TopRight>
          <LogoutSpan
            onClick={async () => {
              await dispatch(actions.logOut());
              AnalyticsAPI.reachGoal("logout");
              window.localStorage.clear();
              deleteAllCookies();
              try {
                await cleanFuckingFirebaseData();
              } catch (exc) {}
              setTimeout(() => {
                window.location.href = `https://subtitles.love`;
              }, 100);
            }}
          >
            Sign out
          </LogoutSpan>
        </TopRight>
      </TopPlaceholder>
      <Field>
        <Label>E-mail</Label>
        <Input disabled value={currentUser?.email} placeholder={"E-mail"} />
      </Field>
      <UpdateUserPasswordForm
        loading={loading || changing}
        onSave={async (d) => {
          if (changing) {
            return;
          }
          setError(undefined);
          setChanging(true);
          console.log("UpdateUserPasswordForm: onSave: d = ", d);
          let pld = (await axios.post(`${API_ENDPOINT}/change_my_password`, d))
            .data;
          if (pld.error != undefined) {
            setChanging(false);
            setError(pld.error);
          } else {
            setChanging(false);
            setSuccessMessage("Password has been changed successfully!");
          }
        }}
      />

      {error == undefined ? null : (
        <CoolModal
          onClose={() => {
            setError(undefined);
          }}
        >
          <ErrorMessage>{error.message}</ErrorMessage>
        </CoolModal>
      )}

      {successMessage == undefined ? null : (
        <CoolModal
          onClose={() => {
            setSuccessMessage(undefined);
          }}
        >
          <SuccessMessage>{successMessage}</SuccessMessage>
        </CoolModal>
      )}
    </SidebarContentTemplate>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 18px;
  text-align: center;
`;

const Field = styled.div`
  margin-bottom: 15px;
  flex: 1;
  box-sizing: border-box;
`;
const Input = styled.input`
  background: #212330;
  border: 2px solid rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 50px;
  width: 100%;
  padding-left: 15px;

  font-weight: bold;

  font-size: 17px;
  line-height: 23px;

  color: #ffffff;

  :focus {
    border: 2px solid rgba(255, 255, 255, 0.03);
  }
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  color: white;
  margin-bottom: 20px;
`;

const SuccessMessage = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
`;

const LogoutSpan = styled.span`
  color: #eb5e4f;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
`;

const TopName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 46px;
  /* identical to box height, or 135% */

  color: #ffffff;
`;

const TopRight = styled.div`
  text-align: right;
  flex: 1;
`;

function deleteAllCookies() {
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

const cleanFuckingFirebaseData = () => {
  let req = indexedDB.deleteDatabase(`firebaseLocalStorageDb`);
  return new Promise((resolve, reject) => {
    req.onsuccess = function () {
      console.log("Deleted database successfully");
      resolve();
    };
    req.onerror = function () {
      console.log("Couldn't delete database");
      resolve();
    };
    req.onblocked = function () {
      console.log(
        "Couldn't delete database due to the operation being blocked"
      );
      resolve();
    };
  });
};
