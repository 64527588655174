import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    useImperativeHandle,
    forwardRef
} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const fabric = window.fabric;

const deleteObj = (canvas, id) => {
    canvas.getObjects().forEach((o) => {
        if (o.id === id) {
            canvas.remove(o);
        }
    })
}

const getObj = (canvas, id) => {
    let res = undefined;
    canvas.getObjects().forEach((o) => {
        if (o.id === id) {
            res = o;
        }
    });
    return res;
}

export default forwardRef((props, ref) => {
    const {id = 'sabir', width = 500, height = 500, top = 0, left = 0, waveWidth = 40, waveHeight = 40,
    onChange = a => {

    },
        onChangeEnd = a => {

        }
    } = props;
    const cId = `canva_${id}`;
    const contRef = useRef(null);

    const canvas = useRef();

    useEffect(() => {
        console.log('useEffect: width, height = ', width, height);
        if (width == 0 || height == 0) {
            return;
        }
        if (canvas.current == undefined) {
            console.log('initializing canva');
            canvas.current = new fabric.Canvas(cId, {
                width: width,
                height: height,
                preserveObjectStacking: true
            });
            let rect = new fabric.Rect({
                top: top,
                left: left,
                width: waveWidth,
                height: waveHeight,
                hasBorder: false,
                fill:'transparent',
                hasRotatingPoint: false
            });
            canvas.current.add(rect);

            canvas.current.on('object:modified', (event) => {
                let object = event.target;
                onChange(object);
                console.log('object:MODIFIED: object = ', object);
                onChangeEnd(object);
            });
            canvas.current.on('object:moving', (event) => {
                let object = event.target;
                onChange(object);
                console.log('object:moving!: object = ', object);
            });
            canvas.current.on('object:scaling', (event) => {
                let object = event.target;
                onChange(object);
                // console.log('object:modified: object = ', object);
            });
            //
            // canvas.current.on('after:render', (event) => {
            //     let object = event.target;
            //     console.log('after:render: object = ', object);
            // });

        } else {
            canvas.current.set({
                width: width,
                height: height
            });
            canvas.current.discardActiveObject();
            canvas.current.renderAll();
        }

        return () => {
            try {
                canvas.current.dispose();
                canvas.current = undefined;
            } catch (exc) {

            }
        }
    }, [width, height]);

    useEffect(() => {
        return () => {
            try {
                canvas.current.dispose();
            } catch (exc) {

            }
        }
    }, []);


    useImperativeHandle(ref, () => ({

    }));

    return (
        <Wrapper ref={contRef}>
            <MyCanvas id={cId} width={width} height={height}></MyCanvas>
        </Wrapper>
    );
});

const Inner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const MyCanvas = styled.canvas`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;
