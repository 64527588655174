import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';
import ReactSlider from 'react-slider'
import ColorPicker from "./ColorPicker";
import {FontList} from "../../../config/config";
import DropdownElement from "./DropdownElement";

const PROPORTION_OPTIONS = [
    '1:1',
    '9:16',
    '16:9',
    '4:5',
    '5:4',
];

const FONT_SIZE_OPTIONS = Array.from({length: 32}).map((a, i) => {
    let perc = 2 + 0.25 * i;
    let fs = 8 + 2 * i;
    return `${fs}`
});

const TEXT_BACKGROUND_TYPE_OPTIONS = [
    {
        label: 'Wrap',
        value: 'wrap'
    },
    {
        label: 'Full',
        value: 'full'
    },
    {
        label: 'Outline',
        value: 'outline'
    },
    {
        label: 'None',
        value: 'none'
    }
];

const VIDEO_SCALE_OPTIONS = [
    {
        label: 'Zoom&Crop',
        value: 'zoom'
    },
    {
        label: 'Fit',
        value: 'fit'
    },
];


const TEXT_ALIGN_IMAGES_MAP = {
    left: require('../images/mdi_format_align_left.svg'),
    center: require('../images/mdi_format_align_center.svg'),
    right: require('../images/mdi_format_align_right.svg'),
}

export default function VideoStyleEditor(props) {
    const {

        originalWidth = 500,
        originalHeight = 500,

        viewVideoHeight = 500, // used to calculate percent values


        fontWeight = 'bold',
        fontStyle = 'italic',
        proportion = '1:1',
        backgroundType = 'full',
        textAlign = 'center',

        videoScale = 'zoom',
        font = 'Roboto',

        backgroundColor = '#000000', // HEX
        overlayBackground = 'rgba(0, 0, 0, 1)', // RGBA
        color = '#FF0000', // text HEX color

        bottomOffset = 0, // in percent
        lineHeight = 5, // in percent
        fontSize = 5, // in percent

        onChange = d => {

        }

    } = props;

    let isOriginalProportion = (PROPORTION_OPTIONS.filter(a => (a == proportion)).length == 0);

    return (
        <Wrapper>

            <Row>
                <Label>
                    Proportion
                </Label>
                <Field>
                    <PropRowCont>
                        {PROPORTION_OPTIONS.concat(`${originalWidth}:${originalHeight}`).map((a, i) => {
                            let selected = (proportion == a);
                            let isOriginal = (i == PROPORTION_OPTIONS.length);
                            return (
                                <RBox selected={selected} key={i} onClick={() => {
                                    if (isOriginal) {
                                        onChange({proportion: a, videoScale: 'zoom'});
                                    } else {
                                        onChange({proportion: a});
                                    }
                                }}>
                                    {isOriginal == true ? 'Original' : a}
                                </RBox>
                            )
                        })}
                    </PropRowCont>
                </Field>
            </Row>

            {isOriginalProportion == true ? null :
                <Row>
                    <Label>
                        Video scale
                    </Label>
                    <Field>
                        <PropRowCont>
                            {VIDEO_SCALE_OPTIONS.map((a, i) => {
                                return (
                                    <RBox selected={videoScale == a.value} key={i} onClick={() => {
                                        onChange({videoScale: a.value});
                                    }}>
                                        {a.label}
                                    </RBox>
                                )
                            })}

                            {videoScale != 'fit' ? null :
                                <ColorPicker hex={true} defaultColor={backgroundColor}
                                             leftOffset={-140}
                                             onColorChange={col => {
                                                 onChange({backgroundColor: col});
                                             }}/>
                            }

                        </PropRowCont>
                    </Field>
                </Row>
            }

            <Row>
                <Label>
                    Text options
                </Label>

                <QRow>
                    <QCol>

                        <Field style={{display: 'flex', flexDirection: 'row'}}>

                            <div style={{maxWidth: 220}}>
                                <DropdownElement
                                    data={FontList}
                                    defaultLabel={'Chose Font'}
                                    defaultValue={font}
                                    onChange={({data}) => {
                                        WebFont.load({
                                            google: {
                                                families: [data]
                                            }
                                        });
                                        onChange({font: data});
                                    }}
                                    hasKey={false}/>
                            </div>


                            <DropdownElement
                                style={{minWidth: 80, width: 80}}
                                data={FONT_SIZE_OPTIONS}
                                defaultLabel={'Chose Font'}
                                defaultValue={`${((+fontSize - 2) / 0.25) * 2 + 8}`}
                                onChange={({data}) => {
                                    let a = ((+data - 8) / 2.0) * 0.25 + 2
                                    onChange({fontSize: a, lineHeight: a * 1.5})
                                }}
                                hasKey={false}/>


                        </Field>

                        <Field>
                            <PropRowCont>
                                <SquareRBox selected={fontWeight == 'bold'} onClick={() => {
                                    onChange({fontWeight: {bold: 'normal', normal: 'bold'}[fontWeight]});
                                }}>
                                    <Img src={require('../images/В.svg')} width={12} height={23}/>
                                </SquareRBox>
                                <SquareRBox selected={fontStyle == 'italic'} onClick={() => {
                                    onChange({fontStyle: {italic: 'normal', normal: 'italic'}[fontStyle]});
                                }}>
                                    <Img src={require('../images/I.svg')} width={7} height={23}/>
                                </SquareRBox>

                                {['left', 'center', 'right'].map((a, i) => {
                                    return (
                                        <SquareRBox selected={(a == textAlign)} key={i} onClick={() => {
                                            onChange({textAlign: a});
                                        }}>
                                            <Img src={TEXT_ALIGN_IMAGES_MAP[a]}/>
                                        </SquareRBox>
                                    )
                                })}

                                <ColorPicker hex={true} defaultColor={color}
                                             leftOffset={-140}
                                             onColorChange={col => {
                                                 onChange({color: col});
                                             }}/>


                            </PropRowCont>
                        </Field>

                    </QCol>
                    <QColLast>
                        <Field>
                            <div style={{
                                width: '100%',
                                height: 80,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <StyledVertSlider
                                    value={bottomOffset}
                                    renderTrack={Track}
                                    renderThumb={Thumb}
                                    max={95}
                                    min={0}
                                    step={0.1}
                                    orientation="vertical"
                                    invert={true}
                                    onChange={a => {
                                        onChange({bottomOffset: a});
                                    }}
                                />
                            </div>
                        </Field>
                    </QColLast>
                </QRow>

            </Row>

            <Brow>
                <RRow>
                    <Row>
                        <Label>
                            Text background
                        </Label>
                        <Field>
                            <PropRowCont2>
                                {TEXT_BACKGROUND_TYPE_OPTIONS.map((a, i) => {
                                    return (
                                        <RBox2 selected={backgroundType == a.value} key={i} onClick={() => {
                                            onChange({backgroundType: a.value});
                                        }}>
                                            {a.label}
                                        </RBox2>
                                    )
                                })}
                                <span style={{display: 'inline-block', marginTop: 3, marginLeft: -2}}>
                                    {backgroundType == 'none' ? null :
                                        <ColorPicker hex={false} defaultColor={overlayBackground}
                                                     leftOffset={-140}
                                                     onColorChange={col => {
                                                         onChange({overlayBackground: col});
                                                     }}/>
                                    }
                                </span>

                            </PropRowCont2>
                        </Field>
                    </Row>
                </RRow>
            </Brow>

        </Wrapper>
    );
}

const QRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const QCol = styled.div`

`;

const QColLast = styled.div`
  width: 60px;
  padding-top: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  background: #171822;
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Brow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const LBrow = styled.div`
  width: 120px;
  box-sizing: border-box;
  padding-right: 10px;
`;

const RRow = styled.div`
  flex: 1;
`;

const Field = styled.div`

`;

const Label = styled.div`
  color: #7B7F9E;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 8px;
`;

const RowCont = styled.div`

`;

const PropRowCont = styled(RowCont)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const PropRowCont2 = styled.div`

`;


const RBox = styled.div`
  background: ${props => (props.selected == true ? '#FFAC30' : '#212330')};
  box-sizing: border-box;
  border-radius: 6px;
  height: 35px;
  margin: 2.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;

  :hover {
    background: ${props => (props.selected == true ? '#FFAC30' : '#3F435A')};
  }
`;

const RBox2 = styled(RBox)`
  display: inline-flex;
  vertical-align: top;
  margin: 4px;
`;

const SquareRBox = styled(RBox)`
  width: 35px;
`;

const Img = styled.img`
  width: ${props => (props.width == undefined ? 'auto' : `${props.width}px`)};
  height: ${props => (props.height == undefined ? 'auto' : `${props.height}px`)};
`;

const sliderHeight = 10;
const thumbHeight = sliderHeight * 2.4;

const StyledVertSlider = styled(ReactSlider)`
  width: ${sliderHeight}px;
  height: 100%;
`;


const StyledThumb = styled.div`
  margin-left: ${-((thumbHeight - sliderHeight) / 2.0)}px;
  width: ${thumbHeight}px;
  height: ${thumbHeight}px;
  line-height: ${thumbHeight}px;
  width: ${thumbHeight}px;
  text-align: center;
  //background-color: #000;
  background-color: #FFFFFF;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  font-size: ${thumbHeight * 0.5}px;
  box-sizing: border-box;
  border: 1px solid whitesmoke;
`;

// const Thumb = (props, state) => <StyledThumb {...props}>{`${state.valueNow}%`}</StyledThumb>;
const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
  background: #3F435A;
  left: 0;
  right: 0;
  background: ${props => props.index === 2 ? '#f00' : props.index === 1 ? '#2C2C2E' : '#FFAC30'};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index}/>;
