import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ServersAPI = {

    async getServer(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/server/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getAllServers() {
        let pld = (await axios.get(`${API_ENDPOINT}/servers/all`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async createServer(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/server`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateServer(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/server`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async deleteServer(id) {
        let pld = (await axios.post(`${API_ENDPOINT}/server/delete`, {id: id})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


}

export default ServersAPI;
