import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";

export default function AdminUsersApp() {

    return (
        <SubtitlesTemplate active={'users'}>

            admin users app

        </SubtitlesTemplate>
    );
}

const Wrapper = styled.div`
    
`;
