/**
 * Created by sabir on 19.07.17.
 */

//users
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const ACTIVATE_PUSH = 'ACTIVATE_PUSH'
export const ACTIVATE_PUSH_SUCCESS = 'ACTIVATE_PUSH_SUCCESS'
export const ACTIVATE_PUSH_FAIL = 'ACTIVATE_PUSH_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

// realtime
export const UPDATE_ONLINE_TUNNELS = 'UPDATE_ONLINE_TUNNELS'
export const UPDATE_ONLINE_PHONES = 'UPDATE_ONLINE_PHONES'

// money
export const SET_USER_BALANCE = 'SET_USER_BALANCE';

// plans
export const LOAD_PLANS = 'LOAD_PLANS'
export const LOAD_PLANS_SUCCESS = 'LOAD_PLANS_SUCCESS'
export const LOAD_PLANS_FAIL = 'LOAD_PLANS_FAIL'

export const CREATE_PLAN = 'CREATE_PLAN'
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS'
export const CREATE_PLAN_FAIL = 'CREATE_PLAN_FAIL'

export const UPDATE_PLAN = 'UPDATE_PLAN'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const UPDATE_PLAN_FAIL = 'UPDATE_PLAN_FAIL'

export const DELETE_PLAN = 'DELETE_PLAN'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL'

export const SET_CURRENT_PLAN_DETAILS = 'SET_CURRENT_PLAN_DETAILS';

// univ
export const LOAD_GROUPS_LINKS_USERS_SUCCESS = 'LOAD_GROUPS_LINKS_USERS_SUCCESS'

//projects
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL'

export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL'

export const LOAD_PROJECTS = 'LOAD_PROJECTS'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_FAIL = 'LOAD_PROJECTS_FAIL'

export const CREATE_SERVER = 'CREATE_SERVER'
export const CREATE_SERVER_SUCCESS = 'CREATE_SERVER_SUCCESS'
export const CREATE_SERVER_FAIL = 'CREATE_SERVER_FAIL'

export const UPDATE_SERVER = 'UPDATE_SERVER'
export const UPDATE_SERVER_SUCCESS = 'UPDATE_SERVER_SUCCESS'
export const UPDATE_SERVER_FAIL = 'UPDATE_SERVER_FAIL'

export const DELETE_SERVER = 'DELETE_SERVER'
export const DELETE_SERVER_SUCCESS = 'DELETE_SERVER_SUCCESS'
export const DELETE_SERVER_FAIL = 'DELETE_SERVER_FAIL'

export const LOAD_SERVERS = 'LOAD_SERVERS'
export const LOAD_SERVERS_SUCCESS = 'LOAD_SERVERS_SUCCESS'
export const LOAD_SERVERS_FAIL = 'LOAD_SERVERS_FAIL'

// jobs

export const LOAD_JOBS = 'LOAD_JOBS'
export const LOAD_JOBS_SUCCESS = 'LOAD_JOBS_SUCCESS'
export const LOAD_JOBS_FAIL = 'LOAD_JOBS_FAIL'

export const CREATE_JOB = 'CREATE_JOB'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_FAIL = 'CREATE_JOB_FAIL'

// arts
export const LOAD_ARTS = 'LOAD_ARTS'
export const LOAD_ARTS_SUCCESS = 'LOAD_ARTS_SUCCESS'
export const LOAD_ARTS_FAIL = 'LOAD_ARTS_FAIL'

export const CREATE_ART = 'CREATE_ART'
export const CREATE_ART_SUCCESS = 'CREATE_ART_SUCCESS'
export const CREATE_ART_FAIL = 'CREATE_ART_FAIL'

export const UPDATE_ART = 'UPDATE_ART'
export const UPDATE_ART_SUCCESS = 'UPDATE_ART_SUCCESS'
export const UPDATE_ART_FAIL = 'UPDATE_ART_FAIL'

export const DELETE_ART = 'DELETE_ART'
export const DELETE_ART_SUCCESS = 'DELETE_ART_SUCCESS'
export const DELETE_ART_FAIL = 'DELETE_ART_FAIL'

// wave templates
export const LOAD_WAVE_TEMPLATES = 'LOAD_WAVE_TEMPLATES'
export const LOAD_WAVE_TEMPLATES_SUCCESS = 'LOAD_WAVE_TEMPLATES_SUCCESS'
export const LOAD_WAVE_TEMPLATES_FAIL = 'LOAD_WAVE_TEMPLATES_FAIL'

export const CREATE_WAVE_TEMPLATE = 'CREATE_WAVE_TEMPLATE'
export const CREATE_WAVE_TEMPLATE_SUCCESS = 'CREATE_WAVE_TEMPLATE_SUCCESS'
export const CREATE_WAVE_TEMPLATE_FAIL = 'CREATE_WAVE_TEMPLATE_FAIL'

export const UPDATE_WAVE_TEMPLATE = 'UPDATE_WAVE_TEMPLATE'
export const UPDATE_WAVE_TEMPLATE_SUCCESS = 'UPDATE_WAVE_TEMPLATE_SUCCESS'
export const UPDATE_WAVE_TEMPLATE_FAIL = 'UPDATE_WAVE_TEMPLATE_FAIL'

export const DELETE_WAVE_TEMPLATE = 'DELETE_WAVE_TEMPLATE'
export const DELETE_WAVE_TEMPLATE_SUCCESS = 'DELETE_WAVE_TEMPLATE_SUCCESS'
export const DELETE_WAVE_TEMPLATE_FAIL = 'DELETE_WAVE_TEMPLATE_FAIL'

