import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CoolModal from "../../modals/CoolModal";

const isShortPwd = a => (a == undefined || a.trim().length < 7);

export default function UpdateUserPasswordForm(props) {
    const {
        onSave = d => {

        }, loading = false
    } = props;

    const [error, setError] = useState(undefined);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    return (
        <Wrapper>

            <SubHeading>
                Change password
            </SubHeading>

            <Row>

                <Field>
                    <Label>
                        Old password
                    </Label>
                    <Input value={oldPassword} placeholder={'7 or more characters'} onChange={evt => {
                        setOldPassword(evt.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        New password
                    </Label>
                    <Input value={newPassword} placeholder={'7 or more characters'} onChange={evt => {
                        setNewPassword(evt.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Confirm new password
                    </Label>
                    <Input value={newPasswordConfirmation} placeholder={'7 or more characters'} onChange={evt => {
                        setNewPasswordConfirmation(evt.target.value);
                    }}/>
                </Field>

            </Row>


            <BottomField>
                <Button onClick={() => {
                    setError(undefined);
                    if (newPasswordConfirmation != newPassword) {
                        setError({message: 'new password and new password confirmation are not equal'});
                        return;
                    }
                    if (isShortPwd(newPassword)) {
                        setError({message: 'new password is too short'});
                        return;
                    }
                    onSave({newPassword, oldPassword});
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span>Change</span>
                    }
                </Button>
            </BottomField>

            {error == undefined ? null :
                <CoolModal onClose={() => {
                    setError(undefined);
                }}>

                    <ErrorMessage>
                        {error.message}
                    </ErrorMessage>

                </CoolModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 18px;
    text-align: center;
`;

const Field = styled.div`
    margin-bottom: 15px;
    flex: 1;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const Label = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
    margin-bottom: 10px;
`;

const Input = styled.input`
    background: #212330;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    
    font-weight: bold;
    
    font-size: 17px;
    line-height: 23px;
    
    color: #FFFFFF;
    
    :focus{
      border: 2px solid rgba(255, 255, 255, 0.03);
    }
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
`;

const Button = styled.div`
    background: #24B6BC;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: pointer;
    
    :hover{
      opacity: 0.9;
    }
`;

const SubHeading = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 29px;
    color: white;
    margin-bottom: 20px;
`;
