import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function UpdateProfileForm(props) {
    const {
        onSave = d => {

        }, loading = false
    } = props;

    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);

    return (
        <Wrapper>

            <Field>
                <Label>
                    First name
                </Label>
                <Input value={firstName} onChange={evt => {
                    setFirstName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Last name
                </Label>
                <Input value={lastName} onChange={evt => {
                    setLastName(evt.target.value);
                }}/>
            </Field>

            <BottomField>
                <Button onClick={() => {
                    onSave({firstName, lastName})
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span>Change</span>
                    }
                </Button>
            </BottomField>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;



const Field = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
`;

const Input = styled.input`
    background: #212330;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    
    font-weight: bold;
    
    font-size: 17px;
    line-height: 23px;
    
    color: #FFFFFF;
    
    :focus{
      border: 2px solid rgba(255, 255, 255, 0.03);
    }
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
`;

const Button = styled.div`
    background: #24B6BC;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: pointer;
    
    :hover{
      opacity: 0.9;
    }
`;
