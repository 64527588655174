import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

export default function UpdateConfigInputTool(props) {
    const {
        paramName = 'sabir',
        type = 'string' //or number
    } = props;
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/dictionary/${paramName}`).then(d => d.data.result.value).then(s => {
            setValue(s);
            setLoading(false);
        });
    }, [paramName]);

    return (
        <Wrapper>

            <InputPlaceholder>
                <Input placeholder={`Please, provide ${type}`} value={value} onChange={evt => {
                    setValue(evt.target.value);
                }}/>
            </InputPlaceholder>

            <Button disabled={loading} onClick={async () => {
                setLoading(true);
                await axios.put(`${API_ENDPOINT}/dictionary/${paramName}`, {value: (type == 'number') ? +value : `${value}`});
                setLoading(false);
            }}>
                <Spin visible={loading}/>
                {loading == true ? null : 'Save'}
            </Button>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
`;

const InputPlaceholder = styled.div`
    margin-right: 15px;
    flex: 1;
`;


