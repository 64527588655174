import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    artsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const ArtsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_ART:
        case types.UPDATE_ART:
        case types.DELETE_ART:
        case types.LOAD_ARTS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_ART_FAIL:
        case types.UPDATE_ART_FAIL:
        case types.DELETE_ART_FAIL:
        case types.LOAD_ARTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_ART_SUCCESS:
        case types.UPDATE_ART_SUCCESS:
            return {
                ...state,
                loading: false,
                artsMap: state.artsMap.set(action.art.id, action.art)
            }


        case types.DELETE_ART_SUCCESS:
            return {
                ...state,
                loading: false,
                artsMap: state.artsMap.delete(action.id),
            }


        case types.LOAD_ARTS_SUCCESS:
            return {
                ...state,
                loading: false,
                artsMap: state.artsMap
                    .merge(action.arts.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ArtsReducer;
