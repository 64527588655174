import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    useImperativeHandle,
    forwardRef
} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import useComponentSize from "@rehooks/component-size";

const fabric = window.fabric;

const deleteObj = (canvas, id) => {
    canvas.getObjects().forEach((o) => {
        if (o.id === id) {
            canvas.remove(o);
        }
    })
}

const getObj = (canvas, id) => {
    let res = undefined;
    canvas.getObjects().forEach((o) => {
        if (o.id === id) {
            res = o;
        }
    });
    return res;
}

export default forwardRef((props, ref) => {
    const {id = 'sabir'} = props;
    const cId = `canva_${id}`;
    const contRef = useRef(null);
    let size = useComponentSize(contRef);
    let {width, height} = size;
    const canvas = useRef();

    useEffect(() => {
        console.log('useEffect: width, height = ', width, height);
        if (width == 0 || height == 0) {
            return;
        }
        if (canvas.current == undefined) {
            console.log('initializing canva');
            canvas.current = new fabric.Canvas(cId, {
                width: width,
                height: height,
                preserveObjectStacking: true
            });
            canvas.current.on('object:selected', (event) => {
                let object = event.target;
                console.log('selected object: object = ', object);
                // canvas.current.sendToBack(object);
                if (object.id == 'background') {
                    object.sendBackwards();
                }
                console.log("Selected");
            });

            canvas.current.on('after:render', (event) => {
                let object = event.target;
                console.log('after:render: object = ', object);
            });

        } else {
            canvas.current.set({
                width: width,
                height: height
            });
            canvas.current.discardActiveObject();
            canvas.current.renderAll();
        }

        return () => {
            try {
                canvas.current.dispose();
                canvas.current = undefined;
            } catch (exc) {

            }
        }
    }, [width, height]);

    useEffect(() => {
        return () => {
            try {
                canvas.current.dispose();
            } catch (exc) {

            }
        }
    }, []);

    useImperativeHandle(ref, () => ({

        addWave: (u = 'http://fabricjs.com/assets/pug_small.jpg', cachedUrl) => {
            let cUrl = (cachedUrl == undefined) ? u : cachedUrl;
            deleteObj(canvas.current, 'wave');
            fabric.Image.fromURL(cUrl, img => {
                img.set({
                    left: 0,
                    top: 0,
                    id: 'wave'
                });
                img.id = 'wave';
                img.scaleToHeight(height / 1.6);
                img.scaleToWidth(width / 1.6);
                canvas.current.add(img);
                try {
                    let back = getObj(canvas.current, 'background');
                    if (back != undefined) {
                        back.sendBackwards();
                    }
                } catch (e) {
                    console.log('can not send background to backwards: e = ', e);
                }
            });
        },

        setBackground: (u = 'http://fabricjs.com/assets/pug_small.jpg', cachedUrl) => {
            let cUrl = (cachedUrl == undefined) ? u : cachedUrl;
            deleteObj(canvas.current, 'background');
            fabric.Image.fromURL(cUrl, img => {
                img.set({
                    left: 0,
                    top: 0,
                    // selectable: false,
                    id: 'background'
                });
                img.id = 'background';
                // img.selectable = false;
                img.scaleToHeight(height);
                img.scaleToWidth(width);
                canvas.current.add(img);
                let back = getObj(canvas.current, 'background');
                back.sendBackwards();
            });
        },

        updateWaveColor(color) {

        },

        deleteObject(id) {
            deleteObj(canvas.current, id);
        },

        flushFocus() {
            try {
                canvas.current.discardActiveObject();
                canvas.current.renderAll();
            } catch (exc) {

            }
        },

        exportImage(){

        },

        getObjects(){
            return canvas.current.getObjects()
        },

        getDataUrl(){
            let waveObj = getObj(canvas.current, 'wave');
            if (waveObj != undefined){
                waveObj.set({opacity: 0})
            }
            let dUrl = canvas.current.toDataURL({
                format: "png",
                left: 0,
                top: 0,
                width: canvas.current.width ,
                height: canvas.current.height ,
            });
            if (waveObj != undefined){
                waveObj.set({opacity: 1})
            }
            return dUrl;
        }

    }));

    return (
        <Wrapper ref={contRef}>
            <MyCanvas id={cId} width={width} height={height}></MyCanvas>
        </Wrapper>
    );
});

const Inner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const MyCanvas = styled.canvas`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;
