import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function SimpleProgressbar(props) {
    const {progress = 0} = props;

    return (
        <Wrapper>
            <Inner style={{width: `${progress}%`}} ></Inner>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;  
    height: 100%;
    position: relative;
    box-sizing: border-box;  
    border-radius: 1000px;
    background: #3F435A;
`;

const Inner = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    box-sizing: border-box;
    border-radius: 1000px;
    background: #FFAC30;
    transition: 0.3s;
`;
