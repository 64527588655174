import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'
import {PUBLIC_URL} from "../../../constants/config";
import CoolModal from "../../modals/CoolModal";
import {Analytics} from "../../../utils/AnalyticsUtility";
import SocialAuthForm from "./SocialAuthForm";
import {generatePasswordFromUid} from "../../../helpers/SocialAuthHelper";

function validateEmail(email) {
    if (email == undefined) {
        return false;
    }
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(`${email}`.toLowerCase());
}

export default function StyledSignupForm(props) {
    const {
        onSubmit = () => {

        },
        onLoginClick = () => {
        },
        onSocialSubmit = () => {

        },
        loading = false
    } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [errorMessage, setErrorMessage] = useState(undefined);

    return (
        <Wrapper>

            <TopPlaceholder>
                Sign Up
            </TopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <SocialAuthForm onLoaded={(social) => {
                        if (social == undefined || social.email == undefined) {
                            return;
                        }
                        onSocialSubmit({
                            email: social.email.toLowerCase(),
                            password: generatePasswordFromUid(social.uid),
                            social
                        })
                        window.gr("track", "conversion", { email: social.email.toLowerCase() });
                        Analytics.send('lead.sign_up', 'lead');
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Email
                    </Label>
                    <Input
                        autoComplete={false}
                        autoFill={false}
                        value={email} type={'email'} placeholder={''} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Password
                    </Label>
                    <Input value={password} type={'password'} placeholder={'7 or more symbols'}
                           onKeyPress={e => {

                           }}
                           onChange={e => {
                               setPassword(e.target.value);
                           }}
                           autoComplete={false}
                           autoFill={false}
                    />
                </Field>

                <Field>
                    <Label>
                        Confirm password
                    </Label>
                    <Input value={confirmation} type={'password'} placeholder={'7 or more symbols'}
                           onKeyPress={e => {

                           }}
                           onChange={e => {
                               setConfirmation(e.target.value);
                           }}
                           autoComplete={false}
                           autoFill={false}
                    />
                </Field>

                <PersField>
                    By submitting this form I agree to the <PolicyLink target={'_blank'}
                                                                       href={`${PUBLIC_URL}/policy.pdf`}>Privacy
                    Policy</PolicyLink>
                </PersField>

                <Field>
                    <Button background={'#FFAC30'}
                            onClick={() => {
                                if (validateEmail(email) == false) {
                                    setErrorMessage('Your email is not valid!');
                                    return;
                                }
                                if (password.length < 7) {
                                    setErrorMessage('You password is too short');
                                    return;
                                }
                                if (password != confirmation) {
                                    setErrorMessage('Password and password confirmation are not equal!');
                                    return;
                                }
                                Analytics.send('lead.sign_up', 'lead');
                                onSubmit({email: email.toLowerCase().trim(), password})


                            }}>
                        <Spin theme={'alfa-on-color'} visible={loading}/>
                        {loading == true ? null :
                            'Get started!'
                        }
                    </Button>
                </Field>

            </ContentPlaceholder>

            <BottomPlaceholder>
                {`Have an account?`}
                <SignupSpan style={{marginLeft: 4}} onClick={() => {
                    onLoginClick();
                }}>
                    Sign In
                </SignupSpan>

            </BottomPlaceholder>

            {errorMessage == undefined ? null :
                <CoolModal onClose={() => {
                    setErrorMessage(undefined);
                }}>

                    <ErrorMessage>
                        {errorMessage}
                    </ErrorMessage>

                </CoolModal>
            }

        </Wrapper>
    );
}

const PrivacyPlaceholder = styled.div`
    
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 18px;
    text-align: center;
`;


const PolicyLink = styled.a`
    color: #01A3AB;
`;

const SignupSpan = styled.span`
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const ForgotPlaceholder = styled.div`
    text-align: right;
    color: #00A3AB;
`;

const ForgotSpan = styled.span`
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const BottomPlaceholder = styled.div`
    color: white;
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 23px;
    
`;

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 40px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
`;

const ContentPlaceholder = styled.div`
    
`;


const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const PersField = styled(Field)`
    text-align: center;
    color: #FFFCFC;
    opacity: 0.75;
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 15px;
`;

const Label = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #7B7F9E;
    margin-bottom: 14px;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;

