import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    plansMap: Map(),
    error: undefined,

    currentPlanId: undefined,
    currentPlanExpirationTimestamp: undefined,
    planStartTimestamp: undefined,

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const PlansReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_PLAN:
        case types.UPDATE_PLAN:
        case types.DELETE_PLAN:
        case types.LOAD_PLANS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_PLAN_FAIL:
        case types.UPDATE_PLAN_FAIL:
        case types.DELETE_PLAN_FAIL:
        case types.LOAD_PLANS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_PLAN_SUCCESS:
        case types.UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plansMap: state.plansMap.set(action.plan.id, action.plan)
            }


        case types.DELETE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plansMap: state.plansMap.delete(action.id),
            }


        case types.LOAD_PLANS_SUCCESS:
            return {
                ...state,
                loading: false,
                plansMap: state.plansMap
                    .merge(action.plans.reduce((map, form) => map.set(form.id, form), Map()))
            }

        case types.SET_CURRENT_PLAN_DETAILS:
            return {
                ...state,
                currentPlanId: action.planId,
                currentPlanExpirationTimestamp: action.expirationTimestamp,
                planStartTimestamp: action.planStartTimestamp
            }


        default:
            return state;
    }

}

export default PlansReducer;
