import React from 'react';
import styled from 'styled-components';
import FacebookPlugin from "../ui/FacebookPlugin";

const isRu = () => {
    try {
        return (window.location.href.indexOf('ru') > -1);
    } catch (exc) {
        return false;
    }
}

export default function FooterSection(props) {
    const {
        onLogout = () => {

        }
    } = props;


    return (
        <Wrapper>

            <BottomInner>
                <LangPlaceholder>
                    {/*<LangItem active={!isRu()}>*/}
                    {/*EN*/}
                    {/*</LangItem>*/}
                    {/*{' '}*/}
                    <MailSpan href={'mailto:hello@subtitles.love'}>
                       Support: hello@subtitles.love
                    </MailSpan>
                </LangPlaceholder>

                <LinksPlaceholder>

                    {[
                        // {
                        //     url: 'http://sabir.pro',
                        //     image: require('../../assets/images/fb_im.svg')
                        // },
                        // {
                        //     url: 'http://sabir.pro',
                        //     image: require('../../assets/images/tw_im.svg')
                        // },
                        {
                            url: 'https://www.instagram.com/subtitleslove/',
                            image: require('../../assets/images/inst_im.svg')
                        },
                    ].map((a, j) => {
                        return (
                            <SocialLink key={j} href={a.url} target={'_blank'}>
                                <SocialIcon src={a.image}/>
                            </SocialLink>
                        )
                    })}

                </LinksPlaceholder>

                <DocsPlaceholder>
                    <DocItem href={'https://my.subtitles.love/policy.pdf'} target={'_blank'} >
                        Privacy policy
                    </DocItem>
                    {/*<DocItem href={'#'}>*/}
                    {/*Terms and conditionals*/}
                    {/*</DocItem>*/}
                    {/*<DocItem href={'#'} onClick={() => {*/}
                    {/*onLogout();*/}
                    {/*}}>*/}
                    {/*Logout*/}
                    {/*</DocItem>*/}
                </DocsPlaceholder>
            </BottomInner>
        </Wrapper>
    );
}

const Left = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const Wrapper = styled.div`
    padding-top: 17px;
    margin-top: 100px;
`;


const BottomInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.070613);
    padding-top: 30px;
    margin-top: 30px;
    color: white;
`;

const LangPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LangItem = styled.div`
    color: ${props => (props.active === true ? '#EA523D' : 'white')};
    font-weight: ${props => (props.active === true ? 'bold' : 'normal')};
    margin-right: 15px;
    border-right: 1px solid lightgrey;
    padding-right: 15px;
    font-size: 17px;
    line-height: 21px;
    :last-of-type{
      border-right: none;
    }
    @media(max-width: 720px){
      font-size: 14px;
      padding-right: 6px;
      margin-right: 6px;
    }
`;

const MailSpan = styled.a`
    color: white;
    font-weight: ${props => (props.active === true ? 'bold' : 'normal')};
    margin-right: 15px;
    border-right: 1px solid lightgrey;
    padding-right: 15px;
    font-size: 17px;
    line-height: 21px;
    text-decoration: none;
    :last-of-type{
      border-right: none;
    }
    @media(max-width: 720px){
      font-size: 14px;
      padding-right: 6px;
      margin-right: 6px;
    }
`;

const DocsPlaceholder = styled.div`
    
`;

const DocItem = styled.a`
    color: rgba(255, 255, 255, 0.5);
    margin-left: 20px;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    @media(max-width: 720px){
      font-size: 11px;
    }
`;

const Logo = styled.img`
    height: 80px;
    width: 82px;
    margin-right: 50px;
    @media(max-width: 720px){
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    @media(max-width: 520px){
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
`;

const LinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const LinkItem = styled.div`
    margin-right: 40px;
    color: white;
    font-size: 17px;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
    @media(max-width: 720px){
      margin-right: 15px;
      font-size: 14px;
    }
`;

const LastLinkItem = styled(LinkItem)`
    @media(max-width: 720px){
      display: none;
    }
`;

const SocialIcon = styled.img`
    height: 30px;
    @media(max-width: 920px){
      height: 24px;
    }
`;

const SocialLink = styled.a`
    margin-left: 80px;  
    @media(max-width: 920px){
      margin-left: 30px;
    }
    @media(max-width: 720px){
      margin-left: 15px;
    }
`;

