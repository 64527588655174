import {combineReducers} from 'redux';

import UsersReducer from './UsersReducer.js';
import PlansReducer from './PlansReducer.js';
import ProjectsReducer from './ProjectsReducer.js';
import ServersReducer from './ServersReducer.js';
import JobsReducer from './JobsReducer.js';
import ArtsReducer from './ArtsReducer.js';
import WaveTemplatesReducer from './WaveTemplatesReducer.js';
import SubtitleReducer from "./SubtitleReducer";
import VideoReducer from "./VideoReducer";

export const reducer = combineReducers({
    waveTemplates: WaveTemplatesReducer,
    arts: ArtsReducer,
    jobs: JobsReducer,
    projects: ProjectsReducer,
    users: UsersReducer,
    servers: ServersReducer,
    plans: PlansReducer,
    videoData: VideoReducer,
    subtitleData: SubtitleReducer
});
