export const SET_SUBTITLE_COLOR = 'SET_SUBTITLE_COLOR';
export const SET_FONT_SIZE = 'SET_FONT_SIZE';
export const SET_FONT = 'SET_FONT';
export const SET_FONT_WEIGHT = 'SET_FONT_WEIGHT';
export const SET_FONT_POSITION = 'SET_FONT_POSITION';
export const SET_FONT_STYLE = 'SET_FONT_STYLE';
export const SET_SUBTITLES_PANEL_COLOR = 'SET_SUBTITLES_PANEL_COLOR';
export const SET_SUBTITLES_PANEL_TYPE= 'SET_SUBTITLES_PANEL_TYPE';
export const MOVE_SUBTITLES_PANEL_UP =  'MOVE_SUBTITLES_PANEL_UP';
export const MOVE_SUBTITLES_PANEL_DOWN =  'MOVE_SUBTITLES_PANEL_DOWN';
export const INCREASE_FONT_LINE_HEIGHT =  'INCREASE_FONT_LINE_HEIGHT';
export const DECREASE_FONT_LINE_HEIGHT =  'DECREASE_FONT_LINE_HEIGHT';
export const SET_FONT_ASPECT =  'SET_FONT_ASPECT';
export const SET_FONT_LINE_HEIGHT_ASPECT =  'SET_FONT_LINE_HEIGHT_ASPECT';

export const setPanelPositionRation = (data) => {
    return {
        type: SET_FONT_LINE_HEIGHT_ASPECT,
        data: Number(data)
    }
}


export const setFontAspect = (data) => {
    return {
        type: SET_FONT_ASPECT,
        data: Number(data)
    }
}


export const setFont = (data) => {
    return {
        type: SET_FONT,
        data: data
    }
}


export const setSubtitlesColor = (data) => {
    return {
        type: SET_SUBTITLE_COLOR,
        data: data
    }
}

export const setSubtitlesFontSize = (data) => {
    return {
        type: SET_FONT_SIZE,
        data: data
    }
}

export const setSubtitlesPanelType = (data) => {
    return {
        type: SET_SUBTITLES_PANEL_TYPE,
        data: data
    }
}

export const setSubtitlesPanelColor = (data) => {
    return {
        type: SET_SUBTITLES_PANEL_COLOR,
        data: data
    }
}

export const setSubtitlesStyle = (data = null) => {
    return {
        type: SET_FONT_STYLE,
        data: data
    }
}

export const setSubtitlesWeight = (data = null) => {
    return {
        type: SET_FONT_WEIGHT,
        data: data
    }
}

export const setSubtitlesPosition = (data) => {
    return {
        type: SET_FONT_POSITION,
        data: data
    }
}


export const movePanel = (toUp = true) => {
    return {
        type: toUp ? MOVE_SUBTITLES_PANEL_UP : MOVE_SUBTITLES_PANEL_DOWN
    }
}

export const changeTextSpace = (toUp = true) => {
    return {
        type: toUp ? INCREASE_FONT_LINE_HEIGHT : DECREASE_FONT_LINE_HEIGHT
    }
}
