import ProjectsAPI from '../../api/ProjectsAPI'

import * as types from '../ActionTypes'


const loadProjects_ = () => {
    return {
        type: types.LOAD_PROJECTS
    }
}
export const loadProjectsSuccess = (projects) => {
    return {
        type: types.LOAD_PROJECTS_SUCCESS,
        projects: projects
    }
}
let loadProjectsFailed = (error) => {
    return {
        type: types.LOAD_PROJECTS_FAIL,
        error: error
    }
}

export function setForceProject(project) {
    return (dispatch, getState) => {
        dispatch(loadProjectsSuccess([project]))
    }
}

//thunk
export function loadAllProjects() {
    return (dispatch, getState) => {
        dispatch(loadProjects_());
        return ProjectsAPI.getAllProjects().then(
            projects => dispatch(loadProjectsSuccess(projects)),
            error => dispatch(loadProjectsFailed(error))
        )
    }
}

export function loadUserProjects(userId) {
    return (dispatch, getState) => {
        dispatch(loadProjects_());
        return ProjectsAPI.getUserProjects(userId).then(
            projects => dispatch(loadProjectsSuccess(projects)),
            error => dispatch(loadProjectsFailed(error))
        )
    }
}

export function getProject(id) {
    return (dispatch, getState) => {
        dispatch(loadProjects_());
        return ProjectsAPI.getProject(id).then(
            proj => dispatch(loadProjectsSuccess([proj])),
            error => dispatch(loadProjectsFailed(error))
        )
    }
}

let createProject_ = () => {
    return {
        type: types.CREATE_PROJECT
    }
}

export function createProjectSuccess(project) {
    return {
        type: types.CREATE_PROJECT_SUCCESS,
        project: project
    }
}

let createProjectFailed = (error) => {
    return {
        type: types.CREATE_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function createProject(data) {
    return (dispatch, getState) => {
        dispatch(createProject_());
        return ProjectsAPI.createProject(data).then(
            project => {

                return dispatch(createProjectSuccess(project))
            },
            error => dispatch(createProjectFailed(error))
        )
    }
}

export function cloneProject(oldProjectId) {
    return (dispatch, getState) => {
        dispatch(createProject_());
        return ProjectsAPI.cloneProject(oldProjectId).then(
            project => dispatch(createProjectSuccess(project)),
            error => dispatch(createProjectFailed(error))
        )
    }
}


let updateProject_ = () => {
    return {
        type: types.UPDATE_PROJECT
    }
}
let updateProjectSuccess = (project) => {
    return {
        type: types.UPDATE_PROJECT_SUCCESS,
        project: project
    }
}
let updateProjectFailed = (error) => {
    return {
        type: types.UPDATE_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function updateProject(data) {
    return (dispatch, getState) => {
        dispatch(updateProject_());
        return ProjectsAPI.updateProject(data).then(
            project => dispatch(updateProjectSuccess(project)),
            error => dispatch(updateProjectFailed(error))
        )
    }
}

export function updateProjectSubtitles(id, subtitles = []) {
    return (dispatch, getState) => {
        dispatch(updateProject_());
        return ProjectsAPI.updateProject({id, subtitles}).then(
            project => dispatch(updateProjectSuccess(project)),
            error => dispatch(updateProjectFailed(error))
        )
    }
}

let deleteProject_ = () => {
    return {
        type: types.DELETE_PROJECT
    }
}
let deleteProjectSuccess = (id) => {
    return {
        type: types.DELETE_PROJECT_SUCCESS,
        id: id
    }
}
let deleteProjectFailed = (error) => {
    return {
        type: types.DELETE_PROJECT_FAIL,
        error: error
    }
}

//thunk
export function deleteProject(id) {
    return (dispatch, getState) => {
        dispatch(deleteProject_());
        return ProjectsAPI.deleteProject(id).then(
            () => dispatch(deleteProjectSuccess(id)),
            error => dispatch(deleteProjectFailed(error))
        )
    }
}
