import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";

const getMb = a => {
    if (a == undefined || window.isNaN(a) == true) {
        return 0;
    }
    return +(+a / 1024.0 / 1024.0).toFixed(1);
}

export default function ImageSelector(props) {
    const {
        onUploaded = (originalUrl, cachedUrl) => {

        }
    } = props;
    const [uploading, setUploading] = useState(false);
    const [progressData, setProgressData] = useState();

    let statsText = (progressData == undefined) ? '' : `${progressData.progress.toFixed(0)}% (${getMb(progressData.loaded)} MB)`;

    return (
        <Wrapper>

            <UploadPlaceholder>
                <SimpleUploadPanel
                    uploadSuffix={'upload_image'}
                    accept={'.jpg,.jpeg,.png,.bmp'}
                    onUploaded={pld => {
                        onUploaded(pld.url);
                        setUploading(false);
                    }}
                    onProgress={d => {
                        console.log('onProgress: d = ', d);
                        setProgressData(d);
                    }} onStart={() => {
                    setUploading(true);
                }}
                >
                    <UploadPlaceholder>
                        <Spin visible={uploading}/>
                        {uploading == true ? null :
                            <span>
                                Upload background image
                            </span>
                        }
                    </UploadPlaceholder>

                </SimpleUploadPanel>
            </UploadPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const UploadPlaceholder = styled.div`
    background: lightgrey;
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
