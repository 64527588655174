import Spin from "arui-feather/spin";
import React, {
    useCallback, useEffect,
    useRef, useState
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import styled from "styled-components";
import BillingAPI from "../../../api/BillingAPI";
import TelegramAPI from "../../../api/TelegramAPI";
import * as actions from "../../../redux/actions/PlansActions";
import * as usersActions from "../../../redux/actions/UsersActions";
import PricingFAQTool from "../../faq/PricingFAQTool";
import CoolModal from "../../modals/CoolModal";
import NewPricingTool from "../../subscriptions/tools/NewPricingTool";
import SidebarContentTemplate from "../../templates/SidebarContentTemplate";

const REQUEST_INTERVAL = 3000;
let isRequesting = false;

export default function UserPricingPanel(props) {
  const {
    loading,
    plans,
    isAdmin,
    currentUser,
    currentUserId,
    currentPlanId,
    currentPlan,
  } = useMappedState(
    useCallback((state) => {
      return {
        loading: state.users.loading || state.plans.loading,
        plans: state.plans.plansMap
          .toArray()
          .sort((a, b) => +b.timestamp - +a.timestamp),
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        currentUserId: state.users.currentUserId,
        currentPlanId: state.plans.currentPlanId,
        currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
      };
    }, [])
  );
  const balanceTimer = useRef();
  const dispatch = useDispatch();

  const [mode, setMode] = useState("month");

  const [unsModalVisible, setUnsModalVisible] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [upgradePaddleId, setUpgradePaddleId] = useState(undefined);

  useEffect(() => {
    setInterval(() => {
      if (!isRequesting) {
        dispatch(usersActions.getCurrentUserInfo()).then((pld) => {
          isRequesting = false;
        });
        isRequesting = true;
      }
    }, REQUEST_INTERVAL);

    return () => {
      clearTimeout(balanceTimer.current);
    };
  }, []);

  useEffect(() => {
    // dispatch(actions.loadAllPlans());
  }, []);

  useEffect(() => {
    try {
      window.mixpanel.track("PricingPageVisit");
      if (
        currentUser != undefined &&
        window.location.href.indexOf("localhost") == -1
      ) {
        TelegramAPI.sendMessageToAdmins(
          `[PRICING PAGE VISIT]: User ${currentUser.email} visited pricing page`
        );
      }
    } catch (exc) {}
  }, []);

  if (currentUser == undefined) {
    return null;
  }

  let { email } = currentUser;

  return (
    <Wrapper>
      <Content>
        <div
            style={{
              marginTop: 40,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 30,
              display: "flex",
              flexDirection: "row",
              justifyContent: 'center',
              gap: 20,
            }}
          >
            <div
              style={{
                padding: 20,
                cursor: "pointer",
                backgroundColor: mode==='month'?"#FFAC30": "#212330",
                borderRadius: 5,
              }}
            >
              <span
                style={{ color: mode==='month'?"black": "white", opacity: 0.5,}}
                onClick={() => {
                  setMode("month");
                }}
              >
                {mode==='year' && 'Switch to'} <span style={{fontWeight:'700',opacity: 1}}>Monthly plans</span>

              </span>
            </div>
            <div
              style={{
                padding: 20,
                cursor: "pointer",
                backgroundColor: mode==='year'?"#FFAC30": "#212330",
                borderRadius: 5,
              }}
            >
              <span
                style={{ color: mode==='year'?"black": "white", opacity: 0.5 }}
                onClick={() => {
                  setMode("year");
                }}
              >
                {mode==='month' && 'Save money, see'} <span style={{fontWeight:'700',opacity: 1}}>Annual plans</span>

              </span>
            </div>
          </div>
        <SidebarContentTemplate>
         

          <NewPricingTool
            mode={mode}
            currentUser={currentUser}
            currentPlanId={currentPlanId}
            currentUserId={currentUserId}
            email={email}
            currentPlan={currentPlan}
            onSelectFreeClick={() => {
              setUnsModalVisible(true);
            }}
            onDifferentPlanSelect={(paddle_id) => {
              setUpgradePaddleId(paddle_id);
              // setUpgradePaddleId
            }}
          />
        </SidebarContentTemplate>

        <div style={{ marginTop: 60 }}>
          <PricingFAQTool currentUser={currentUser} />
        </div>
      </Content>

      {unsModalVisible == false ? null : (
        <CoolModal
          onClose={() => {
            setUnsModalVisible(false);
          }}
        >
          <UnsText>
            Are you sure you want to cancel subscription?
            <br />
            Your paid credits will be lost!
          </UnsText>

          <UpdateButton
            onClick={async () => {
              setUnsubscribing(true);
              let link = await BillingAPI.getCurrentUserSubLink();
              console.log("link = ", link);
              setUnsubscribing(false);
              try {
                let { cancel_url } = link;
                let win = window.open(cancel_url, "_blank");
                win.focus();
              } catch (ee) {}
            }}
          >
            {unsubscribing == true ? (
              <Spin theme={"alfa-on-color"} visible={true} />
            ) : (
              "Unsubscribe!"
            )}
          </UpdateButton>
        </CoolModal>
      )}

      {upgradePaddleId == undefined ? null : (
        <CoolModal
          onClose={() => {
            setUpgradePaddleId(undefined);
          }}
        >
          <UnsText>
            Are you sure you want to change your subscription plan?
          </UnsText>

          <UpdateButton
            onClick={async () => {
              if (unsubscribing == true) {
                return;
              }
              setUnsubscribing(true);
              await BillingAPI.upgradePlan(upgradePaddleId);
              await timeout(2 * 1000);
              setUnsubscribing(false);
              setUpgradePaddleId(undefined);
              try {
                window.location.reload();
              } catch (exc) {}
            }}
          >
            {unsubscribing == true ? (
              <Spin theme={"alfa-on-color"} visible={true} />
            ) : (
              "Yes, change plan!"
            )}
          </UpdateButton>
        </CoolModal>
      )}
    </Wrapper>
  );
}

const UnsText = styled.div`
  color: white;
  font-size: 17px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`;

const UpdateButton = styled.div`
  background: #eb5e4f;
  border-radius: 5px;
  color: white;
  height: 46px;
  padding-left: 34px;
  padding-right: 34px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  font-weight: bold;

  :hover {
    opacity: 0.9;
  }
`;

const Wrapper = styled.div``;

const Content = styled.div``;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 46px;
  /* identical to box height, or 135% */

  color: #ffffff;
`;

const TopRight = styled.div``;

const timeout = (ms) => new Promise((res) => setTimeout(res, ms));
