import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import SubtitlesTemplate from "../templates/SubtitlesTemplate";

export default function FacebookPlugin() {
    useEffect(() => {
            window.fbAsyncInit = function() {
                window.FB.init({
                    xfbml            : true,
                    version          : 'v7.0'
                });
            };

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
    })
    return (
        <React.Fragment>
            <Wrapper>
                <div id="fb-root"></div>
                <div class="fb-customerchat"
                     attribution="setup_tool"
                     page_id="107417014145107"
                     theme_color="#008f97">
                </div>
            </Wrapper>
        </React.Fragment>
    );
}

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  z-index: 9999;
  bottom: 0;
`;
