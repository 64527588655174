/*
* REDUCER FOR VIDEO REDUCER
* */


import {
    MOVE_SUBTITLE_TO_FRAME,
    LOAD_VIDEO_SUBTITLE,
    SET_ACTIVE_FRAME,
    SET_MAIN_VIDEO_FRAME,
    UPDATE_FRAME_SUBTITLE, SET_VIDEO_SIZE, SET_VIDEO_PANEL_COLOR, SET_VIDEO_SCALE, SET_SUBTITLE_TIMING, REMOVE_SUBTITLE
} from "../actions/VideoActions";
import {SubtitlesService} from "../../services/SubtitlesService";
import {EditorConfig, VideoFormats} from "../../config/config";

const initialState = {
    subtitles: null,
    activeFrame: 0,
    startFrom: 0,
    videoSize: VideoFormats.SIZE_0,
    videoPanelBg: EditorConfig.defaultPanelColor,
    videoScale: EditorConfig.defaultVideoScale,
};

const VideoReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case REMOVE_SUBTITLE:
            debugger;
            return {
                ...state,
                subtitles: state.subtitles.reduce((accum, value, index) => index != action.data ? [...accum, value] : accum , [])
            }

        case SET_SUBTITLE_TIMING:
            const {start, end} = action.data;
            return {
                ...state,
                subtitles: state.subtitles.map((value, index) => {
                    return index !== action.data.index ? value : {
                        ...value,
                        from: start,
                        to: end
                    }
                })
            }


        case SET_VIDEO_SCALE:
            return {
                ...state,
                videoScale: action.data
            }
        case SET_VIDEO_SIZE:
            return {
                ...state,
                videoSize: action.data
            }

        case SET_VIDEO_PANEL_COLOR:
            return {
                ...state,
                videoPanelBg: action.data
            }

     case LOAD_VIDEO_SUBTITLE:
            return {
                ...state,
                subtitles: action.data
            }

        case SET_ACTIVE_FRAME:
            return {
                ...state,
                activeFrame: action.data
            }

        case SET_MAIN_VIDEO_FRAME:
            return {
                ...state,
                startFrom: action.data
        }

        case MOVE_SUBTITLE_TO_FRAME:
            const {text, index} = action;
            return {
                ...state,
                subtitles: SubtitlesService.moveSelectedToNewFrame(state.subtitles, index, text)
        }

        case UPDATE_FRAME_SUBTITLE:
            const {value, id} = action.data;
            const updatedSubtitles = state.subtitles.map((item, index) => {
                return index === id
                    ? {
                        ...item,
                        text: value
                    } : item;
            });

            return {
                ...state,
                subtitles: updatedSubtitles
            }

        default:
            return state;
    }

}

export default VideoReducer;
