import ServersAPI from '../../api/ServersAPI'

import * as types from '../ActionTypes'


const loadServers_ = () => {
    return {
        type: types.LOAD_SERVERS
    }
}
export const loadServersSuccess = (servers) => {
    return {
        type: types.LOAD_SERVERS_SUCCESS,
        servers: servers
    }
}
let loadServersFailed = (error) => {
    return {
        type: types.LOAD_SERVERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllServers() {
    return (dispatch, getState) => {
        dispatch(loadServers_());
        return ServersAPI.getAllServers().then(
            servers => dispatch(loadServersSuccess(servers)),
            error => dispatch(loadServersFailed(error))
        )
    }
}

export function getServer(id) {
    return (dispatch, getState) => {
        dispatch(loadServers_());
        return ServersAPI.getServer(id).then(
            proj => dispatch(loadServersSuccess([proj])),
            error => dispatch(loadServersFailed(error))
        )
    }
}

let createServer_ = () => {
    return {
        type: types.CREATE_SERVER
    }
}
let createServerSuccess = (server) => {
    return {
        type: types.CREATE_SERVER_SUCCESS,
        server: server
    }
}
let createServerFailed = (error) => {
    return {
        type: types.CREATE_SERVER_FAIL,
        error: error
    }
}

//thunk
export function createServer(data) {
    return (dispatch, getState) => {
        dispatch(createServer_());
        return ServersAPI.createServer(data).then(
            server => dispatch(createServerSuccess(server)),
            error => dispatch(createServerFailed(error))
        )
    }
}

let updateServer_ = () => {
    return {
        type: types.UPDATE_SERVER
    }
}
let updateServerSuccess = (server) => {
    return {
        type: types.UPDATE_SERVER_SUCCESS,
        server: server
    }
}
let updateServerFailed = (error) => {
    return {
        type: types.UPDATE_SERVER_FAIL,
        error: error
    }
}

//thunk
export function updateServer(data) {
    return (dispatch, getState) => {
        dispatch(updateServer_());
        return ServersAPI.updateServer(data).then(
            server => dispatch(updateServerSuccess(server)),
            error => dispatch(updateServerFailed(error))
        )
    }
}

let deleteServer_ = () => {
    return {
        type: types.DELETE_SERVER
    }
}
let deleteServerSuccess = (id) => {
    return {
        type: types.DELETE_SERVER_SUCCESS,
        id: id
    }
}
let deleteServerFailed = (error) => {
    return {
        type: types.DELETE_SERVER_FAIL,
        error: error
    }
}

//thunk
export function deleteServer(id) {
    return (dispatch, getState) => {
        dispatch(deleteServer_());
        return ServersAPI.deleteServer(id).then(
            () => dispatch(deleteServerSuccess(id)),
            error => dispatch(deleteServerFailed(error))
        )
    }
}
