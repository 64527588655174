import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const UsersAPI = {

    async parselessLogout() {
        try {
            window.localStorage.removeItem(`auth_token`);
        } catch (exc) {
            throw new Error(exc);
        }
    },

    async parselessLogin(email, password, social = null) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/users/login`, {email, password, social})).data;
            if (pld.error != undefined) {
                throw pld.error;
            }
            let {token} = pld.result;
            console.log('got token = ', token);
            window.localStorage.setItem(`auth_token`, token);
            let us = (await axios.get(`${API_ENDPOINT}/user/token/${token}`)).data;
            return us;
        } catch (exc) {
            throw exc;
        }
    },

    async parselessSocialLogin(email, password, social = null) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/users/login/social`, {email, password, social})).data;
            if (pld.error != undefined) {
                throw pld.error;
            }
            let {token} = pld.result;
            console.log('got token = ', token);
            window.localStorage.setItem(`auth_token`, token);
            let us = (await axios.get(`${API_ENDPOINT}/user/token/${token}`)).data;
            return us;
        } catch (exc) {
            throw exc;
        }
    },

    async parselessSignup(email, password) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/users/signup`, {email, password})).data;
            if (pld.error != undefined) {
                throw pld.error;
            }
            let {token} = pld.result;
            window.localStorage.setItem(`auth_token`, token);
            return (await axios.get(`${API_ENDPOINT}/user/token/${token}`)).data;
        } catch (exc) {
            throw new Error(exc);
        }
    },

    async parselesslyGetCurrentUser() {
        let token = window.localStorage.getItem(`auth_token`);
        let pld = (await axios.get(`${API_ENDPOINT}/user`)).data;
        console.log('parselesslyGetCurrentUser: pld = ', pld);
        if (pld.error != undefined) {
            return undefined;
        }
        // return (pld.result == undefined) ? undefined : pld.result.user;
        return (pld.result == undefined) ? undefined : pld.result;
    },

    activatePush(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/notification`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    createUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/create`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    getUsersByIds(ids) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/by_ids`, {ids: ids}).then(d => d.data).then(users => {
                if (users.error != undefined) {
                    return reject(users.error);
                }
                resolve(users);
            }).catch(err => reject(err));
        });
    },


    updateUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/update`, data).then(d => d.data).then(
                pld => {
                    if (pld.error != undefined) {
                        return reject(pld.error);
                    }
                    resolve(pld.result);
                }
            ).catch(err => reject(err))
        })
    },

    deleteUser(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/delete/:${id}`).then(d => d.data).then(
                () => resolve(id)
            ).catch(err => reject(err))
        });
    },

    getUsersByQueryMap(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/users/query`, {
                query: queryMap
            }).then(d => d.data).then(
                users => resolve(users)
            ).catch(err => reject(err))
        });
    },

    getAllUsers(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/users/all`).then(d => d.data).then(
                users => {
                    resolve(users);
                }
            ).catch(err => reject(err))
        });
    },

    getAllStudents() {
        return new Promise((resolve, reject) => {
            let queryMap = {
                equalTo: [['userRole', 'student']]
            };
            this.getUsersByQueryMap(queryMap).then(users => {
                resolve(users.sort((a, b) => (+b.tiemsatmp - +a.timestamp)));
            }).catch(err => {
                reject(err);
            });
        });
    },

    getAllTeachers() {
        return new Promise((resolve, reject) => {
            let queryMap = {
                equalTo: [['userRole', 'teacher']]
            };
            this.getUsersByQueryMap(queryMap).then(users => {
                resolve(users.sort((a, b) => (+b.timestamp - +a.timestamp)));
            }).catch(err => {
                reject(err);
            });
        });
    },


    getUserById(userId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/user/${userId}`).then(d => d.data).then(user => {
                if (user.error != undefined) {
                    return reject(user.error);
                }
                resolve(user);
            }).catch(err => {
                reject(err);
            })
        })
    },

    createStudentTeacherLink(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/student_teacher_links/create`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    updateStudentTeacherLink(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/student_teacher_links/update`, data).then(d => d.data).then(
                user => {
                    if (user.error != undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                }
            ).catch(err => reject(err))
        })
    },

    deleteStudentTeacherLink(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/student_teacher_links/delete/:${id}`).then(d => d.data).then(
                () => resolve(id)
            ).catch(err => reject(err))
        });
    },

    getStudentTeacherLinkById(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/student_teacher_link/${id}`).then(d => d.data).then(user => {
                if (user.error != undefined) {
                    return reject(user.error);
                }
                resolve(user);
            }).catch(err => {
                reject(err);
            })
        })
    },

    createStudentTeacherLinkByTeacherEmail(studentId, teacherEmai) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/student_teacher_links/add_teacher_by_email/${studentId}`, {
                email: teacherEmai
            }).then(d => d.data).then(
                link => {
                    if (link.error != undefined) {
                        return reject(link.error);
                    }
                    resolve(link);
                }
            ).catch(err => reject(err))
        })
    },

    createStudentTeacherLinkByStudentEmail(teacherId, studentEmail) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/student_teacher_links/add_student_by_email/${teacherId}`, {
                email: studentEmail
            }).then(d => d.data).then(
                link => {
                    if (link.error != undefined) {
                        return reject(link.error);
                    }
                    resolve(link);
                }
            ).catch(err => reject(err))
        })
    },

    getTeacherUserLinks(teacherId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/student_teacher_links/teacher/${teacherId}`).then(d => d.data).then(links => {
                resolve(links);
            }).catch(err => reject(err))
        });
    }


}

export default UsersAPI;
