import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const WAVES = [
    {
        name: 'linear',
        image: require('../../../assets/images/sound.svg'),
        goodImg: require('../../../assets/images/lin_1.png')
    },
    {
        name: 'linear-2',
        image: require('../../../assets/images/wave.svg'),
        goodImg: require('../../../assets/images/lin_1.png')
    },
    {
        name: 'circle-1',
        image: require('../../../assets/images/sun.svg'),
        goodImg: require('../../../assets/images/circle_1.png')
    },
    {
        name: 'linear',
        image: require('../../../assets/images/wave.svg'),
        goodImg: require('../../../assets/images/circle_1.png')
    },
];

export default function WavesSelector(props) {
    const {
        onSelect = (a, b) => {

        }
    } = props;


    return (
        <Wrapper>

            {WAVES.map((a, j) => {
                return (
                    <SlideItem key={j} onClick={() => {
                        onSelect(a.name, a.goodImg);
                    }}>
                        <SlideImg src={a.image}/>
                    </SlideItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


const SlideItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 35px;
    margin-right: 35px;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    width: 120px;
    background: ${props => (props.selected == true ? 'rgba(211,211,211,0.5)' : 'transparent')};
    :hover{
      opacity: 0.8;
      background: rgba(211,211,211,0.5);
    }
`;


const SlideLabel = styled.div`
    text-align: center;
    font-size: 22px;
    padding: 10px;
`;

const SlideImg = styled.img`
    height: 30px;
    margin-bottom: 10px;
`;
