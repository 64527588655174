import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';

import styled, {css} from 'styled-components';
import CommonHelper from "../../helpers/CommonHelper";

const MENU = [
    {
        label: 'Workspace',
        key: 'workspace',
        url: '/'
    },    {
        label: 'Billing',
        key: 'billing',
        url: '/billing'
    },
    {
        label: 'Pricing',
        key: 'pricing',
        url: '/pricing'
    },
    {
        label: 'Profile',
        key: 'profile',
        url: '/profile'
    },
];

const validateUrl = () => {

}

const windowObj = typeof window !== `undefined` ? window : null;

export default function SidebarContentTemplate({
    children
                                               }) {

    const [activeUrl, setActiveUrl] = useState(null);


    const navigationChanger = () => {
        setActiveUrl(windowObj.location.hash)
    }

    const findMenuKey = (key) => activeUrl != null && activeUrl.includes(key)

    useEffect(() => {
        if(windowObj != null){
            setActiveUrl(windowObj.location.hash)
            windowObj.addEventListener('popstate', navigationChanger);
        }
        return () => {
            if(windowObj != null){
                windowObj.removeEventListener('popstate', navigationChanger, false);
            }
        }
    }, [])

    return (
        <Wrapper>
            <SidebarContainer>
                <SidebarMenu>
                    {
                        MENU.map(({label, url, key}) => (<SidebarMenuItem onClick={() => CommonHelper.linkTo(url)} active={findMenuKey(key)}>{label}</SidebarMenuItem>))
                    }

                </SidebarMenu>
            </SidebarContainer>
            <ContentArea>
                {children}
            </ContentArea>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
`;
const SidebarContainer = styled.div`
  max-width: 300px;
  flex: 1;
`;
const ContentArea = styled.div`
  padding: 0 10px;
  flex: 1;
`;


const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const SidebarMenuItem = styled.div`
  color: #fff;
  cursor: pointer;
  padding: 7px;
  margin: 2px 0;

  :hover {
    background-color: #212330;
    border-radius: 10px;
  }
  
  ${props => props.active && css `
      background-color: #212330;
      border-radius: 10px;
  `}
`;
