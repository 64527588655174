import 'firebase/auth';
import withFirebaseAuth, { WrappedComponentProps } from 'react-with-firebase-auth';
import {connectFirebase} from "./Firebase";
import firebase from "./../helpers/Firebase";


const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    facebookProvider: new firebase.auth.FacebookAuthProvider()
};

const firebaseAppAuth = firebase.auth();

export const withSocialAuth = withFirebaseAuth({
    providers,
    firebaseAppAuth,
});

export const generatePasswordFromUid = (uid) => window.btoa(uid).slice(0, 8);
