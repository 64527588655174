import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {SliderBar} from "../../ui/slider-bar";
import {useMappedState} from "redux-react-hook";
import axios from "axios";
import {API_ENDPOINT} from "../../../constants/config";
import Spin from "arui-feather/spin";

let lockPurchase = false;
export const CreditPurchasePannel = () => {
    const {
        plans,
        isAdmin,
        currentUser,
        currentUserId,
        currentPlanId,
        currentPlan,
    } = useMappedState(
        useCallback((state) => {
            return {
                loading: state.users.loading || state.plans.loading,
                plans: state.plans.plansMap
                    .toArray()
                    .sort((a, b) => +b.timestamp - +a.timestamp),
                currentUser: state.users.usersMap.get(state.users.currentUserId),
                currentUserId: state.users.currentUserId,
                currentPlanId: state.plans.currentPlanId,
                currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
            };
        }, [])
    );

    const [creditValue, setCreditValue] = useState(1);
    const [paymenyUrl, setPaymenyUrl] = useState(null);
    const [loading, setLoading] = useState(false);


    const getPaymentUrl = async (creditValue, price) => {
        let pld = (await axios.post(`${API_ENDPOINT}/paddle/gpayment`, {
            quantity: creditValue,
            price: price
        })).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        setPaymenyUrl(pld);
    }

    const oneVidePrice = useMemo(() => {
        if (creditValue <= 5) {
            return 5;
        } else if (creditValue <= 10) {
            return 3.50;
        } else if (creditValue <= 15) {
            return 2.50;
        } else if (creditValue <= 30) {
            return 1.50;
        } else if (creditValue <= 50) {
            return 0.95;
        } else if (creditValue <= 75) {
            return 0.80;
        } else {
            return 0.70
        }
    }, [creditValue]);

    const maybePluralize = (count, noun, suffix = 's') =>
        `${count} ${noun}${count !== 1 ? suffix : ''}`;

    useEffect(() => {
        const t = setTimeout(async () => {
            if (creditValue != null) {
                await getPaymentUrl(creditValue, oneVidePrice)
            }
            setLoading(false);
        }, 300)
        return () => clearTimeout(t)
    }, [creditValue, oneVidePrice])


    useEffect(() => {
        let timer;
        if (loading) {
            timer = setTimeout(async () => {
                setLoading(false);
            }, 600)
        }

        return () => {
            clearTimeout(timer);
        }
    }, [])

    return (
        <Wrapper>
            <div className={'left'}>
                <div className={'desc'}>
                    If you out of your subscription plan limits 😣. You can purchase extra amount of videos on top of
                    your subscription.🤠
                </div>
            </div>
            <div className={'right'}>
                <div className={'info-value'}>
                    <div className={'call-action'}>
                        <div>How Many video's you wanna add? 🤔</div>
                    </div>
                    <div className={'video-counter'}><span>{maybePluralize(creditValue, 'video')}</span></div>
                </div>
                <PurchaseContainer>
                    <SliderBar
                        from={1}
                        value={creditValue}
                        to={100}
                        onChange={(value) => {
                            setLoading(true);
                            setCreditValue(value);
                        }}
                    />
                </PurchaseContainer>
                <PurchaseButton disabled={loading} onClick={async () => {
                    if (paymenyUrl == null || loading) {
                        return;
                    }

                    const transaction_ids = window.btoa(`${currentUserId}|${+new Date()}`);
                    axios.post(`${API_ENDPOINT}/user/precredit`, {
                        transaction_ids: transaction_ids
                    }).then(({data}) => {
                        window.Paddle.Checkout.open({
                            override: paymenyUrl,
                            allowQuantity: false,
                            disableLogout: true,
                            passthrough: JSON.stringify({
                                userId: currentUserId,
                                quantity_video: creditValue,
                                transaction_ids: transaction_ids
                            }),
                            email: currentUser?.email,
                        });
                    })
                    lockPurchase = false;
                }}>
                    {
                        loading
                            ? <Spin theme={'alfa-on-color'} visible={true}/>
                            : <span>Pay ${Math.ceil((creditValue * oneVidePrice))}</span>
                    }

                </PurchaseButton>
            </div>
        </Wrapper>
    );
}

const PurchaseButton = styled.div`
  box-sizing: border-box;
  background-color: #EA523D;
  padding: 14px;
  font-size: 18px;
  width: 70%;
  min-width: 200px;

  margin: 20px auto 0;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  color: #fff;
  transition: .2s;
  cursor: pointer;

  :hover {
    opacity: .9;
  }

  ${(props) => props.disabled && css`
    opacity: 0.9;
    background-color: rgb(33, 35, 48)
  `}

`;

const Wrapper = styled.div`
  box-shadow: 0 0 10px 1px #ffac3185;
  padding: 20px;
  border: 1px solid #ffac31;
  border-radius: 5px;
  display: flex;
  flex-direction: row;

  .left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .desc {
      color: #fff;
      font-size: 18px;
      text-align: center;
      padding: 5px;
    }
  }

  .right {
    width: 50%;

    .video-counter {
      text-align: center;
      font-size: 25px;
      color: #ffac31;
      font-weight: bold;
      padding-top: 20px;
    }
  }

  .info-value {
    color: #fff;

    .call-action {
      font-size: 22px;
      text-align: center;
    }
  }
`;
const PurchaseContainer = styled.div`
  box-sizing: border-box;
  padding: 0 20px;
  display: block;

`;
