import PlansAPI from '../../api/PlansAPI'

import * as types from '../ActionTypes'

let loadPlans_ = () => {
    return {
        type: types.LOAD_PLANS
    }
}
let loadPlansSuccess = (plans) => {
    return {
        type: types.LOAD_PLANS_SUCCESS,
        plans: plans
    }
}
let loadPlansFailed = (error) => {
    return {
        type: types.LOAD_PLANS_FAIL,
        error: error
    }
}

//thunk
export function loadAllPlans() {
    return (dispatch, getState) => {
        dispatch(loadPlans_());
        return PlansAPI.getAllPlans().then(
            plans => dispatch(loadPlansSuccess(plans)),
            error => dispatch(loadPlansFailed(error))
        )
    }
}

let createPlan_ = () => {
    return {
        type: types.CREATE_PLAN
    }
}
let createPlanSuccess = (plan) => {
    return {
        type: types.CREATE_PLAN_SUCCESS,
        plan: plan
    }
}
let createPlanFailed = (error) => {
    return {
        type: types.CREATE_PLAN_FAIL,
        error: error
    }
}

//thunk
export function createPlan(data) {
    return (dispatch, getState) => {
        dispatch(createPlan_());
        return PlansAPI.createPlan(data).then(
            plan => dispatch(createPlanSuccess(plan)),
            error => dispatch(createPlanFailed(error))
        )
    }
}

let updatePlan_ = () => {
    return {
        type: types.UPDATE_PLAN
    }
}
let updatePlanSuccess = (plan) => {
    return {
        type: types.UPDATE_PLAN_SUCCESS,
        plan: plan
    }
}
let updatePlanFailed = (error) => {
    return {
        type: types.UPDATE_PLAN_FAIL,
        error: error
    }
}

//thunk
export function updatePlan(data) {
    return (dispatch, getState) => {
        dispatch(updatePlan_());
        return PlansAPI.updatePlan(data).then(
            plan => dispatch(updatePlanSuccess(plan)),
            error => dispatch(updatePlanFailed(error))
        )
    }
}

let deletePlan_ = () => {
    return {
        type: types.DELETE_PLAN
    }
}
let deletePlanSuccess = (id) => {
    return {
        type: types.DELETE_PLAN_SUCCESS,
        id: id
    }
}
let deletePlanFailed = (error) => {
    return {
        type: types.DELETE_PLAN_FAIL,
        error: error
    }
}

//thunk
export function deletePlan(id) {
    return (dispatch, getState) => {
        dispatch(deletePlan_());
        return PlansAPI.deletePlan(id).then(
            () => dispatch(deletePlanSuccess(id)),
            error => dispatch(deletePlanFailed(error))
        )
    }
}

export function setActivePlan(planId, expirationTimestamp, startTimestamp) {
    return (dispatch, getState) => {
        dispatch({type: types.SET_CURRENT_PLAN_DETAILS, planId: planId, expirationTimestamp: expirationTimestamp, planStartTimestamp: startTimestamp});
    }
}


