import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserWorkspacePanel from "../workspace/panels/UserWorkspacePanel";
import FacebookPlugin from "../ui/FacebookPlugin";

export default function IndexApp() {

    return (
        <SubtitlesTemplate active={'index'}>
            <UserWorkspacePanel />
            {/* <FacebookPlugin/> */}

        </SubtitlesTemplate>
    );
}
