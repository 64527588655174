import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ReactHelper from "../../helpers/ReactHelper";
import CommonHelper from "../../helpers/CommonHelper";
import SubtitlesHelper from "../../helpers/SubtitlesHelper";

const gradImg = require('../../assets/images/grad_dark.png')

export default function FakeLogsTool(props) {
    const {

        interval = 60,
        maxVisibleNumber = 20,
    } = props;
    const [items, setItems] = useState([]);
    const currNum = useRef(0);

    ReactHelper.useInterval(() => {
        currNum.current = currNum.current + 1;
        let sec = Math.round(currNum.current * interval / 1000.0);
        let fps = 300 + Math.round(Math.random() * 10);
        let speed = 10 + Math.round(Math.random() * 7) / 10.0;
        let newItems = items.concat([
            {
                id: uuid(),
                text: `frame ${currNum.current} fps=${fps} size=N/A time=${SubtitlesHelper.toHHMMSS(sec)} bitrate=N/A speed=${speed}x`
            }
        ]);
        setItems(newItems.slice(-maxVisibleNumber));
    }, interval);

    return (
        <Wrapper>

            <ItemsList>
                {items.map((it, i) => {
                    return (
                        <ItemRow key={it.id}>
                            {it.text}
                        </ItemRow>
                    )
                })}
            </ItemsList>

            <Overlay src={gradImg}></Overlay>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow-y: hidden;
    position: relative;
    background: #171822;
`;

const ItemsList = styled.div`
    
`;

const ItemRow = styled.div`
    margin-bottom: 10px;
    color: white;
    font-size: 14px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
`;
