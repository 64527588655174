import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useImperativeHandle,
    forwardRef,
    useReducer,
    useMemo
} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

// import {UPLOAD_DO_ENDPOINT_URL} from "../../constants/config";

import Dropzone, {useDropzone} from 'react-dropzone'
import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";

export default forwardRef((props, ref) => {
    const {
        uploadSuffix = 'upload',
        // uploadSuffix = 'pm2/upload',
        onUploaded = pld => {
        },
        onStart = () => {

        },
        onFail = () => {

        },
        onProgress = d => {

        },
        onFileDrop = f => {

        },
        children,
        accept = '.mp4,.mov,.mpg,.vob,.avi'
    } = props;

    const selectedFile = useRef();

    useImperativeHandle(ref, () => ({
        uploadFile: (url) => {
            let file = selectedFile.current;
            const fd = new FormData();
            fd.append('file', file);
            onStart();
            axios.post(url == undefined ? `${API_ENDPOINT}/${uploadSuffix}` : url, fd, {
                timeout: 60 * 60 * 1000,
                onUploadProgress: progressEvent => {
                    let {loaded, total} = progressEvent;
                    let progress = 100.0 * (+loaded) / (+total);
                    console.log('progress: ', progress);
                    onProgress({progress, loaded, total});
                },
                params: {
                    userId: window.currentUserId
                }
            }).then(d => d.data.result).then(pld => {
                onUploaded(pld);
            }).catch(err => {
                try{
                    console.log('caught error = ', err);
                    console.log('err.response = ', err.response);
                    onFail(err.response.data);
                }catch(exc){
                    onFail({});
                }
            })
        }
    }));

    const onDrop = useCallback((files) => {
        console.log('onDrop occured: files = ', files);
        selectedFile.current = files[0];
        onFileDrop(selectedFile.current);
    }, []);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});


    return (
        <Wrapper className={'SimpleUploadPanel_wr'} {...getRootProps()} >
            <input {...getInputProps()} accept={accept} maxSize={10000000000}/>
            {children}
        </Wrapper>
    );
})

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px; 
    outline: none;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
