import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

import AnimationPlayer from "./AnimationPlayer";
import WaveHelper from "../../../helpers/WaveHelper";

export default function CachedAnimationPlayer(props) {
    const {
        onReady = () => {

        },
        id = 'sss',
        // audioUrl = 'https://www.englishpatient.org/english-patient-files/0408a175-0074-4ba7-8e76-171d46d2a2e2.mp3',
        audioUrl = 'https://s3-eu-west-1.amazonaws.com/subtitles-files/Tax9AqEuL3/7df94053-ac00-4b72-80d5-b48e77353074.mp3',
        width,
        height,
        options = {
            h_mirror: true,
            v_mirror: false,
            reversed: false,
            top: false,
            width_p: 0.8,
            height_p: 0.8,
            downsample: 0.01,
            wave_color: `#7eff5e`,
            shadow: false,
            wave_shadow: `#7eff5e`,
            follow_inensity: false,
            scale: true,
            scale_k: 1,
            approximation: true,
            approximation_k: 10,
            clean_nuls: false,
            fill: true,
            scaleX: 1,
            scaleY: 1
        },
        drawFunction = window.eval(`(${WaveHelper.deafultWaveCode})`)
        // drawFunction = drawBarC
    } = props;
    const [cachedUrl, setCachedUrl] = useState();

    useEffect(() => {
        CommonHelper.getCachedUrl(audioUrl).then(cUrl => {
            setCachedUrl(cUrl);
            onReady();
        })
    }, []);

    if (cachedUrl == undefined) {
        return (<Wrapper width={width} height={height}><span>loading...</span></Wrapper>)
    }

    return (
        <Wrapper width={width} height={height} >
            <AnimationPlayer
                id={id}
                options={{...options, width, height}}
                audioUrl={cachedUrl}
                drawFunction={drawFunction}
                width={width}
                height={height}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    box-sizing: border-box;
`;
