import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleUploadPanel from "./SimpleUploadPanel";
import SimpleControledUploadPanel from "./SimpleControledUploadPanel";
import './style.css'
import CoolModal from '../modals/CoolModal'
import {SUPPORTED_LANGUAGES} from "../../constants/config";

import {Button} from "../projects/panels/ProjectPanel";

import Select from 'react-select'
import CommonHelper from "../../helpers/CommonHelper";
import {Analytics} from "../../utils/AnalyticsUtility";
import TelegramAPI from "../../api/TelegramAPI";


const LANG_OPTIONS = SUPPORTED_LANGUAGES.map(a => ({label: a, value: a}))

const getMb = a => {
    if (a == undefined || window.isNaN(a) == true) {
        return 0;
    }
    return +(+a / 1024.0 / 1024.0).toFixed(1);
}

const getUploadLimits = () => {
    let d = (window.uploadLimits == undefined) ? {} : window.uploadLimits;
    let {size = 1000, duration = 120} = d;
    return {
        size: size,
        duration: size
    }
}

const isGoodSize = (bitesSize = 0) => {
    let d = getUploadLimits();
    let {size = 1000} = d;
    let mvSize = (+bitesSize) / 1024.0 / 1024.0;
    return (+mvSize <= +size);
}

export default function UploadVideoPanelWithConfirmation(props) {
    const {
        currentUser,
        userId = '',
        hasWatermark = false,
        uploadText = 'Upload new video',
        onUploaded = (u, lang) => {

        },
        onProgress = d => {

        },
        onStart = () => {

        },
        quotaOver = false
    } = props;
    const selectedFile = useRef();
    const uploaderRef = useRef();
    const [fileSelected, setFileSelected] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [url, setUrl] = useState();
    const [progressData, setProgressData] = useState(undefined);
    const [badFormatSelected, setBadFormatSelected] = useState(false);

    const [errorMessage, setErrorMessage] = useState(undefined);

    const [isBigFileUploaded, setIsBigFileUploaded] = useState(false);
    const [isLongFileUploaded, setIsLongFileUploaded] = useState(false);

    const {
        currentPlanId,
        currentPlan,
        ss
    } = useMappedState(useCallback(state => {
        return {
            currentPlanId: state.plans.currentPlanId,
            currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
            ss: state.plans.plansMap,
        }
    }, []));

    const renderDropMsg = () => {
        let time = 30;
        let size = '500mb';

        if(currentPlanId == '2v7DU1gu8l'){
            time = 60;
            size = '2GB'
        } else if(currentPlanId == 'zdCnvJmPIo') {
            time = 30;
            size = '800mb'
        } else if(currentPlanId == 'EEySyPbAeu') {
            time = 40;
            size = '1.5GB'
        }

        return `Up to ${time} minutes and ${size}`;
    }

    let text = (uploading == true) ? `Uploading...` : uploadText;

    if (url != undefined) {
        text = 'Uploaded!';
    }
    let statsText = (progressData == undefined) ? '' : `${progressData.progress.toFixed(0)}% (${getMb(progressData.loaded)} MB)`;

    const [lang, setLang] = useState('English');

    return (
        <Wrapper>
            <SimpleControledUploadPanel ref={uploaderRef} onUploaded={pld => {
                setUrl(pld.url);
                setUploading(false);
                try {
                    window.mixpanel.track('VideoUploaded', {
                        ...pld,
                        name: selectedFile.current.name,
                        lang: lang
                    });
                } catch (eee) {

                }
                onUploaded({...pld, lang: lang, name: selectedFile.current.name});
            }}

                                        onFail={async failPld => {
                                            console.log('onUploadFail: failPld = ', failPld);
                                            if (failPld.code == 11) {
                                                setIsLongFileUploaded(true);
                                                await TelegramAPI.sendMessageToAdmins(`[FAILED_TO_UPLOAD_TOO_LONG_FILE]: User ${currentUser.email} uploaded too long file - ${failPld.details.duration} minutes`);
                                                try {
                                                    window.mixpanel.track('VideoUploadFailTooLongVideoDuration', {
                                                        name: selectedFile.current.name,
                                                        lang: lang
                                                    });
                                                } catch (eee) {
                                                }
                                            } else {
                                                setErrorMessage('Failed to upload file! Please try more time.');
                                                await TelegramAPI.sendMessageToAdmins(`[FAILED_TO_UPLOAD]: unknown reason`);
                                            }
                                        }}

                                        onProgress={d => {
                                            console.log('onProgress: d = ', d);
                                            onProgress(d);
                                            setProgressData(d);
                                        }} onStart={() => {
                Analytics.send('editor.upload_video', 'editor');
                setUploading(true);
                onStart();
            }}
                                        onFileDrop={async f => {
                                            console.log('onFileDrop: f = ', f);
                                            if (f == undefined) {
                                                return;
                                            }
                                            if (f != undefined && f.type != undefined) {
                                                if (f.type != undefined && f.type != "" && f.type.indexOf('video') == -1) {
                                                    setBadFormatSelected(true);
                                                    if (currentUser != undefined) {
                                                        await TelegramAPI.sendMessageToAdmins(`[BAD FILE SELECTED]: User ${currentUser.email} загрузил хуйню: "${f.name}"`)
                                                    }
                                                    return;
                                                }
                                            }
                                            let sz = +f.size;
                                            if (!isGoodSize(sz)) {
                                                setIsBigFileUploaded(true);
                                                await TelegramAPI.sendMessageToAdmins(`[BIG FILE SIZE]: User ${currentUser.email} uploaded too big file - ${Math.round(+f.size / 1024.0 / 1024.0)} MB`);
                                                try {
                                                    window.mixpanel.track('VideoUploadFailTooBigFileSize', {
                                                        name: selectedFile.current.name,
                                                        lang: lang
                                                    });
                                                } catch (eee) {
                                                }
                                                return;
                                            }
                                            selectedFile.current = f;
                                            setFileSelected(true);
                                            if (currentUser != undefined) {
                                                await TelegramAPI.sendMessageToAdmins(`[FILE SELECTED]: User ${currentUser.email} selected file "${f.name}"`)
                                            }
                                        }}
            >
                <Inner>

                    <CloudImageOuterCircle>
                        <CloudImageInnerCircle>
                            <CloudImage src={require('../../assets/images/mdi_cloud_upload_q.svg')}/>
                        </CloudImageInnerCircle>
                    </CloudImageOuterCircle>

                    <UploadText>
                        {text}
                    </UploadText>
                    {uploading == false ? null :
                        <StatsPlaceholder>
                            {statsText}
                        </StatsPlaceholder>
                    }
                    <SubInfoText> {renderDropMsg()}</SubInfoText>
                </Inner>
            </SimpleControledUploadPanel>

            {fileSelected &&
            <CoolModal onClose={async () => {
                selectedFile.current = undefined;
                setFileSelected(false);
                if (currentUser != undefined) {
                    await TelegramAPI.sendMessageToAdmins(`[FILE SELECTION CANCELED]: User ${currentUser.email} closed modal with selected file`)
                }
            }}>

                {quotaOver == true ?
                    <>
                        <Heading style={{textAlign: 'center'}}>
                            Quota is over
                        </Heading>
                        <QuotaP style={{textAlign: 'center'}}>
                            Please update your subscription.
                        </QuotaP>
                        <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Button style={{height: 52}} onClick={() => {
                                CommonHelper.linkTo(`/pricing`);
                            }}>
                                Update subscription
                            </Button>
                        </div>
                    </>
                    :
                    <>
                        <Heading>
                            Auto-generate subtitles
                        </Heading>

                        <NameLine>
                            <NameSpan>
                                {selectedFile.current.name}
                            </NameSpan>
                            <DeleteSpan onClick={async () => {
                                selectedFile.current = undefined;
                                setFileSelected(false);
                                if (currentUser != undefined) {
                                    await TelegramAPI.sendMessageToAdmins(`[FILE SELECTION CANCELED]: User ${currentUser.email} closed modal with selected file`)
                                }
                            }}>
                                Delete
                            </DeleteSpan>
                        </NameLine>

                        <LangSection>
                            <LangSelectPlaceholder>
                                <LangLabel>
                                    Select the language spoken in the video
                                </LangLabel>
                                <Select options={LANG_OPTIONS}
                                        classNamePrefix={'sel-lang'}
                                        className={'sel-lang-selector'}
                                        value={LANG_OPTIONS.filter(a => (a.value == lang))[0]}
                                        placeholder={''}
                                        onChange={async a => {
                                            setLang(a.value);
                                            if (currentUser != undefined) {
                                                await TelegramAPI.sendMessageToAdmins(`[LANGUAGE CHANGED]: User ${currentUser.email} selected language "${a.value}"`)
                                            }
                                        }}/>
                            </LangSelectPlaceholder>
                        </LangSection>

                        <ButtonPlaceholder>
                            <Button onClick={async () => {
                                uploaderRef.current.uploadFile(`${window.VIDEO_UPLOAD_PREFIX}/${userId}/${lang}${hasWatermark == true ? '?watermark=1' : ''}`);
                                setFileSelected(false);
                                if (currentUser != undefined) {
                                    await TelegramAPI.sendMessageToAdmins(`[UPLOADING STARTED]: User ${currentUser.email} started uploading video`)
                                }
                            }}>
                                Save
                            </Button>
                        </ButtonPlaceholder>
                    </>
                }

            </CoolModal>}

            {badFormatSelected &&
            <CoolModal onClose={() => {
                setBadFormatSelected(false);
            }}>

                <BadModalInner>

                    <FacepalmImg src={require('../../assets/images/facepalm.png')}/>

                    <BadModalInnerText>
                        Please upload VIDEO.
                    </BadModalInnerText>

                    <BadModalSubInnerText>
                        One of types
                        <br/>
                        <span style={{opacity: 0.5}}>
                            .mp4, .mov, .3gp, .webm, .avi, .vob, .mpg, etc...
                        </span>
                    </BadModalSubInnerText>

                </BadModalInner>

            </CoolModal>
            }

            {(isBigFileUploaded == true) &&
            <CoolModal onClose={() => {
                setIsBigFileUploaded(false);
            }}>

                <BadModalInner>

                    <FacepalmImg src={require('../../assets/images/blow_img.png')}/>

                    <BadModalInnerText>
                        Too big file!
                    </BadModalInnerText>

                    {window.uploadLimits == undefined ? null :
                        <BadModalSubInnerText>
                            {`Your current pricing plan allows uploading files less than ${window.uploadLimits.size} MB`}
                        </BadModalSubInnerText>
                    }

                    <div style={{marginTop: 20}}>
                        <Button onClick={() => {
                            CommonHelper.linkTo(`/pricing`);
                        }}>
                            Upgrade plan
                        </Button>
                    </div>

                </BadModalInner>

            </CoolModal>
            }

            {(isLongFileUploaded == true) &&
            <CoolModal onClose={() => {
                setIsLongFileUploaded(false);
                window.location.reload();
            }}>

                <BadModalInner>

                    <FacepalmImg src={require('../../assets/images/blow_img.png')}/>

                    <BadModalInnerText>
                        Too long file!
                    </BadModalInnerText>

                    {window.uploadLimits == undefined ? null :
                        <BadModalSubInnerText>
                            {`Your current pricing plan allows uploading files shorter than ${window.uploadLimits.duration} minutes`}
                        </BadModalSubInnerText>
                    }

                    <div style={{marginTop: 20}}>
                        <Button onClick={() => {
                            CommonHelper.linkTo(`/pricing`);
                        }}>
                            Upgrade plan
                        </Button>
                    </div>

                </BadModalInner>

            </CoolModal>
            }

        </Wrapper>
    );
}

const FacepalmImg = styled.img`
  width: 160px;
`;

const BadModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BadModalInnerText = styled.div`
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
`;


const BadModalSubInnerText = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const QuotaP = styled.div`
  color: white;
  font-weight: bold;
  font-size: 17px;
`;

const SubInfoText = styled.div`
  text-align: center;
  color: #212330;
  opacity: 0.5;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-top: 20px;
  width: 100%;
  box-sizing: border-box;
  background: #FFAC30;
`;

const CloudImage = styled.img`
  width: 46px;
  height: 46px;
`;

const CloudImageInnerCircle = styled.div`
  width: 78px;
  height: 78px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloudImageOuterCircle = styled.div`
  width: 96px;
  height: 96px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const UploadText = styled.div`
  text-align: center;
  color: #212330;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
`;

const StatsPlaceholder = styled.div`
  font-size: 12px;
  color: white;
  opacity: 0.5;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 34px;
  line-height: 46px;
  color: white;
  margin-bottom: 32px;
`;

const NameLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 35px;
`;

const NameSpan = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #FFFFFF;
  box-sizing: border-box;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const DeleteSpan = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: right;
  cursor: pointer;
  color: #EB5E4F;

  :hover {
    opacity: 0.8;
  }
`;

const LangSection = styled.div`
  margin-bottom: 35px;
`;

const LangSelectPlaceholder = styled.div`
  margin-bottom: 20px;
  color: black;
`;

const LangLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #7B7F9E;
  margin-bottom: 14px;
`;

const ButtonPlaceholder = styled.div`

`;
