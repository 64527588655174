import uuid from 'uuid'

const SubtitlesHelper = {

    getTitledItemsByAwsItems(items, options = {}) {
        let {maxWords = 15, maxSilence = 1.5, maxLength = 50} = options;

        let arr = [], k = 0, caption = undefined;
        for (let i in items) {
            let it = items[i];
            let {start_time, end_time, alternatives, type} = it;
            let val = alternatives[0];
            let {confidence, content} = val;
            let a = {start: start_time, end: end_time, text: content, type: type, confidence: confidence};
            if (caption == undefined) {
                caption = {
                    id: uuid(),
                    start: a.start,
                    end: a.end,
                    words: [a]
                }
            } else {
                let length = caption.words.reduce((sm, b) => (+sm + +b.text.replace(/[\.\?\!]/g, '').length), 0);
                let wordCount = caption.words.length;
                let silence = +a.start - +caption.end;
                if ((+silence > +maxSilence) || (+wordCount > +maxWords) || (+length > +maxLength) || (['.', '!', '?'].indexOf(caption.latestText) > -1)) {
                    arr.push(JSON.parse(JSON.stringify(caption)));
                    caption = {
                        id: uuid(),
                        start: a.start,
                        end: a.end,
                        words: [a],
                        isEndsWithPunctuation: (a.type == 'punctuation'),
                        latestText: a.text
                    }
                } else {
                    caption.latestText = a.text;
                    caption.words = caption.words.concat([a]);
                    caption.isEndsWithPunctuation = (a.type == 'punctuation');
                    caption.end = a.end;
                }
            }
        }

        if (arr.length > 0) {
            if (arr[arr.length - 1].id != caption.id) {
                arr.push(JSON.parse(JSON.stringify(caption)));
            }
        } else {
            arr = [caption];
        }
        arr = arr.filter(a => !(a.words.length > 0 && (['.', '!', '?'].indexOf(a.words.map(w => w.text).join('')) > -1)));

        return arr;

    },

    toHHMMSS(s) {
        let sec_num = parseInt(s, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return minutes + ':' + seconds;
    },

    getCurrentSubtitle(time, subtitles) {
        let res = undefined;
        if (time == undefined || subtitles.length == 0) {
            return undefined;
        }
        // console.log('getCurrentSubtitle: time = ', time);
        let index = undefined;
        for (let i in subtitles) {
            let sub = subtitles[i];
            let from = +sub.from;
            let to = +sub.to;
            if (+from <= +time && +time < +to) {
                index = i;
            }
        }
        res = (index == undefined) ? undefined : subtitles[index];
        return res;
    },

    getCurrentSubtitles(time, subtitles) {
        let res = undefined;
        if (time == undefined || subtitles.length == 0) {
            return undefined;
        }
        let arr = [];
        let index = undefined;
        for (let i in subtitles) {
            let sub = subtitles[i];
            let from = +sub.from;
            let to = +sub.to;
            if (+from <= +time && +time < +to) {
                arr.push(sub);
            }
        }
        return arr;
    },

    getPrevSubtitle(time, subtitles) {
        if (subtitles.length == 0) {
            return undefined;
        }
        let last = subtitles[subtitles.length - 1];
        if (+time > +last.to) {
            return last;
        }
        let current = this.getCurrentSubtitle(time, subtitles);
        if (current == undefined) {
            return undefined;
        }
        let res = undefined;
        for (let i = 0; i < subtitles.length - 1; i++) {
            let nx = subtitles[+i + 1];
            if (nx.id == current.id) {
                res = subtitles[i];
            }
        }
        return res;
    },

    getNextSubtitle(time, subtitles) {
        if (subtitles.length == 0) {
            return undefined;
        }
        let first = subtitles[0];
        if (+time < +first.from) {
            return first;
        }
        let current = this.getCurrentSubtitle(time, subtitles);
        if (current == undefined) {
            return undefined;
        }
        let res = undefined;
        for (let i = 1; i < subtitles.length; i++) {
            let pr = subtitles[+i - 1];
            if (pr.id == current.id) {
                res = subtitles[i];
            }
        }
        return res;
    },

    round2(a) {
        return Math.round((+a) * 100.0) / 100.0;
    },

    getSrtString(subtitles) {
        let srtOut = '';
        subtitles.forEach((v, i) => {
            srtOut += `${ i + 1 }\n${ formatSeconds(parseFloat(v.from)).replace('.', ',') } --> ${ formatSeconds(parseFloat(v.to)).replace('.', ',') }\n${ v.text.trim() }\n\n`;
        });
        return srtOut;
    },

    getFakeProgressPercents(timeElapsed, duration) {
        let approxMaxTime = duration * 1.1;
        let ground = 0;
        let isLess = (+timeElapsed <= approxMaxTime);
        let frac = 1.0 * +timeElapsed / +approxMaxTime;
        if (isLess) {
            return 70 * frac;
        }

        ground = 70;
        let dt1 = 0.9 * duration; // 1.1 - 2
        approxMaxTime = duration * 2;
        isLess = (+timeElapsed <= approxMaxTime);
        let dd = approxMaxTime - timeElapsed;
        let addP = 20.0 * (1 - (approxMaxTime - timeElapsed) / dt1);
        if (isLess) {
            return (70 + addP);
        }
        dt1 = 8 * duration;
        ground = 90;
        approxMaxTime = duration * 10;
        isLess = (+timeElapsed <= approxMaxTime);
        if (isLess) {
            return (+ground + 10 * (1 - (approxMaxTime - timeElapsed) / dt1))
        }
        return 100.0;
    }


};

export default SubtitlesHelper;

const formatSeconds = seconds => new Date(seconds.toFixed(3) * 1000).toISOString().substr(11, 12);
