import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import moment from 'moment'

import ls from 'local-storage'
import PlansAPI from "../../api/PlansAPI";
import CommonHelper from "../../helpers/CommonHelper";

import {Analytics} from "../../utils/AnalyticsUtility";
import AnalyticsAPI from "../../api/AnalyticsAPI";
import {Code} from "react-content-loader";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import PaddleCheckoutTool from "../paddle/tools/PaddleCheckoutTool";

export default function BuyApp(props) {
    let {paddle_id} = props.match.params;

    return (
        <SubtitlesTemplate>
            <PaddleCheckoutTool paddle_id={paddle_id} />
        </SubtitlesTemplate>
    );
}

const TopLogo = styled.img`
  height: 100px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1.25px;
  font-size: 64px;
  line-height: 64px;
  color: #FFFFFF;
  margin-top: 60px;
  font-weight: bold;
`;

const SubHeading = styled.div`
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #EFEFEF;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: #171822;
  position: relative;
`;

const TopLeftFunImg = styled.img`
  left: 0px;
  bottom: 0px;
  position: fixed;
`;

const TopRightFunImg = styled.img`
  right: 10px;
  width: 110px;
  top: 0px;
  position: fixed;
`;

const InnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoxPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const Button = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  background: #EB5E4F;
  border-radius: 5px;
  height: 56px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    opacity: 0.8;
  }
`;
