const AnalyticsAPI = {

    reachGoal(goalName) {
        try {
            window.ym(57194221, 'reachGoal', goalName);
        } catch (exc) {
            console.log('can not reach goal: exc = ', exc);
        }
    },

    pixelTrackLead() {
        try {
            window.fbq('track', 'Lead');
        } catch (exc) {
            console.log('can not track lead: exc = ', exc);
        }
    },

    pixelTrackPurchase(value) {
        try {
            window.fbq('track', 'Purchase', {currency: "USD", value: value}); // todo
        } catch (exc) {
            console.log('can not track lead: exc = ', exc);
        }
    },


    mixpanelIdentifyUser(user) {
        console.log('ident user: ', user);
        if (user == undefined) {
            return;
        }
        try {
            window.mixpanel.people.set({
                "$email": user.email,
                "Sign up date": (new Date(user.createdTimestamp)).toISOString(),
                "USER_ID": user.id
            });
            window.mixpanel.identify(user.id);
        } catch (eer) {

        }
    },


}

export default AnalyticsAPI;
