import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    waveTemplatesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const WaveTemplatesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_WAVE_TEMPLATE:
        case types.UPDATE_WAVE_TEMPLATE:
        case types.DELETE_WAVE_TEMPLATE:
        case types.LOAD_WAVE_TEMPLATES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_WAVE_TEMPLATE_FAIL:
        case types.UPDATE_WAVE_TEMPLATE_FAIL:
        case types.DELETE_WAVE_TEMPLATE_FAIL:
        case types.LOAD_WAVE_TEMPLATES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_WAVE_TEMPLATE_SUCCESS:
        case types.UPDATE_WAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                waveTemplatesMap: state.waveTemplatesMap.set(action.waveTemplate.id, action.waveTemplate)
            }


        case types.DELETE_WAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                waveTemplatesMap: state.waveTemplatesMap.delete(action.id),
            }


        case types.LOAD_WAVE_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                waveTemplatesMap: state.waveTemplatesMap
                    .merge(action.waveTemplates.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default WaveTemplatesReducer;
