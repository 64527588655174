import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";

import {Code} from 'react-content-loader'

import {Input, Button} from '../auth/ui'
import CommonHelper from "../../helpers/CommonHelper";


export default function RecoverPasswordApp(props) {
    let {key} = props.match.params;
    const [loading, setLoading] = useState(true);
    const [recovering, setRecovering] = useState(false);
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(undefined);
    const [error, setError] = useState(undefined);
    useEffect(() => {
        axios.get(`${API_ENDPOINT}/recover/${key}`).then(d => d.data).then(pld => {
            if (pld.error != undefined) {
                setError(pld.error.message);
                setLoading(false);
            } else {
                setUser(pld);
                setLoading(false);
            }
        })
    }, []);

    if (loading == true) {
        return (
            <Code/>
        )
    }

    if (error != undefined) {
        return (
            <ErrorPlaceholder>
                {error}
            </ErrorPlaceholder>
        )
    }

    if (user == undefined) {
        return (
            <ErrorPlaceholder>
                User is not found
            </ErrorPlaceholder>
        )
    }

    return (
        <Wrapper>

            <Field>
                <Input placeholder={'New password'} value={password} onChange={evt => {
                    setPassword(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Button onClick={() => {
                    if (password.trim().length < 4) {
                        window.alert('The password is too short');
                        return;
                    }
                    setRecovering(true);
                    axios.post(`${API_ENDPOINT}/recover/${key}`, {
                        password: password
                    }).then(d => d.data).then(pld => {
                        if (pld.error != undefined) {
                            window.alert(pld.error.message);
                            setRecovering(false);
                            return;
                        }
                        window.alert('New password has been updated successfully!');
                        setRecovering(false);
                        setRecovering(false);
                        CommonHelper.linkTo('/');
                    }).catch(err => {
                        setError(JSON.stringify(err));
                        setRecovering(false);
                    })
                }}>
                    Change password
                </Button>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 520px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background: white;
    border-radius: 15px;
    @media(max-width: 520px){
      width: 100%;
    }
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const ErrorPlaceholder = styled.div`
    color: red;
    padding: 10px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
`;
