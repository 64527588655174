import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import PickPlanPanel from "../billing/panels/PickPlanPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserBillingPanel from "../billing/panels/UserBillingPanel";
import FacebookPlugin from "../ui/FacebookPlugin";

export default function UserBillingApp() {

    return (
        <SubtitlesTemplate active={'billing'}>

            <UserBillingPanel/>
            {/* <FacebookPlugin/> */}

        </SubtitlesTemplate>
    );
}
