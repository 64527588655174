import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";
import AnalyticsAPI from './AnalyticsAPI'

function gup(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    let regexS = "[\\?&]" + name + "=([^&#]*)";
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
}

const JobsAPI = {

    async getProjectJobs(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}/jobs`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getUserJobs(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/jobs`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async createProjectProcessingJob(projectId, hasWatermark = false) {
        let workerId = gup('workerId');
        let pld = (await axios.post(`${API_ENDPOINT}/project/${projectId}/create_job${hasWatermark == true ? '?watermark=1' : ''}`, {
            workerId: workerId
        })).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        AnalyticsAPI.reachGoal(`render`);
        return pld.result;
    },

}

export default JobsAPI;

