export const Color = {
  MAIN_DARK: '#171822',
  LIGHT_DARK: '#212330',
  LIGHT_GRAY: '#D8D8D8',
  BLOCK_LABEL_COLOR: '#7B7F9E',
  ORANGE: '#FFAC30',
  ASPHALT: '#3F435A',
  PINK: '#FF356B',
  LIGHT_RED: '#eb5e4f',
  AQUA: '#01939B',
  BAD_STRAWBERRY: '#744468',
  HOVER_GRAY_COLOR: '#3F435A'
};

export const Size = {
  buttonHeight: '35px'
}
