import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import moment from 'moment'

import ls from 'local-storage'
import PlansAPI from "../../api/PlansAPI";
import CommonHelper from "../../helpers/CommonHelper";

import {Analytics} from "../../utils/AnalyticsUtility";
import AnalyticsAPI from "../../api/AnalyticsAPI";

export default function PaymentSuccessApp(props) {
    let {paddle_id} = props.match.params;
    const [sub, setSub] = useState(undefined);

    useEffect(() => {
        PlansAPI.getAllPlans().then(arr => {
            let a = arr.filter(x => (x.paddle_id == paddle_id))[0];
            console.log('---> a = ', a);
            if (a == undefined) {
                return;
            }
            setSub(a);
            let key = `sub_success_${paddle_id}_${moment().format('HHDDMM')}_tracked`;
            console.log('sub = ', sub);
            try {
                if (ls(key) != 'yes') {

                    try {
                        window.mixpanel.track('PaymentSuccessPage', {
                            paddle_id: paddle_id
                        });
                    } catch (eez) {
                    }


                    //createdTimestamp: 1583403660516
                    // description: "Automatic Captions↵60+ Supported Languages↵Custom Top Border Headlines↵Caption Text/Timing Editor↵Caption Positioning↵Custom Caption Styles↵200+ Preloaded Fonts↵Video Resizing↵Download .SRT File"
                    // hidden: false
                    // id: "EEySyPbAeu"
                    // jobsPerProject: 5
                    // label: "Standard"
                    // name: "standard"
                    // paddle_id: "586001"
                    // plan_type: 0
                    // price: 13
                    // timestamp: 1583403660516
                    // updatedTimestamp: 1606222366317
                    // videos_number: 15

                    Analytics.sendCheckoutSuccess(a.price, window.currentUserId, a?.paddle_id, a?.name);
                    AnalyticsAPI.pixelTrackPurchase(a.price);

                    // Analytics.send('billing.plan.purchase.success', 'billing', () => {}, {
                    //     currency: "USD",
                    //     value: +a.price,
                    //     price: +a.price
                    // });


                    ls(key, 'yes');
                    window.location.reload();
                }
            } catch (eee) {
            }
        });
    }, []);

    let subName = (sub == undefined) ? '' : sub.label;

    return (
        <Wrapper>

            <TopLeftFunImg src={require('../../assets/images/left_bo.svg')}/>

            <TopRightFunImg src={require('../../assets/images/rig_to.svg')}/>

            <InnerPlaceholder>
                <BoxPlaceholder>

                    <TopLogo src={require('../../assets/images/sub_logo_.svg')}/>

                    <Heading>
                        Thank you!
                    </Heading>

                    <SubHeading>
                        {'You subscribed to '} <b style={{marginLeft: 4, marginRight: 4}}>{subName}</b> {'plan'}
                    </SubHeading>

                    <ButtonPlaceholder>
                        <Button onClick={() => {
                            CommonHelper.linkTo(`/`);
                        }}>
                            Start subtitling
                        </Button>
                    </ButtonPlaceholder>

                </BoxPlaceholder>
            </InnerPlaceholder>

        </Wrapper>
    );
}

const TopLogo = styled.img`
  height: 100px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1.25px;
  font-size: 64px;
  line-height: 64px;
  color: #FFFFFF;
  margin-top: 60px;
  font-weight: bold;
`;

const SubHeading = styled.div`
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #EFEFEF;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: #171822;
  position: relative;
`;

const TopLeftFunImg = styled.img`
  left: 0px;
  bottom: 0px;
  position: fixed;
`;

const TopRightFunImg = styled.img`
  right: 10px;
  width: 110px;
  top: 0px;
  position: fixed;
`;

const InnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoxPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const Button = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  background: #EB5E4F;
  border-radius: 5px;
  height: 56px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    opacity: 0.8;
  }
`;
