import React from 'react';
import styled from 'styled-components';

import './style.css'
import {Analytics} from "../../../utils/AnalyticsUtility";
import CommonHelper from "../../../helpers/CommonHelper";

import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import TelegramAPI from "../../../api/TelegramAPI";

export default function PricingTool(props) {
    const {
        currentPlanId,
        email,
        currentUser = undefined,
        currentUserId,
        onPlanSelect = aId => {

        },
        onSelectFreeClick = () => {

        }
    } = props;


    return (
        <Wrapper>

            <Cont>

                <BoxesPlaceholder>
                    {ITEMS.map((it, j) => {
                        let selected = (it.id == currentPlanId || (currentPlanId == undefined && it.isFree == true));
                        let isFree = it.isFree;

                        return (
                            <Box key={j} {...it} selected={selected}>
                                <Name color={it.nameColor}>
                                    {it.name}
                                </Name>
                                <Motto color={it.mottoColor}>
                                    {it.motto}
                                </Motto>
                                <SubMotto color={it.subMottoColor}>
                                    {it.subMotto}
                                </SubMotto>
                                <CenterText color={it.centerColor}>
                                    {it.centerText}
                                </CenterText>
                                <BottomText color={it.textColor}>
                                    <div dangerouslySetInnerHTML={{__html: it.text}}></div>
                                </BottomText>
                                <ButtonPlaceholder>
                                    {selected == true ? <MyBox>CURRENT</MyBox> :
                                        <Button {...it}
                                                onClick={async () => {
                                                    if (it.id == undefined) {
                                                        return;
                                                    }
                                                    if (isFree == true) {
                                                        onSelectFreeClick();
                                                        return;
                                                    }
                                                    try {
                                                        await axios.post(`${API_ENDPOINT}/user/cancel_all_subscriptions`);
                                                    } catch (exc_) {

                                                    }

                                                    try {
                                                        Analytics.send('billing.plan.purchase.confirmed', 'billing');
                                                        window.Paddle.Checkout.open({
                                                            product: it.paddle_id,
                                                            email: email,
                                                            passthrough: currentUserId,
                                                            success: `https://my.subtitles.love/#/payment_success/${it.paddle_id}`
                                                        });
                                                    } catch (exc) {

                                                    }

                                                    if (currentUser != undefined) {
                                                        await TelegramAPI.sendMessageToAdmins(`[PRICING PAGE - SUBSCRIPTION BUTTON CLICK]: User ${currentUser.email} clicked on "${it.name}" subscription.`);
                                                    }

                                                }}>
                                            {it.buttonText}
                                        </Button>
                                    }
                                </ButtonPlaceholder>
                            </Box>
                        )
                    })}
                </BoxesPlaceholder>

            </Cont>

        </Wrapper>
    );
}

const Wrapper = styled.div`
      color: white;
      margin-top: 70px;
`;

const Cont = styled.div`
    padding-top: 48px;
    width: 920px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const BoxesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const Box = styled.div`
    position: relative;
    padding: 25px;
    box-sizing: border-box;
    margin: 15px;
    width: calc(33.33% - 30px);
    border: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 16px;
    background: ${props => props.background};
    @media(max-width: 820px){
      width: calc(50% - 30px);
    }
    @media(max-width: 520px){
      width: calc(100% - 30px);
    }
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.25px;
    color: ${props => props.color};
`;

const Motto = styled.div`
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.25px;
    color: ${props => props.color};
    margin-top: 10px;
    font-style: italic;
    font-weight: bold;
`;

const SubMotto = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 2.5px;
    color: ${props => props.color};
    margin-top: 6px;
`;

const CenterText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.25px;
    color: ${props => props.color};
    margin-top: 40px;
`;

const BottomText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
    color: ${props => props.color};
    margin-top: 40px;
`;

const ButtonPlaceholder = styled.div`
    margin-top: 24px;
`;

const Button = styled.div`
    background: ${props => props.buttonColor};
    color: ${props => props.buttonTextColor};
    
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    height: 56px;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const MyBox = styled.div`
    background: transparent;
    color: ${props => props.buttonTextColor};
    border: 2px solid white;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    height: 56px;
    font-weight: bold;
`;


const ITEMS = [
    {
        id: '8oCk3S8ZJZ',
        paddle_id: '586033',

        isFree: true,

        background: '#212330',

        name: 'Free',
        nameColor: '#FFFFFF',
        motto: 'Free',
        mottoColor: '#5C5C5C',
        subMotto: 'FOREVER',
        subMottoColor: '#5C5C5C',
        centerText: '1 video / 1 render',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Simple Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Watermark
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'
    },

    {

        id: 'EEySyPbAeu',
        paddle_id: '586001',

        background: '#212330',

        name: 'Medium',
        nameColor: '#FFFFFF',
        motto: '$13',
        mottoColor: '#5C5C5C',
        subMotto: 'PER MONTH',
        subMottoColor: '#5C5C5C',
        centerText: '10 videos / 50 renders',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'
    },

    {
        id: '2v7DU1gu8l',
        paddle_id: '586002',

        background: '#FFAC30',

        name: 'Premium',
        nameColor: '#212330',
        motto: '$20',
        mottoColor: '#FFFFFF',
        subMotto: 'PER MONTH',
        subMottoColor: '#FFFFFF',

        centerText: '30 videos / 300 renders',
        centerColor: '#212330',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Faster rendering
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFFFF',

        buttonColor: '#FFFFFF',
        buttonTextColor: '#212330',
        buttonText: 'SUBSCRIBE'

    },

]

