import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    jobsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const JobsReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_JOB:
        case types.LOAD_JOBS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_JOB_FAIL:
        case types.LOAD_JOBS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                jobsMap: state.jobsMap.set(action.job.id, action.job)
            }


        case types.LOAD_JOBS_SUCCESS:
            return {
                ...state,
                loading: false,
                jobsMap: state.jobsMap
                    .merge(action.jobs.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default JobsReducer;
