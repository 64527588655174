import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import PickPlanPanel from "../billing/panels/PickPlanPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserSubscriptionsPanel from "../subscriptions/panels/UserSubscriptionsPanel";
import UserBillingPanel from "../billing/panels/UserBillingPanel";
import UserPricingPanel from "../billing/panels/UserPricingPanel";
import FacebookPlugin from "../ui/FacebookPlugin";
import {SliderBar} from "../ui/slider-bar";
import {CreditPurchasePannel} from "../billing/panels/CreditPurchasePannel";
import {useMappedState} from "redux-react-hook";

export default function UserPricingApp() {
    const {
        currentUser,
        expPlan
    } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            expPlan: state.plans.currentPlanExpirationTimestamp || null
        }
    }, []));


    return (
        <SubtitlesTemplate active={'pricing'}>
            {currentUser?.isSubscribedAtLeastOnce != null && currentUser?.isSubscribedAtLeastOnce && moment().isSameOrBefore(expPlan)
                ? <CreditPurchasePannel/>
                : null
            }

            <UserPricingPanel/>
        </SubtitlesTemplate>
    );
}


