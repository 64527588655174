import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TelegramAPI = {

    async sendMessageToAdmins(message) {
        await axios.post(`${API_ENDPOINT}/telegram/send_to_admins`, {
            message: message
        });
    },


}

export default TelegramAPI;
