import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import './style.css'

import trImg from './triangle.svg'
import TelegramAPI from "../../api/TelegramAPI";

export default function PricingFAQTool(props) {
    const {
        currentUser
    } = props;

    const [expandedSet, setExpandedSet] = useState(Set());
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(ITEMS);
    }, []);

    return (
        <Wrapper className={'pricing_faq_placeholder'}>

            {items.map((a, i) => {
                let isExpanded = expandedSet.has(i);
                return (
                    <QuestionItem key={i} onClick={() => {
                        if (isExpanded == true) {
                            setExpandedSet(expandedSet.delete(i));
                        } else {
                            setExpandedSet(expandedSet.add(i));
                            try {
                                if (currentUser != undefined && (window.location.href.indexOf('localhost') == -1)) {
                                    TelegramAPI.sendMessageToAdmins(`[FAQ ITEM CLICK]: User ${currentUser.email} | ${a.question}`);
                                }
                            } catch (exc) {

                            }
                        }
                    }}>
                        <TopPlaceholder>
                            <NamePlaceholder>
                                {a.question}
                            </NamePlaceholder>
                            <Image src={trImg} expanded={isExpanded}/>
                        </TopPlaceholder>
                        {isExpanded == false ? null :
                            <BottomPlaceholder>
                                <div dangerouslySetInnerHTML={{__html: a.answer}}></div>
                            </BottomPlaceholder>
                        }
                    </QuestionItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const QuestionItem = styled.div`
  margin-bottom: 20px;
  background: #212330;
  border: 2px solid rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  height: 12px;
  transform: rotate(${props => (props.expanded == true ? 180 : 0)}deg);
`;

const BottomPlaceholder = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #EFEFEF;
`;

const NamePlaceholder = styled.div`
  font-size: 21px;
  line-height: 25px;
  color: #FFFFFF;
  font-weight: bold;
  flex: 1;
  box-sizing: border-box;
  margin-right: 40px;
`;


const ITEMS = [
    {
        question: 'Why should I pay you?',
        answer: `
            <p>
            Our software is top of the art translations application, we use research and algorithms developed by Google to provide a great user experience. We wish to continue to support creators like yourself and subscribing to the subtitles love community makes us grow stronger together.
            </p>
        `
    },
    {
        question: 'What does the watermark look like?',
        answer: `
            <p>
                The watermark is shown in the corner of videos that are created on the free plan. As a self-funded startup, this watermark is important in helping us grow. But as you’ll see below, it doesn’t distract from your content. To remove the watermark, you can choose one of our PRO plans.
            </p>
            <img 
            class="faq_img"
            src="https://www.englishpatient.org/english-patient-files/ef05469c-09ea-4cb4-be64-f2c75896ef0f.jpg" />
        `
    },
    {
        question: 'Do you offer a trial for the paid plans?',
        answer: `
            <p>
                We don’t currently have a trial, but our free plan allows you to test some of our tools. Go ahead and try it, then if you want to remove the watermark and gain access to HD quality, you can switch to a paid plan.
            </p>
        `
    },
    {
        question: 'Can I really cancel anytime?',
        answer: `
            <p>
                Yes, absolutely. If you want to cancel your plan, simply go to Billing page and cancel it.
            </p>
        `
    },
    {
        question: 'What is your refund policy?',
        answer: `
            <p>
                You may request a refund for your current month if you request it within 24 hours of the transaction. We reserve the right to decline that request should you use our software within this time.
            </p>
        `
    },
    {
        question: 'Do you offer enterprise?',
        answer: `
            <p>
                Yes we do, please email hello@subtitles.love and provide us with your requirements. Subtitles.Love can offer white-label solution for your business.
            </p>
        `
    },


]