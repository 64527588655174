import WaveTemplatesAPI from '../../api/WaveTemplatesAPI'

import * as types from '../ActionTypes'


const loadWaveTemplates_ = () => {
    return {
        type: types.LOAD_WAVE_TEMPLATES
    }
}
export const loadWaveTemplatesSuccess = (waveTemplates) => {
    return {
        type: types.LOAD_WAVE_TEMPLATES_SUCCESS,
        waveTemplates: waveTemplates
    }
}
let loadWaveTemplatesFailed = (error) => {
    return {
        type: types.LOAD_WAVE_TEMPLATES_FAIL,
        error: error
    }
}

//thunk
export function loadAllWaveTemplates() {
    return (dispatch, getState) => {
        dispatch(loadWaveTemplates_());
        return WaveTemplatesAPI.getAllWaveTemplates().then(
            waveTemplates => dispatch(loadWaveTemplatesSuccess(waveTemplates)),
            error => dispatch(loadWaveTemplatesFailed(error))
        )
    }
}

export function loadUserAllWaveTemplates(userId) {
    return (dispatch, getState) => {
        dispatch(loadWaveTemplates_());
        return WaveTemplatesAPI.getUserWaveTemplates(userId).then(
            waveTemplates => dispatch(loadWaveTemplatesSuccess(waveTemplates)),
            error => dispatch(loadWaveTemplatesFailed(error))
        )
    }
}


export function getWaveTemplate(id) {
    return (dispatch, getState) => {
        dispatch(loadWaveTemplates_());
        return WaveTemplatesAPI.getWaveTemplate(id).then(
            proj => dispatch(loadWaveTemplatesSuccess([proj])),
            error => dispatch(loadWaveTemplatesFailed(error))
        )
    }
}

let createWaveTemplate_ = () => {
    return {
        type: types.CREATE_WAVE_TEMPLATE
    }
}
let createWaveTemplateSuccess = (waveTemplate) => {
    return {
        type: types.CREATE_WAVE_TEMPLATE_SUCCESS,
        waveTemplate: waveTemplate
    }
}
let createWaveTemplateFailed = (error) => {
    return {
        type: types.CREATE_WAVE_TEMPLATE_FAIL,
        error: error
    }
}

//thunk
export function createWaveTemplate(data) {
    return (dispatch, getState) => {
        dispatch(createWaveTemplate_());
        return WaveTemplatesAPI.createWaveTemplate(data).then(
            waveTemplate => dispatch(createWaveTemplateSuccess(waveTemplate)),
            error => dispatch(createWaveTemplateFailed(error))
        )
    }
}

let updateWaveTemplate_ = () => {
    return {
        type: types.UPDATE_WAVE_TEMPLATE
    }
}
let updateWaveTemplateSuccess = (waveTemplate) => {
    return {
        type: types.UPDATE_WAVE_TEMPLATE_SUCCESS,
        waveTemplate: waveTemplate
    }
}
let updateWaveTemplateFailed = (error) => {
    return {
        type: types.UPDATE_WAVE_TEMPLATE_FAIL,
        error: error
    }
}

//thunk
export function updateWaveTemplate(data) {
    return (dispatch, getState) => {
        dispatch(updateWaveTemplate_());
        return WaveTemplatesAPI.updateWaveTemplate(data).then(
            waveTemplate => dispatch(updateWaveTemplateSuccess(waveTemplate)),
            error => dispatch(updateWaveTemplateFailed(error))
        )
    }
}

let deleteWaveTemplate_ = () => {
    return {
        type: types.DELETE_WAVE_TEMPLATE
    }
}
let deleteWaveTemplateSuccess = (id) => {
    return {
        type: types.DELETE_WAVE_TEMPLATE_SUCCESS,
        id: id
    }
}
let deleteWaveTemplateFailed = (error) => {
    return {
        type: types.DELETE_WAVE_TEMPLATE_FAIL,
        error: error
    }
}

//thunk
export function deleteWaveTemplate(id) {
    return (dispatch, getState) => {
        dispatch(deleteWaveTemplate_());
        return WaveTemplatesAPI.deleteWaveTemplate(id).then(
            () => dispatch(deleteWaveTemplateSuccess(id)),
            error => dispatch(deleteWaveTemplateFailed(error))
        )
    }
}
