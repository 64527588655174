import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    forwardRef,
    useImperativeHandle
} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import WebFont from "webfontloader";

const FormattedSubtitle = forwardRef((props, ref) => {
    const {

        fontSize = 12,
        lineHeight = 12,
        textAlign = 'center',
        color = 'black',
        bottom = 0,
        font = 'Roboto',

        backgroundType = 'full',
        overlayBackground = 'rgba(0, 0, 0, 1)',

        text = ''

    } = props;

    const spanRef = useRef();
    const wrapperRef = useRef();

    useEffect(() => {
        try {
            WebFont.load({
                google: {
                    families: [font]
                }
            });
        } catch (e) {

        }
    }, [font]);

    useImperativeHandle(ref, () => ({

        setText(txt) {
            try {
                // spanRef.current.innerText = txt;
                spanRef.current.innerHTML = txt;
                if (txt == undefined || txt.trim() == '') {
                    wrapperRef.current.style.display = 'none';
                } else {
                    wrapperRef.current.style.display = 'block';
                }
            } catch (e) {

            }
        }

    }));

    console.log('render: backgroundType = ', backgroundType);
    let boxBackground = (backgroundType == 'full') ? overlayBackground : 'none';
    let spanBackground = (backgroundType == 'wrap') ? overlayBackground : 'none';
    let textShadow = (backgroundType == 'outline') ? `1px 1px 3px ${overlayBackground}` : 'none';

    return (
        <Wrapper ref={wrapperRef}>
            <Box background={boxBackground} appearance={props}>
                <Span background={spanBackground} ref={spanRef} textShadow={textShadow} ></Span>
            </Box>
        </Wrapper>
    );
});

export default FormattedSubtitle;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Span = styled.div`
    background: ${props => props.background};
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    text-shadow: ${props => props.textShadow};
`;

const Box = styled.div`
    font-size: ${props => props.appearance.fontSize}px;
    line-height: ${props => props.appearance.lineHeight}px;
    
    text-align: ${props => props.appearance.textAlign};
    color: ${props => props.appearance.color};
    bottom: ${props => props.appearance.bottom}px;
    
    font-weight: ${props => props.appearance.fontWeight};
    font-style: ${props => props.appearance.fontStyle};
    
    background: ${props => props.background};
    box-sizing: border-box;
    
    font-family: ${props => props.appearance.font};
    
    padding-left: 5px;
    padding-right: 5px;
    
    left: 0px;
    right: 0px;
    
    position: absolute;
`;
