import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import ParamView from "./ParamView";
import UpdateParamForm from "../forms/UpdateParamForm";

export default function ParamsEditor(props) {
    const {
        onSave = newParams => {

        }
    } = props;

    console.log('ParamsEditor: render: props = ', props);

    const [params, setParams] = useState(props.params == undefined ? [] : props.params);
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);

    const selectedParam = (selectedId == undefined) ? undefined : params.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Add new parameter
                </Button>
            </TopPlaceholder>

            <ParamsListPlaceholder>
                {params.map((p, j) => {
                    return (
                        <ParamItem selected={(p.id == selectedId)}
                                   key={p.id} onClick={() => {
                            setSelectedId(p.id);
                        }}>
                            <ParamView {...p} />
                        </ParamItem>
                    )
                })}
            </ParamsListPlaceholder>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>
                {addModalVisible == false ? null :
                    <UpdateParamForm onSave={d => {
                        let a = {id: uuid(), ...d};
                        let newParams = params.concat([a]);
                        setParams(newParams);
                        onSave(newParams);
                        setAddModalVisible(false);
                    }}/>
                }
            </Sidebar>

            <Sidebar visible={(selectedParam != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                {selectedParam == undefined ? null :
                    <UpdateParamForm {...selectedParam}
                                     canDelete={true}
                                     onSave={d => {
                                         let newParams = params.map(a => (a.id == selectedId ? {...selectedParam, ...d} : a));
                                         setParams(newParams);
                                         onSave(newParams);
                                         setSelectedId(undefined);
                                     }}
                                     onDelete={() => {
                                         let newParams = params.filter(a => (a.id != selectedParam.id));
                                         setParams(newParams);
                                         onSave(newParams);
                                         setSelectedId(undefined);
                                     }}
                    />
                }
            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const ParamsListPlaceholder = styled.div`
    padding: 5px;
`;

const ParamItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 10px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;


const BottomPlaceholder = styled.div`
    
`;
