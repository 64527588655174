import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import {SketchPicker} from 'react-color'
import styled from 'styled-components';
import {Color, Size} from "../../../config/CommonStyles";
import OutsideClickHandler from 'react-outside-click-handler';

export default function ColorPicker(props) {
    const {
        leftOffset = -100
    } = props;

    const {hex = false, defaultColor = '#fff', onColorChange = () => {}} = props;
    const [color, setColor] = useState(defaultColor);
    const [active, setActive] = useState(false);

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                if (active) setActive(!active)
            }}>

            <Wrapper>
                <ColorButton onClick={() => setActive(!active)}>
                    <ColorContainer color={color}/>
                </ColorButton>
                <ColorWrapper active={active} leftOffset={leftOffset} >
                    <SketchPicker color={color} onChange={(color) => {
                        if (hex == true){
                            onColorChange(color.hex);
                            setColor(color.hex);
                        }else {
                            onColorChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
                            setColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
                        }
                    }}/>
                </ColorWrapper>
            </Wrapper>
        </OutsideClickHandler>
    );
}

const ColorWrapper = styled.div`
  position: absolute;
  top: 55px;
  left: ${props => (props.leftOffset == undefined ? 0 : props.leftOffset)}px;
  z-index: 3000;
  display: ${props => props.active ? 'block' : 'none'}
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 5px;
`;

const ColorButton = styled.div`
  background: ${Color.LIGHT_DARK};
  width: 35px;
  height: ${Size.buttonHeight};
  border: 2px solid rgba(255, 255, 255, 0.03);
 box-sizing: border-box;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  
   &:hover{
    background-color: ${Color.ORANGE};
  }
`;

const ColorContainer = styled.div`
  background-color:  ${props => props.color};
  
  height: 20px;
  width: 20px;
`;
