export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export const FACELESS_AVATAR = 'https://www.englishpatient.org/storage/e7d099fe-653b-488e-9970-f693ab585f05.png';

export const PUBLIC_URL = process.env.PUBLIC_URL || 'https://my.subtitles.love';

export const API_ENDPOINT = process.env.REACT_APP_API_URL || 'https://api.subtitles.love';
export const NCHAN_SUB_ENDPOINT = 'https://api.subtitles.love/sub';

export const LOGIN_BACKGROUNR_IMAGE = 'https://www.englishpatient.org/english-patient-files/36208fa0-49a1-406a-8ac7-ecfaeb810a55.jpg';

// export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';

export const SUPPORTED_LANGUAGES = ["English", "Russian", "French", "German", "Dutch", "Italian", "Spanish", "Portuguese", "Latvian", "Lithuanian", "Estonian", "Finnish", "Swedish", "Ukrainian", "Korean", "Farsi", "Turkish", "Japanese", "Hebrew", "Afrikaans", "Albanian", "Amharic", "Arabic", "Armenian", "Basque", "Belarusian", "Bengali", "Bosnian", "Bulgarian", "Catalan", "Cebuano", "Chinese", "Corsican", "Croatian", "Czech", "Danish", "Esperanto", "Frisian", "Galician", "Georgian", "Greek", "Haitian Creole", "Hausa", "Hawaiian", "Hindi", "Hungarian", "Icelandic", "Igbo", "Indonesian", "Irish", "Javanese", "Kannada", "Kazakh", "Khmer", "Kinyarwanda", "Kurdish", "Kyrgyz", "Lao", "Luxembourgish", "Malagasy", "Malay", "Malayalam", "Maltese", "Maori", "Marathi", "Mongolian", "Myanmar", "Nepali", "Norwegian", "Nyanja", "Odia", "Pashto", "Persian", "Polish", "Punjabi", "Romanian", "Samoan", "Scots Gaelic", "Serbian", "Sesotho", "Shona", "Sindhi", "Sinhala", "Slovak", "Slovenian", "Somali", "Sundanese", "Tajik", "Tamil", "Tatar", "Telugu", "Thai", "Turkmen", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Welsh", "Xhosa", "Yiddish", "Yoruba"];
