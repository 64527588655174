import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import iconArrow from '../images/down_arrow.svg'
import styled, {css} from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';

const Color = {
    MAIN_DARK: '#171822',
    LIGHT_DARK: '#212330',
    LIGHT_GRAY: '#D8D8D8',
    BLOCK_LABEL_COLOR: '#7B7F9E',
    ORANGE: '#FFAC30',
    ASPHALT: '#3F435A',
    PINK: '#FF356B',
    LIGHT_RED: '#EB5E4F',
    AQUA: '#01939B',
    BAD_STRAWBERRY: '#744468',
};

const Size = {
    buttonHeight: '35px'
}

export default function DropDownElement({
                                            data = [],
                                            hasKey = true,
                                            valueKey = 'value',
                                            defaultLabel = 'Zoom & Crop',
                                            defaultValue = null,
                                            onChange = () => {
                                            },
                                            style = {}
                                        }) {
    const [active, setActive] = useState(false);
    const [value, setValue] = useState(defaultLabel);

    useEffect(() => {
        if (defaultValue != null) {
            if (hasKey) {
                const v = data.find(i => i.value === defaultValue);
                v != null && setValue(v[valueKey])
            } else {
                const v = data.find(i => String(i).toLowerCase() === String(defaultValue).toLowerCase());
                setValue(v)
            }
        }
    }, [defaultValue]);

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                if (active) {
                    setActive(!active)
                }
            }}
        >
            <Wrapper style={style}>
                <SelectContainer>
                    <SelectInput onClick={() => setActive(!active)} active={active}>{value}</SelectInput>
                    <OptionsContainer active={active}>

                        {data.map((data, index) => <Option
                            key={index}
                            onClick={() => {
                                setValue(hasKey ? data[valueKey] : data);
                                setActive(!active);
                                onChange({
                                    index: index,
                                    data: data
                                });
                            }}>{hasKey ? data[valueKey] : data}</Option>)
                        }
                    </OptionsContainer>
                </SelectContainer>
            </Wrapper>
        </OutsideClickHandler>
    );
}

const Wrapper = styled.div`
  padding: 5px;
  min-width: 160px;
  position: relative;
`;

const SelectContainer = styled.div`
  position: relative;
  box-sizing: content-box;
`;

const OptionsContainer = styled.div`
  background: ${Color.LIGHT_DARK};
  border-bottom: 2px solid rgba(255, 255, 255, 0.03);
  border-right: 2px solid rgba(255, 255, 255, 0.03);
  border-left: 2px solid rgba(255, 255, 255, 0.03);
  padding: 10px;
  display: ${props => props.active ? 'block' : 'none'};
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: ${props => props.active ? 11 : 10};
  max-height: 300px;
  overflow-y: scroll;
`;
const Option = styled.div`
   color: #FFFFFF;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.03);
  
  &:nth-child(1) {
    border: none;
    padding-top: 0;
  }
  
  &:hover{
    background-color: ${Color.ORANGE};
  }
`;

const SelectInput = styled.div`
  background: ${Color.LIGHT_DARK};
  border: 2px solid rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  padding: 6px;
  height: ${Size.buttonHeight};
  position:relative;
  padding-right: 40px;
  ${props => props.active && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  
  &:after {
    content: '';
    width: 20px;
    height: 10px;
    background-image: url(${iconArrow});
    display: block;
    background-repeat: no-repeat;
    position: absolute;
    background-position: center;
    top: 12px;
    right: 10px;
    transform: rotate(${props => props.active ? '-180deg' : '0'});
  }
  
   &:hover{
    background-color: ${Color.ORANGE};
  } 
`;
