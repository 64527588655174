import ArtsAPI from '../../api/ArtsAPI'

import * as types from '../ActionTypes'


const loadArts_ = () => {
    return {
        type: types.LOAD_ARTS
    }
}
export const loadArtsSuccess = (arts) => {
    return {
        type: types.LOAD_ARTS_SUCCESS,
        arts: arts
    }
}
let loadArtsFailed = (error) => {
    return {
        type: types.LOAD_ARTS_FAIL,
        error: error
    }
}

//thunk
export function loadAllArts() {
    return (dispatch, getState) => {
        dispatch(loadArts_());
        return ArtsAPI.getAllArts().then(
            arts => dispatch(loadArtsSuccess(arts)),
            error => dispatch(loadArtsFailed(error))
        )
    }
}

export function getArt(id) {
    return (dispatch, getState) => {
        dispatch(loadArts_());
        return ArtsAPI.getArt(id).then(
            proj => dispatch(loadArtsSuccess([proj])),
            error => dispatch(loadArtsFailed(error))
        )
    }
}

let createArt_ = () => {
    return {
        type: types.CREATE_ART
    }
}
let createArtSuccess = (art) => {
    return {
        type: types.CREATE_ART_SUCCESS,
        art: art
    }
}
let createArtFailed = (error) => {
    return {
        type: types.CREATE_ART_FAIL,
        error: error
    }
}

//thunk
export function createArt(data) {
    return (dispatch, getState) => {
        dispatch(createArt_());
        return ArtsAPI.createArt(data).then(
            art => dispatch(createArtSuccess(art)),
            error => dispatch(createArtFailed(error))
        )
    }
}

let updateArt_ = () => {
    return {
        type: types.UPDATE_ART
    }
}
let updateArtSuccess = (art) => {
    return {
        type: types.UPDATE_ART_SUCCESS,
        art: art
    }
}
let updateArtFailed = (error) => {
    return {
        type: types.UPDATE_ART_FAIL,
        error: error
    }
}

//thunk
export function updateArt(data) {
    return (dispatch, getState) => {
        dispatch(updateArt_());
        return ArtsAPI.updateArt(data).then(
            art => dispatch(updateArtSuccess(art)),
            error => dispatch(updateArtFailed(error))
        )
    }
}

let deleteArt_ = () => {
    return {
        type: types.DELETE_ART
    }
}
let deleteArtSuccess = (id) => {
    return {
        type: types.DELETE_ART_SUCCESS,
        id: id
    }
}
let deleteArtFailed = (error) => {
    return {
        type: types.DELETE_ART_FAIL,
        error: error
    }
}

//thunk
export function deleteArt(id) {
    return (dispatch, getState) => {
        dispatch(deleteArt_());
        return ArtsAPI.deleteArt(id).then(
            () => dispatch(deleteArtSuccess(id)),
            error => dispatch(deleteArtFailed(error))
        )
    }
}
