import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const OPTIONS = [
    {
        label: 'Horizontal',
        description: 'Ideal for YouTube and anywhere your video will be viewed on a desktop.',
        width: 1280,
        height: 720,
        image: require('../../../assets/images/horizontal-mockup.png')
    },
    {
        label: 'Square',
        description: 'Flexible. Great for Instagram, Twitter, and Facebook.',
        width: 1080,
        height: 1080,
        image: require('../../../assets/images/square-mockup.png')
    },
    {
        label: 'Vertical',
        description: 'Great for Stories format on Instagram, Snapchat, & Facebook.',
        width: 720,
        height: 1280,
        image: require('../../../assets/images/vertical-mockup.png')
    }

]

export default function SizeSelector(props) {
    const {
        width, height,
        onChange = d => {

        }
    } = props;

    return (
        <Wrapper>
            {OPTIONS.map((a, j) => {
                let selected = (a.width == width && a.height == height);
                return (
                    <Item selected={selected} key={`aa_${a.width}_${a.height}`} onClick={() => {
                        onChange(a);
                    }}>
                        <ItemImagePlaceholder>
                            <ItemImage src={a.image}/>
                        </ItemImagePlaceholder>
                        <InfoPlaceholder>
                            <ItemName>
                                <span>
                                    {a.label}
                                </span>
                                {selected == false ? null :
                                    <CheckImg src={require('../../../assets/images/tick_white.svg')}/>
                                }
                            </ItemName>
                            <ItemSizeInfo>
                                {`${a.width}p x ${a.height}p`}
                            </ItemSizeInfo>
                            <ItemDescription>
                                {a.description}
                            </ItemDescription>
                        </InfoPlaceholder>
                    </Item>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 10px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border: ${props => (props.selected == true ? '1px solid rgba(255, 255, 255, 1)' : '1px dashed rgba(255, 255, 255, 0.1)')};
    border-radius: 8px;
    :hover{
      opacity: 0.9;
      border: 1px solid rgba(255, 255, 255, 1);
    }
`;

const ItemImagePlaceholder = styled.div`
    margin-right: 40px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ItemImage = styled.img`
    height: 130px;
`;


const InfoPlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: white;
    margin-left: 30px;
`;

const ItemName = styled.div`
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
    display: flex;
    
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ItemSizeInfo = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const ItemDescription = styled.div`
    font-size: 18px;
    opacity: 0.8;
`;

const CheckImg = styled.img`
    height: 20px;
    width: 20px;
`;
