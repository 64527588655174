import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";

import {Map, Set} from 'immutable'

const StateHelper = {

    getFollowingGroups(state, userId) {
        let {groupsMap, linksMap} = state.groups;
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let groups = linksMap.toArray().filter(a => (a.userId == uId)).map(a => groupsMap.get(a.groupId)).filter(a => (a != undefined));
        return groups;
    },

    getGroupFollowers(state, groupId) {
        let {groupsMap, linksMap} = state.groups;
        let users = linksMap.toArray().filter(a => (a.groupId == groupId)).map(a => state.users.usersMap.get(a.userId)).filter(a => (a != undefined));
        return users;
    },

    getSessionByUserIdAndStartTimestamp(state, userId, startTimestamp) {
        let fSessions = state.sessions.sessionsMap.toArray().filter(a => (a.userId == userId && a.startTimestamp == startTimestamp));
        if (fSessions.length == 0) {
            return;
        }
        return fSessions[0];
    },

    getMaxVideosNumber(state) {
        let currentPlan = state.plans.plansMap.get(state.plans.currentPlanId);
        let {planStartTimestamp} = state.plans;
        let maxVideosNumber = (currentPlan == undefined) ? 1 : +currentPlan.videos_number;
        return maxVideosNumber;
    },

    getAcaliableVideoCredits(state) {

        let {currentUserId, balance} = state.users;
        let projects = state.projects.projectsMap.toArray().filter(a => (a.userId == currentUserId && !a?.isSample));

        // calculate amount of videos from subscribtion plan
        let currentPlan = state.plans.plansMap.get(state.plans.currentPlanId);
        let planStartTimestamp = state.plans.planStartTimestamp == undefined ? 0 : state.plans.planStartTimestamp;
        let avaliableVideoBySubscribtion = (currentPlan == undefined) ? 1 : +currentPlan.videos_number;

        // get prepaid
        const prepaidCredits = state?.users?.prepaidCredit || 0;


        // get this value from BE, much

        let currentCycleProjects = projects.filter(a => (+a.timestamp > +planStartTimestamp));
        let currentProjectLimit = +balance - currentCycleProjects.length;
        return currentProjectLimit;
    },


};

export default StateHelper;
