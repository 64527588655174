import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const BillingAPI = {

    async getUserPayments(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/subscription_payments`, {
            params: {
                userId: userId
            }
        })).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getCurrentUserPayments() {
        let pld = (await axios.get(`${API_ENDPOINT}/user/subscription_payments`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getCurrentUserSubLink() {
        let pld = (await axios.get(`${API_ENDPOINT}/user/sub_link`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async upgradePlan(new_paddle_id) {
        let pld = (await axios.post(`${API_ENDPOINT}/user/upgrade_subscription`, {
            new_paddle_plan_id: new_paddle_id
        })).data;
        return pld.result;
    },


}

export default BillingAPI;
