import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/ProjectsActions";
import * as jobsActions from "../../../redux/actions/JobsActions";
import CommonHelper from "../../../helpers/CommonHelper";
import ProjectsAPI from "../../../api/ProjectsAPI";

import {SUPPORTED_LANGUAGES, PUBLIC_URL} from "../../../constants/config";
import SimpleProgressbar from "../../progress/SimpleProgressbar";
import ClideoEditor from "../../clideo/tools/ClideoEditor";
import ReactHelper from "../../../helpers/ReactHelper";

import CoolModal from '../../modals/CoolModal'

import iconDownload from '../../../assets/images/download.svg';
import FakeLogsTool from "../../fake/FakeLogsTool";
import ReactSlider from "react-slider";
import StepsTool from "../../workspace/tools/StepsTool";
import FakeProgressBar from "../../fake/FakeProgressBar";
import * as plansActions from "../../../redux/actions/PlansActions";
import {Analytics} from "../../../utils/AnalyticsUtility";
import ProjectTranslationTool from "../tools/ProjectTranslationTool";
import UpdateTranslatedSubtitlesTool from "../tools/UpdateTranslatedSubtitlesTool";

const getStepNumber = ({isCaching, isUploading, isMakingSubtitles, isConverting}) => {
    if (isUploading) {
        return 0;
    }
    if (isConverting) {
        return 1;
    }
    if (isMakingSubtitles) {
        return 2;
    }
    if (isCaching) {
        return 3;
    }
}

// 586001 = Medium monthly
// 586002 = Premium monthly
// 646321 = Medium yearly
// 646322 = Premium yearly

const MEDIUM_PADDLE_ID = 586001;
const PREMIUM_PADDLE_ID = 586002;
const MEDIUM_PADDLE_YEARLY_ID = 646321;
const PREMIUM_PADDLE_YEARLY_ID = 646322;

const isMediumOrPremium = () => {
    let x = window.CURRENT_PLAN_PADDLE_ID;
    if (x == undefined || window.IS_FREE_USER == true) {
        return false;
    }
    return ([MEDIUM_PADDLE_ID, PREMIUM_PADDLE_ID, MEDIUM_PADDLE_YEARLY_ID, PREMIUM_PADDLE_YEARLY_ID].indexOf(+x) > -1);
}

export default function ProjectPanel(props) {
    const {id} = props;
    const {
        loading,
        project,
        isAdmin,
        currentUserId,
        jobs,
        jobsLoading,
        maxProjectJobsNumber,
        currentPlan,
        translateActivated
    } = useMappedState(useCallback(state => {
        let currentPlan = state.plans.plansMap.get(state.plans.currentPlanId);
        let jobsPerProject = (currentPlan == undefined) ? 5 : currentPlan.jobsPerProject;
        if (jobsPerProject == undefined) {
            jobsPerProject = 5;
        }
        return {
            maxProjectJobsNumber: +jobsPerProject,
            loading: state.users.loading || state.projects.loading,
            jobsLoading: state.jobs.loading,
            project: state.projects.projectsMap.get(id),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            currentPlan: state.plans.plansMap.get(state.plans.currentPlanId),
            jobs: state.jobs.jobsMap.toArray().filter(a => (a.projectId == id)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            translateActivated: isMediumOrPremium()
        }
    }, []));

    const subtitlesRef = useRef();
    const appearanceRef = useRef();

    const [updT, setUpdT] = useState(0);

    const [exceededModalVisible, setExceededModalVisible] = useState(false);

    const [fixTranslationModalVisible, setFixTranslationModalVisible] = useState(false);

    const [caching, setCaching] = useState(false);
    const [blobUrl, setBlobUrl] = useState();
    const [cachingProgress, setCachingProgress] = useState(0);
    // const [cachingSkipped, setCachingSkipped] = useState(false);
    const [cachingSkipped, setCachingSkipped] = useState(true);

    const [translateSelectVisible, setTranslateSelectVisible] = useState(false);
    const [translateLanguage, setTranslateLanguage] = useState('en');

    const [subtitlesLoaded, setSubtitlesLoaded] = useState(false);
    const [rendersVisible, setRendersVisible] = useState(false);
    const [renderButtonLoading, setRenderButtonLoading] = useState(false);

    const dispatch = useDispatch();
    let finishedJobs = jobs.filter(a => (a.status == 'finished'));
    let notFinishedJobs = jobs.filter(a => (a.status != 'finished'));
    let projectStatus = (project == undefined) ? undefined : project.status;

    let hasNotFinishedJob = (notFinishedJobs.length > 0);
    // let hasNotFinishedJob = true;

    ReactHelper.useInterval(() => {
        let status = (project == undefined) ? undefined : project.status;
        if (status == 'ready') {
            return;
        }
        dispatch(actions.getProject(id));
    }, 3000);

    ReactHelper.useInterval(() => {
        if (notFinishedJobs.length > 0) {
            dispatch(jobsActions.getProjectJobs(id));
        }
    }, 5000);

    useEffect(() => {
        try {
            window.mixpanel.track('ProjectEditorVisit', {
                projectId: id
            });
        } catch (exc) {

        }
    }, []);


    useEffect(() => {
        dispatch(plansActions.loadAllPlans());
    }, []);
    useEffect(() => {
        dispatch(actions.getProject(id));
        dispatch(jobsActions.getProjectJobs(id));
        let url240p = (project == undefined) ? undefined : project.originalConverted240pUrl;
        if (url240p == undefined) {
            return;
        }
        // setCaching(true);
        // CommonHelper.getCachedUrl(url240p, cProgress => {
        //     if ((project == undefined || project.language == undefined || url240p == undefined) == false) {
        //         setCachingProgress(cProgress);
        //     }
        // }).then(bUrl => {
        //     setCaching(false);
        //     setBlobUrl(bUrl);
        // });
    }, [projectStatus]);

    useEffect(() => {
        if (projectStatus == 'ready' && subtitlesLoaded == false) {
            ProjectsAPI.getProjectSubtitles(id).then(subs => {
                subtitlesRef.current = subs;
                setSubtitlesLoaded(true);
            });
        }
    }, [projectStatus]);

    if (project == undefined) {
        return <Code/>;
    }
    console.log('project panel: render: project = ', project);
    let leftJobsNumber = maxProjectJobsNumber - jobs.length;

    let preparingEstimatedDuration = 100;
    try {
        preparingEstimatedDuration = 1.2 * project.duration * Math.pow(project.height / 720.0, 2);
    } catch (eer) {

    }

    const hasSubtitles = (projectStatus == 'ready' && subtitlesLoaded == true);
    const hasLanguage = (project.language != undefined);
    let isConverting = (projectStatus == 'new');
    const obtainingSubtitles = (hasSubtitles == false && isConverting == false);
    let blockingMessage = (caching == true) ? `Caching video locally for better performance...` : (isConverting == true ? 'Your video is converting...' : (hasSubtitles == false ? 'Generating subtitles...' : ''));

    let projectSubtitles = subtitlesRef.current;

    console.log('render: hasNotFinishedJob = ', hasNotFinishedJob);
    console.log('render: ProjectPanel: currentPlan = ', currentPlan);
    // hasNotFinishedJob = true;
    let url240p = (project == undefined) ? undefined : project.originalConverted240pUrl;

    let videoUrl = (hasSubtitles == false || isConverting == true) ? undefined : (blobUrl != undefined ? blobUrl : (cachingSkipped ? url240p : undefined))
    let hasWatermark = (currentPlan == undefined);

    let canTranslate = (project != undefined && project.isTranslated != true && projectSubtitles != undefined && projectSubtitles.length > 0);
    let canRevertTranslation = (project != undefined && project.isTranslated == true);

    if (isConverting == true || (caching == true && cachingSkipped == false) || hasSubtitles == false) {
        return (
            <BlockingScreenPlaceholder>
                <BlockingInner>

                    <StepsPlaceholder>
                        <StepsTool currentStep={getStepNumber({
                            isConverting,
                            isCaching: (caching == true && cachingSkipped == false),
                            isMakingSubtitles: !hasSubtitles,
                            isUploading: false
                        })}/>
                    </StepsPlaceholder>

                    <FakeProgressbarPlaceholder>
                        <FakeProgressBar duration={preparingEstimatedDuration}/>
                    </FakeProgressbarPlaceholder>

                    {blobUrl == undefined ? null :
                        <DummyVideoPlaceholder>
                            <SampleVideo style={{height: 240, marginBottom: 40}} controls={true}>
                                <source src={blobUrl}/>
                            </SampleVideo>
                        </DummyVideoPlaceholder>
                    }

                    {caching == false ?
                        <>
                            <BlockingText>
                                {blockingMessage}
                            </BlockingText>
                            <SubBlockingText>
                                {`Video quality in video editor is a preview only and not reflective of final quality`}
                            </SubBlockingText>
                            <SubBlockingText>
                                {`Please wait. It can take several minutes`}
                            </SubBlockingText>
                        </>
                        :
                        <>
                            <BlockingText>
                                <BlockingText>
                                    {blockingMessage}
                                </BlockingText>
                                <div style={{width: '100%', height: 15, marginBottom: 15, marginTop: 15}}>
                                    <SimpleProgressbar progress={cachingProgress}/>
                                </div>
                                <SubBlockingText>
                                    {`Please wait`}
                                </SubBlockingText>
                                {/*{caching &&*/}
                                {/*<SubBlockingText>*/}
                                {/*<SkipSpan onClick={() => {*/}
                                {/*setCachingSkipped(true);*/}
                                {/*}}>*/}
                                {/*skip*/}
                                {/*</SkipSpan>*/}
                                {/*</SubBlockingText>*/}
                                {/*}*/}
                            </BlockingText>
                        </>
                    }

                </BlockingInner>
                }

            </BlockingScreenPlaceholder>
        );
    }


    return (
        <Wrapper key={`upt_t${updT}`}>
            <ClideoEditor
                {...project}
                videoUrl={videoUrl}
                subtitles={projectSubtitles}
                onSubtitlesChange={async newSubtitles => {
                    subtitlesRef.current = newSubtitles;
                    console.log('onSubtitlesChange: newSubtitles = ', newSubtitles);
                    await ProjectsAPI.updateProjectSubtitles(id, newSubtitles);
                    // await dispatch(actions.updateProjectSubtitles(id, newSubtitles));
                }}
                onAppearanceChange={async a => {
                    let {proportion = '1:1'} = a;
                    let [q, z] = proportion.split(':').map(x => +x);
                    let k = 1.0 * (+q) / (+z);
                    let [h, w] = [project.height, project.height * k];
                    a.width = w;
                    a.height = h;
                    appearanceRef.current = a;
                    await dispatch(actions.updateProject({id: id, optionsData: a}));
                }}
                onBack={() => {
                    CommonHelper.linkTo('/');
                }}
                bottomRightContent={(
                    <BottomRight>

                        <BottomProjName>

                            <RendersWrapperSpan clickable={leftJobsNumber < maxProjectJobsNumber} onClick={() => {
                                if (leftJobsNumber == maxProjectJobsNumber) {
                                    return;
                                }
                                setRendersVisible(true);
                            }}>
                                <StatSpan>{`${leftJobsNumber} renders left`}</StatSpan>
                                {notFinishedJobs.length == 0 ? null :
                                    <>
                                        <StatSpan style={{
                                            marginLeft: 4,
                                            marginRight: 5
                                        }}>{` (${notFinishedJobs.length} in progress)`}</StatSpan>
                                        <Spin theme={'alfa-on-color'} visible={true}/>
                                    </>
                                }
                            </RendersWrapperSpan>
                        </BottomProjName>

                        <Button onClick={async () => {
                            if (loading) {
                                return;
                            }
                            if (leftJobsNumber <= 0) {
                                setExceededModalVisible(true);
                                return;
                            }

                            try {
                                window.mixpanel.track('CreateVideoButtonClick', {
                                    projectId: project.id,
                                    projectName: project.name,
                                    projectDuration: project.duration,
                                    projectHeight: project.height,
                                    projectWidth: project.width
                                });
                            } catch (eee) {

                            }

                            Analytics.send('editor.render', 'editor');
                            setRenderButtonLoading(true);
                            await dispatch(jobsActions.createProjectProcessingJob(id, hasWatermark));
                            setRenderButtonLoading(false);
                            window.location.href = `${PUBLIC_URL}/#/project/${id}/view`
                        }}>
                            {!(renderButtonLoading || loading) ? null :
                                <span style={{marginRight: 5}}>
                                    <Spin theme={'alfa-on-color'} visible={renderButtonLoading || loading}/>
                                </span>
                            }
                            <span>
                                    Create video
                                </span>
                        </Button>
                    </BottomRight>
                )}
                bottomContent={(
                    <BottomContentPlaceholder>

                    </BottomContentPlaceholder>
                )}
                topRightContent={(
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                        {canTranslate == false ? null :
                            <FuckingButton onClick={() => {
                                setTranslateSelectVisible(true);
                            }}>
                                <TranslateImg src={require('../../../assets/images/mdi_g_translate.svg')}
                                              style={{marginRight: 5}}/>
                                <div style={{color: 'white', fontWeight: 'bold'}}>
                                    Translate
                                </div>
                            </FuckingButton>
                        }

                        {canRevertTranslation == false ? null :
                            <TranslateImg src={require('../../../assets/images/file-text.svg')} onClick={() => {
                                setFixTranslationModalVisible(true);
                            }}/>
                        }

                    </div>
                )}
            />

            {rendersVisible == false ? null :
                <CoolModal onClose={() => {
                    setRendersVisible(false);
                }}>

                    {jobs.map((a, i) => {
                        let isLoading = (a.status != 'finished');
                        return (
                            <HistoryItem key={`${a.id}`}>
                                <Resolution>Size {`${a.optionsData.width}x${a.optionsData.height}`}</Resolution>
                                <Date>{moment(a.timestamp).format('MM/DD/YYYY HH:mm')}</Date>
                                {isLoading == true ? <Spin visible={true} theme={'alfa-on-color'}/> :
                                    <DownloadIcon wide={(jobs.length > 4)} onClick={() => {
                                        const win = window.open(a.resultUrl, '_blank');
                                        win.focus();
                                    }}/>
                                }
                            </HistoryItem>
                        )
                    })}

                </CoolModal>
            }

            {exceededModalVisible == false ? null :
                <CoolModal onClose={() => {
                    setExceededModalVisible(false);
                }}>

                    <ExceededP>
                        You exceeded maximum number of renders for this project.
                        Tou can duplicate this project and render. It will cost one credit.
                    </ExceededP>

                    <ExceededButtonPlaceholder>
                        <Button onClick={async () => {
                            let pld = await dispatch(actions.cloneProject(id));
                            let newProject = pld.project;
                            console.log('project cloned! newProject = ', newProject);
                            await dispatch(jobsActions.createProjectProcessingJob(newProject.id));
                            // CommonHelper.linkTo(`/project/${newProject.id}`);
                            window.location.href = `${PUBLIC_URL}/#/project/${newProject.id}/view`
                        }}>
                            <Spin visible={loading || jobsLoading}/>
                            {(loading || jobsLoading) ? null : 'Clone and render!'}
                        </Button>
                    </ExceededButtonPlaceholder>

                </CoolModal>
            }

            {translateSelectVisible == false ? null :
                <CoolModal onClose={() => {
                    setTranslateSelectVisible(false);
                }}>

                    <ProjectTranslationTool {...project} translateActivated={translateActivated}
                                            onTranslated={async l => {
                                                let subs_ = await ProjectsAPI.getProjectSubtitles(id);
                                                await dispatch(actions.getProject(id));
                                                subtitlesRef.current = subs_;
                                                setSubtitlesLoaded(true);
                                                setTranslateSelectVisible(false);
                                                setUpdT(+moment());
                                                setFixTranslationModalVisible(true);
                                            }}/>

                </CoolModal>
            }

            {fixTranslationModalVisible == false ? null :
                <CoolModal width={920} onClose={() => {
                    setFixTranslationModalVisible(false);
                }}>

                    <div style={{width: '100%'}}>
                        <UpdateTranslatedSubtitlesTool id={project.id} onRevert={async () => {
                            let subs_ = await ProjectsAPI.getProjectSubtitles(id);
                            await dispatch(actions.getProject(id));
                            subtitlesRef.current = subs_;
                            await dispatch(actions.getProject(id));
                            setUpdT(+moment());
                            setFixTranslationModalVisible(false);
                        }}/>
                    </div>

                </CoolModal>
            }


            {hasNotFinishedJob == false ? null :
                <BigOverlay>
                    <ProcessingHeading>
                        Processing your video
                    </ProcessingHeading>
                    <ProcessingSubHeading>
                    Video quality in video editor is a preview only and not reflective of final quality
                    </ProcessingSubHeading>
                    <ProcessingSubHeading>
                        Please wait. It can take several minutes...
                    </ProcessingSubHeading>
                    <FakeProgressbarPlaceholder>
                        <FakeProgressBar duration={project.duration}/>
                    </FakeProgressbarPlaceholder>
                    <LoaderPlaceholder>
                        <FakeLogsTool/>
                    </LoaderPlaceholder>
                </BigOverlay>
            }

        </Wrapper>
    );
}

const FuckingButton = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  border-radius: 4px;
  background: #212330;
  align-items: center;
  cursor: pointer;

  :hover {
    background: #3F435A;
  }
`;

const ExceededP = styled.div`
  color: white;
  margin-bottom: 30px;
`;

const TranslateImg = styled.img`
  height: 24px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ProcessingHeading = styled.div`
  color: white;
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
`;

const ProcessingSubHeading = styled.div`
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;
`;

const ExceededButtonPlaceholder = styled.div`
  text-align: center;
`;

const BigOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #171822;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FakeProgressbarPlaceholder = styled.div`
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 0px;
  height: 8px;
  width: 520px;
`;

const LoaderPlaceholder = styled.div`
  width: 520px;
  height: calc(100% - 400px);
  text-align: center;
`;

const Resolution = styled.div`
  min-width: 110px;
`;

const Date = styled.div`
  flex: 1;

`;

const DownloadIcon = styled.div`
  background-image: url(${iconDownload});
  background-position: center;
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-right: ${props => (props.wide == true ? '20px' : '0px')};
`;


const HistoryItem = styled.div`
  color: #EFEFEF;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #3a3a4a;
`;

const BottomContentPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
`;

const BottomLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const BottomRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BottomProjName = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

const DummyVideoPlaceholder = styled.div`
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
`;

const SampleVideo = styled.video`
  //width: 600px;
  //height: 50vh;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  box-sizing: border-box;
  background: #181923;
`;

const BlockingScreenPlaceholder = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  box-sizing: border-box;
`;

const BlockingInner = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #191A23;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

const StepsPlaceholder = styled.div`
  display: block;
  margin-bottom: 110px;
  width: 800px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const BlockingText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
`;

const SubBlockingText = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #EFEFEF;
`;

export const Button = styled.div`
  height: 37px;
  background: #EA523D;
  border-radius: 5px;
  cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;

  :hover {
    opacity: ${props => (props.disabled == true ? 0.5 : 0.8)};
  }
`;


const StatSpan = styled.span`
  opacity: 0.5;
`;

const RendersWrapperSpan = styled.span`
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
  color: #7B7F9E;
  font-size: 16px;

  :hover {
    text-decoration: ${props => (props.clickable ? 'underline' : 'none')};
  }
`;

const SkipSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;



