import {
    SET_SUBTITLE_COLOR,
    SET_FONT_SIZE,
    SET_SUBTITLES_PANEL_COLOR,
    SET_FONT_POSITION,
    SET_FONT_WEIGHT,
    SET_FONT_STYLE,
    SET_SUBTITLES_PANEL_TYPE,
    MOVE_SUBTITLES_PANEL_UP,
    MOVE_SUBTITLES_PANEL_DOWN,
    INCREASE_FONT_LINE_HEIGHT,
    DECREASE_FONT_LINE_HEIGHT,
    SET_FONT,
    SET_FONT_ASPECT,
    SET_FONT_LINE_HEIGHT_ASPECT
} from "../actions/SubtitleActions";
import {EditorConfig, SubPanelBg} from "../../config/config";


const initialState = {
    textColor: EditorConfig.defaultTextColor,
    panelColor: EditorConfig.defaultPanelColor,
    fontSize: EditorConfig.defaultTextSize,
    fontAspect: 0,
    textAlign: 'center',
    textStyle: 'normal',
    textWeight: 'normal',
    backgroundType: SubPanelBg.FULL,
    subtitlePosition: 0,
    font: 'Roboto',
    lineHeight: EditorConfig.defaultTextSpace,
    lineHeightAspect: 0
}

const SubtitleReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case SET_FONT_LINE_HEIGHT_ASPECT:
            return {
                ...state,
                lineHeightAspect: action.data
            }

            case SET_FONT_ASPECT:
            return {
                ...state,
                fontAspect: action.data
            }
    case SET_FONT:
            return {
                ...state,
                font: action.data
            }

        case SET_SUBTITLE_COLOR:
            return {
                ...state,
                textColor: action.data
            }

        case INCREASE_FONT_LINE_HEIGHT:
            return {
                ...state,
                lineHeight: +state.lineHeight + 1
            }

        case DECREASE_FONT_LINE_HEIGHT:
            const lineHeight = +state.lineHeight - 1;
            const minLineHeight = state.fontSize + (state.fontSize / 3)
            return {
                ...state,
                lineHeight: lineHeight >= minLineHeight ? lineHeight : state.lineHeight
            }

        case SET_SUBTITLES_PANEL_COLOR:
            return {
                ...state,
                panelColor: action.data
            }

        case SET_SUBTITLES_PANEL_TYPE:
            return {
                ...state,
                backgroundType: action.data
            }

        case SET_FONT_SIZE:
            return {
                ...state,
                fontSize: action.data
            }

        case SET_FONT_POSITION:
            return {
                ...state,
                textAlign: action.data
            }

        case SET_FONT_WEIGHT:
            return {
                ...state,
                textWeight: state.textWeight === 'normal' ? 'bold' : 'normal'
            }

        case SET_FONT_STYLE:
            return {
                ...state,
                textStyle: state.textStyle === 'normal' ? 'italic' : 'normal'
            }

        case MOVE_SUBTITLES_PANEL_UP:
            return {
                ...state,
                subtitlePosition: state.subtitlePosition + 0.5
            }

        case MOVE_SUBTITLES_PANEL_DOWN:
            return {
                ...state,
                subtitlePosition: state.subtitlePosition > 0
                    ? state.subtitlePosition - 0.5
                    : 0
            }

        default:
            return state;
    }

}

export default SubtitleReducer;
