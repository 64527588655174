import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import FakeProgressBar from "../../fake/FakeProgressBar";

export default function StepsTool(props) {
    const {
        steps = [
            'Uploading video',
            'Converting video',
            'Generating subtitles',
            'Caching'
        ],
        currentStep = 1,
        duration = 120,
        hasProgress = true
    } = props;


    return (
        <Wrapper>

            {/*{hasProgress == false ? null :*/}
                {/*<TopProgressPlaceholder>*/}
                    {/*<FakeProgressbarPlaceholder>*/}
                        {/*<FakeProgressBar duration={duration}/>*/}
                    {/*</FakeProgressbarPlaceholder>*/}
                {/*</TopProgressPlaceholder>*/}
            {/*}*/}

            <BackProgress>
                <BackInner>
                    {steps.slice(1).map((a, i) => {
                        let isSelected = (+currentStep > +i);
                        return (
                            <LineItem key={i} selected={isSelected}>

                            </LineItem>
                        )
                    })}
                </BackInner>
            </BackProgress>

            <StepsList>
                {steps.map((a, i) => {
                    let isPassed = (+currentStep > +i);
                    let isCurrent = (+i == +currentStep);
                    return (
                        <StepItem key={i} active={isPassed}>
                            <StepNumberPlaceholder
                                passed={isPassed}
                                current={isCurrent}
                                style={{color: (isCurrent || isPassed) ? `#FFFFFF` : '#4D4F59'}}
                            >
                                {isPassed ? <DoneImg src={require('../../../assets/images/done.svg')}/> : (+i + 1)}
                            </StepNumberPlaceholder>
                            <StepLabel
                                current={isCurrent}
                            >
                                {a}

                            </StepLabel>
                        </StepItem>
                    )
                })}
            </StepsList>


        </Wrapper>
    );
}

const stepWidth = 180;

const FakeProgressbarPlaceholder = styled.div`
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 0px;
    height: 8px;
    width: 520px;
`;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;

const TopProgressPlaceholder = styled.div`
    margin-bottom: 40px;
`;

const BackProgress = styled.div`
    position: absolute;
    top: 22px;
    height: 2px;
    left: ${stepWidth / 2}px;
    right: ${stepWidth / 2}px;
    z-index: 1;
    box-sizing: border-box;
`;

const BackInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.15);
`;

const LineItem = styled.div`
    flex: 1;
    background: ${props => (props.selected ? '#FFAC30' : 'transparent')};
`;

const StepsList = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;

const StepItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    
`;

const StepLabel = styled.div`
    color: #F2F2F2;
    font-weight: ${props => (props.current ? 'bold' : 'normal')};
    margin-top: 10.5px;
    width: ${200}px;
    text-align: center;
`;

const StepNumberPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    font-weight: bold;
    color: white;
    background: ${props => (props.passed ? '#FFAC30' : '#212330')};
    border: 2px solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    font-size: 24px;
    line-height: 29px;
`;

const DoneImg = styled.img`
    width: 26px;
    height: 26px;
`;
