import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'
import SocialAuthForm from "./SocialAuthForm";
import {generatePasswordFromUid} from "../../../helpers/SocialAuthHelper";
import {Analytics} from "../../../utils/AnalyticsUtility";

export default function StyledLoginForm(props) {
    const {
        onSubmit = () => {

        },
        onSocialSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLostPasswordClick = () => {
        }, loading = false
    } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [clickedSocial, setClickedSocial] = useState(false);

    return (
        <Wrapper>

            <TopPlaceholder>
                Sign In
            </TopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <SocialAuthForm
                        onLoaded={(social) => {
                            if (clickedSocial == false) {
                                return;
                            }
                            if (social == undefined || social.email == undefined){
                                return;
                            }
                            onSocialSubmit({
                                email: social.email.toLowerCase(),
                                password: generatePasswordFromUid(social.uid),
                                social
                            })
                            Analytics.send('lead.sign_up', 'lead');
                        }}
                        onClickSocial={() => {
                            setClickedSocial(true);
                        }}
                    />
                </Field>
                <Field>
                    <Label>
                        Email
                    </Label>
                    <Input
                        autoComplete={false}
                        autoFill={false}
                        value={email} type={'email'} placeholder={''} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Password
                    </Label>
                    <Input value={password} type={'password'} placeholder={''}
                           onKeyPress={e => {
                               if (e.key === 'Enter') {
                                   onSubmit({email: email.toLowerCase().trim(), password})
                               }
                           }}
                           onChange={e => {
                               setPassword(e.target.value);
                           }}
                           autoComplete={false}
                           autoFill={false}
                    />
                    <ForgotPlaceholder>
                        <ForgotSpan onClick={() => {
                            onLostPasswordClick();
                        }}>
                            Forgot password
                        </ForgotSpan>
                    </ForgotPlaceholder>
                </Field>


                <Field>
                    <Button background={'#FFAC30'}
                            onClick={() => {
                                onSubmit({email: email.toLowerCase(), password})
                            }}>
                        <Spin theme={'alfa-on-color'} visible={loading}/>
                        {loading == true ? null :
                            'Login'
                        }
                    </Button>
                </Field>

            </ContentPlaceholder>

            <BottomPlaceholder>
                {`Don't have an account?`}
                <SignupSpan style={{marginLeft: 4}} onClick={() => {
                    onSignUpClick();
                }}>
                    Sign Up
                </SignupSpan>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const PrivacyPlaceholder = styled.div`
    
`;

const SignupSpan = styled.span`
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.75;
    }
`;

const ForgotPlaceholder = styled.div`
    text-align: right;
    color: #00A3AB;
`;

const ForgotSpan = styled.span`
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const BottomPlaceholder = styled.div`
    color: white;
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 23px;
    
`;

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 40px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
`;

const ContentPlaceholder = styled.div`
    
`;


const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Label = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #7B7F9E;
    margin-bottom: 14px;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;
