import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import ProjectPanel from "../projects/panels/ProjectPanel";

export default function ProjectApp(props) {
    let {id} = props.match.params;

    return (
        <SubtitlesTemplate active={`project/${id}`}>

            <React.Fragment key={`pr_e_${id}`}>
                <ProjectPanel id={id}/>
            </React.Fragment>

        </SubtitlesTemplate>
    );
}

const Wrapper = styled.div`
    
`;
