import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import CardioMoodTemplate from "../templates/CardioMoodTemplate";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import UserWorkspacePanel from "../workspace/panels/UserWorkspacePanel";
import UserWaveTemplatesPanel from "../wave/panels/UserWaveTemplatesPanel";

export default function UserWaveTemplatesApp() {

    return (
        <SubtitlesTemplate active={'wave_templates'}>

            <UserWaveTemplatesPanel/>

        </SubtitlesTemplate>
    );
}
