import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CachedAnimationPlayer from "../../wave/tools/CachedAnimationPlayer";

export default function WaveLayer(props) {
    const {
        top = 0,
        left = 0,
        waveWidth = 240,
        waveHeight = 240,

        iniId = 'dd',

        // drawFunction
        // options

        width = 500,
        height = 500} = props;

    return (
        <Wrapper width={width} height={height}>
            <WavePlaceholder {...props} >
                <CachedAnimationPlayer
                    {...props}
                    id={`${iniId}_${top}_${height}_${waveHeight}_${waveWidth}`}
                    width={waveWidth} height={waveHeight} />
            </WavePlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    position: relative;
`;

const WavePlaceholder = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: ${props => props.waveWidth}px;
    height: ${props => props.waveWidth}px;
`;
