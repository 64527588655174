import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';

import styled from 'styled-components';
import {useMappedState} from "redux-react-hook";
import StateHelper from "../../../helpers/StateHelper";

export default function UserBalancePanel() {
    const {videoBalance, plans, amontOfSubscriptionVideos, currentUserPlan} = useMappedState(useCallback(state => {
        return {
            videoBalance: state.users.videoBalance,
            plans: state.plans.plansMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentPlanId: state.plans.currentPlanId,
            currentUserPlan: state.plans.currentPlanId != null ? state.plans.plansMap.toArray().find((p) => p.id === state.plans.currentPlanId) : null
        }}))
    return currentUserPlan != null || videoBalance != null ? (
        <Wrapper>
            <UserStatsContainer>
                <UserStatsContainerItem>
                    <Stats color={'#FFAC30'}>Subscription</Stats>
                    <Stats align={'right'}></Stats>
                    <Stats align={'right'}>{videoBalance.subscribed != null ? `${videoBalance.subscribed } videos/month` : '-'}</Stats>
                </UserStatsContainerItem>
                <UserStatsContainerItem>
                    <Stats color={'#FFAC30'}>Pay as you go</Stats>
                    <Stats align={'right'}/>
                    <Stats align={'right'}> {videoBalance.prepaid != null ? `${videoBalance.prepaid} videos/year` : '-'} </Stats>
                </UserStatsContainerItem>
            </UserStatsContainer>
        </Wrapper>
    ) : null;
}

const Wrapper = styled.div`
`;

const UserStatsContainer = styled.div`
 display: flex;
 flex-direction: column;
`;

const UserStatsContainerItem = styled.div`
 display: flex;
 flex-direction: row;
 font-size: 24px;
 flex-wrap: wrap;
 justify-content: space-between;
 padding: 15px 0;
 border-bottom: 1px solid rgba(216,216,216, 0.3);
`;

const Stats = styled.div`
  flex: 1;
  text-align: ${props => props.align};
  color: ${props => props.color != null ? props.color : '#fff'}
`;
