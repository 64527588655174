import JobsAPI from '../../api/JobsAPI'

import * as types from '../ActionTypes'


const loadJobs_ = () => {
    return {
        type: types.LOAD_JOBS
    }
}
export const loadJobsSuccess = (jobs) => {
    return {
        type: types.LOAD_JOBS_SUCCESS,
        jobs: jobs
    }
}
let loadJobsFailed = (error) => {
    return {
        type: types.LOAD_JOBS_FAIL,
        error: error
    }
}

//thunk
export function getProjectJobs(projectId) {
    return (dispatch, getState) => {
        dispatch(loadJobs_());
        return JobsAPI.getProjectJobs(projectId).then(
            jobs => dispatch(loadJobsSuccess(jobs)),
            error => dispatch(loadJobsFailed(error))
        )
    }
}

export function getUserJobs(userId) {
    return (dispatch, getState) => {
        dispatch(loadJobs_());
        return JobsAPI.getUserJobs(userId).then(
            jobs => dispatch(loadJobsSuccess(jobs)),
            error => dispatch(loadJobsFailed(error))
        )
    }
}

let createJob_ = () => {
    return {
        type: types.CREATE_JOB
    }
}
let createJobSuccess = (job) => {
    return {
        type: types.CREATE_JOB_SUCCESS,
        job: job
    }
}
let createJobFailed = (error) => {
    return {
        type: types.CREATE_JOB_FAIL,
        error: error
    }
}

//thunk
export function createProjectProcessingJob(projectId, hasWatermark = false) {
    return (dispatch, getState) => {
        dispatch(createJob_());
        return JobsAPI.createProjectProcessingJob(projectId, hasWatermark).then(
            job => dispatch(createJobSuccess(job)),
            error => dispatch(createJobFailed(error))
        )
    }
}
