import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle
} from 'react';
import styled from 'styled-components';

export default forwardRef((props, ref) => {
    const {
        width, height,
        id = 'test',
        drawFunction = (ctx, opts) => {

        },
        onReady = () => {

        }
    } = props;
    const canvasRef = useRef();
    const context = useRef();

    useEffect(() => {
        context.current = canvasRef.current.getContext("2d");
        onReady();
    }, [id, width, height]);

    useImperativeHandle(ref, () => (
        {
            update: options => {
                // drawFunction(canvasRef.current.getContext("2d"), options);
                drawFunction(context.current, {...options, width: width, height: height});
            }
        }
    ), [id]);

    return (
        <Wrapper width={width} height={height} >
            <Canvas width={width} height={height} ref={canvasRef} id={id} key={id}></Canvas>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    box-sizing: border-box;
`;

const Canvas = styled.canvas`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;
