export const FontList = [
    'Arial',
    'Roboto',
    'Open Sans',
    'Trade Winds',
    'Montserrat',
    'Roboto Condensed',
    'Raleway',
    'Lato',
    'Ubuntu',
    'PT Sans',
    'Nunito'
];

export const SubPanelBg = {
    NONE: 'none',
    FULL: 'full',
    OUTLINE: 'outline',
    WRAP: 'wrap'
}

export const ScaleOptions = {
    FIT: 'fit',
    ZOOM: 'zoom&crop',
}

export const VideoFormats = {
    SIZE_0: 0,
    SIZE_1s1: 1.0,
    SIZE_4s5: 4.0/5.0,
    SIZE_5s4: 5.0/4.0,
    SIZE_16s9: 16.0/9.0,
    SIZE_9s16: 9.0/16.0,
}


export const EditorConfig = {
    defaultPanelColor: '#000000',
    defaultTextColor: '#ffffff',
    defaultTextSpace: 24 + (24/3),
    defaultTextSize: 24,
    defaultVideoScale: ScaleOptions.FIT
}
