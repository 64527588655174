import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SubtitlesTemplate from "../templates/SubtitlesTemplate";
import ProjectPanel from "../projects/panels/ProjectPanel";
import ProjectViewPanel from "../projects/panels/ProjectViewPanel";
import FacebookPlugin from "../ui/FacebookPlugin";

export default function ProjectViewApp(props) {
    let {id} = props.match.params;

    return (
        <SubtitlesTemplate active={`project/${id}`}>

            <React.Fragment key={`pr_e_${id}`}>
                <ProjectViewPanel id={id}/>
            </React.Fragment>
            {/* <FacebookPlugin/> */}

        </SubtitlesTemplate>
    );
}

const Wrapper = styled.div`
    
`;
