import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    forwardRef,
    useImperativeHandle
} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Rnd} from "react-rnd";
import {useDebounce, useDebouncedCallback} from 'use-debounce';

import './style.css'

import useComponentSize from "@rehooks/component-size";
import DrawHelper from "../../../helpers/DrawHelper";
import SubtitlesHelper from "../../../helpers/SubtitlesHelper";

const TimelineEditor = forwardRef((props, ref) => {
    const {

        playing = false,

        subtitles = [],

        totalDuration = 120,
        rulerHeight = 30,
        height = undefined,
        labelsColor = 'grey',
        hasRightTick = true,
        hasZeroTick = true,
        widthPerSecond = 100,

        minWidthPerSecond = 40,

        onChange = newBoxes => {
            console.log('onChange: new boxes = ', JSON.stringify(boxes));
        },

        onStartChange = () => {

        },

        onSeek = currT => {

        }

    } = props;
    const rulerRef = useRef();
    const hoverTimeRef = useRef();
    const wrapperRef = useRef();
    const currTimeRef = useRef();
    let size = useComponentSize(wrapperRef);
    const [selectedBoxId, setSelectedBoxId] = useState(undefined);
    const [updT, setUpdT] = useState(0);

    let realStripeWidth = widthPerSecond * totalDuration;
    let maxTicksNumber = Math.floor(1.0 * realStripeWidth / minWidthPerSecond);

    // let xGridItems = DrawHelper.getGridForTimeSpan(0, totalDuration * 1000, Math.max(10, Math.floor(size.width / widthPerSecond) / 10.0 * totalDuration));
    let xGridItems = DrawHelper.getGridForTimeSpan(0, totalDuration * 1000, maxTicksNumber);

    const subtitlesRef = useRef(subtitles);

    useImperativeHandle(ref, () => ({

        setTimePosition(time) {
            let pos = 100.0 * (+time / totalDuration);
            try {
                currTimeRef.current.style.left = `${pos}%`;
                let currSub = SubtitlesHelper.getCurrentSubtitle(time, subtitlesRef.current);
                let elems = document.querySelectorAll(".titles-film-stripe .rnd-item");
                if (currSub != undefined) {
                    document.getElementsByClassName(`rnd_${currSub.id}`)[0].classList.add('current');
                }
                [].forEach.call(elems, (el) => {
                    el.classList.remove("current");
                    if ((currSub != undefined) && (el.classList.contains(`rnd_${currSub.id}`) > -1)) {
                        document.getElementsByClassName(`rnd_${currSub.id}`)[0].classList.add('current');
                    } else {
                    }
                });
            } catch (e) {

            }
        },

        setSubtitles(subs) {
            subtitlesRef.current = subs;
            setUpdT(+new Date());
        },

        scrollToBox(boxId) {
            console.log('scrollToBox occured!');
            try {
                let box = subtitlesRef.current.filter(a => (a.id == boxId))[0];
                let middle = (+box.to + +box.from) / 2.0;
                let pos = 1.0 * realStripeWidth * middle / totalDuration;
                console.log('pos = ', pos);
                pos = pos - window.innerWidth / 2;
                document.getElementsByClassName('titles-film-stripe')[0].scrollLeft = pos;
            } catch (e) {

            }
        }

    }));

    const boxes = subtitlesRef.current;
    const stripeHeight = size.height - scrollHeight;

    return (
        <Wrapper ref={wrapperRef} className={'titles-film-stripe'}>
            <WrapperInner width={realStripeWidth}>
                <RulerOverlay ref={rulerRef}
                              height={rulerHeight}
                              onClick={e => {
                                  let rect = e.target.getBoundingClientRect();
                                  let x = e.clientX - rect.left; //x position within the element.
                                  let seekTime = 1.0 * totalDuration * x / realStripeWidth;
                                  onSeek(seekTime);
                              }}
                              onMouseMove={e => {
                                  try {
                                      document.getElementById('hov_time_vert').style.display = 'block';
                                      let rect = e.target.getBoundingClientRect();
                                      let x = e.clientX - rect.left; //x position within the element.
                                      document.getElementById('hov_time_vert').style.left = `${x}px`;
                                      let t = (1.0 * (+x) / (+realStripeWidth)) * totalDuration;
                                      let fracSec = Math.round((+t) * 100.0) % 100;
                                      let sec = Math.floor(+t);
                                      let minutes = Math.floor(+sec / 60.0);
                                      sec = +sec - (+minutes) * 60;
                                      document.getElementById('hov_curr_bubble').innerText = `${`${minutes}`.padStart(2, '0')}:${`${sec}`.padStart(2, '0')}.${`${fracSec}`.padStart(2, '0')}`;
                                  } catch (e) {

                                  }
                              }}
                              onMouseLeave={evt => {
                                  try {
                                      document.getElementById('hov_time_vert').style.display = 'none';
                                  } catch (e) {

                                  }
                              }}
                              onMouseOver={evt => {

                              }}
                >
                </RulerOverlay>
                <HoverTimeHandle height={rulerHeight} id={'hov_time_vert'}>
                    <HoverTimeHandleInner>
                        <HoverTimeHandleBubble id={'hov_curr_bubble'}>

                        </HoverTimeHandleBubble>
                    </HoverTimeHandleInner>
                </HoverTimeHandle>
                <CurrentTimeHandle ref={currTimeRef} style={{height: stripeHeight + scrollHeight}}>
                    <CurrentTimeHandleInner>
                        <TickHandImg src={require('../images/pol_qw.svg')}/>
                    </CurrentTimeHandleInner>
                </CurrentTimeHandle>
                <RulerPlaceholder height={rulerHeight} width={realStripeWidth}>
                    {xGridItems.map((a, j) => {
                        let xOffset = +a;
                        let xOffsetPercent = (100.0 * xOffset / totalDuration / 1000.0);
                        return (
                            <React.Fragment key={`j_${a}`}>
                                {((j == xGridItems.length - 1 && hasRightTick == false) || (j == 0 && hasZeroTick == false)) ? null :
                                    <XTickItem left={xOffsetPercent} color={labelsColor}>
                                        {moment(a).format('mm:ss')}
                                    </XTickItem>
                                }
                            </React.Fragment>
                        )
                    })}
                </RulerPlaceholder>
                <AreaPlaceholder>

                    <AreaInner>
                        {boxes.map((box, i) => {
                            let isSelected = (selectedBoxId == box.id);
                            let duration = +box.to - +box.from;
                            return (
                                <React.Fragment key={`${box.id}_${i}`}>
                                    <Rnd
                                        className={`rnd-item rnd_${box.id}`}
                                        size={{
                                            width: 1.0 * duration * realStripeWidth / totalDuration,
                                            height: stripeHeight - 40 - rulerHeight
                                        }}
                                        position={{x: +box.from * realStripeWidth / totalDuration, y: 0}}
                                        selected={isSelected}
                                        enableResizing={{
                                            left: true,
                                            right: true,
                                            top: false,
                                            bottom: false,
                                            topLeft: false,
                                            topRight: false,
                                            bottomLeft: false,
                                            bottomRight: false
                                        }}
                                        resizeHandleClasses={{
                                            left: 'dr-left-bar',
                                            right: 'dr-right-bar',
                                        }}
                                        onResizeStart={() => {
                                            onStartChange();
                                        }}
                                        onResize={(e, direction, ref, delta, position) => {
                                            // console.log('position, ref = ', position, ref);
                                            let w = ref.offsetWidth;
                                            let newStart = SubtitlesHelper.round2((Math.max(0, +position.x) / realStripeWidth) * totalDuration);
                                            let newEnd = SubtitlesHelper.round2(newStart + (w * totalDuration / realStripeWidth));
                                            if (newEnd > +totalDuration || newStart < 0) {
                                                return;
                                            }
                                        }}
                                        onResizeStop={(e, direction, ref, delta, position) => {
                                            let w = ref.offsetWidth;
                                            let newStart = SubtitlesHelper.round2(((Math.max(0, +position.x) / realStripeWidth) * totalDuration));
                                            let newEnd = SubtitlesHelper.round2((newStart + (w * totalDuration / realStripeWidth)));
                                            if (newEnd > +totalDuration || newStart < 0) {
                                                return;
                                            }
                                            let newBoxes = boxes.map(bb => (bb.id == box.id ? {
                                                ...bb,
                                                from: newStart,
                                                to: newEnd
                                            } : {...bb}));
                                            subtitlesRef.current = newBoxes;
                                            onChange(newBoxes);
                                            onSeek(newStart, playing);
                                        }}
                                        onDragStart={() => {
                                            onStartChange();
                                        }}
                                        onDrag={(a, b, c) => {
                                            let newStart = SubtitlesHelper.round2((Math.max(0, +b.x) / realStripeWidth) * totalDuration);
                                            let newEnd = SubtitlesHelper.round2(+newStart + +duration);
                                            if (newEnd > +totalDuration) {
                                                return;
                                            }
                                        }}
                                        onDragStop={(a, b) => {
                                            let newStart = SubtitlesHelper.round2((Math.max(0, +b.x) / realStripeWidth) * totalDuration);
                                            let newEnd = SubtitlesHelper.round2(newStart + duration);
                                            if (newEnd > +totalDuration) {
                                                return;
                                            }
                                            let newBoxes = boxes.map(bb => (bb.id == box.id ? {
                                                ...bb,
                                                from: newStart,
                                                to: newEnd
                                            } : {...bb}));
                                            subtitlesRef.current = newBoxes;
                                            onChange(newBoxes);
                                            onSeek(newStart, playing);
                                        }}

                                    >
                                        <BoxInner id={`box_${box.id}`} className={'box-inner '} onClick={() => {
                                            setSelectedBoxId(box.id);
                                            onStartChange();
                                            onSeek(box.from);
                                        }}>

                                        </BoxInner>
                                    </Rnd>
                                </React.Fragment>
                            )
                        })}
                    </AreaInner>
                </AreaPlaceholder>


            </WrapperInner>

        </Wrapper>
    );
});

export default TimelineEditor;

const scrollHeight = 16;
// const scrollHeight = 0;

const Wrapper = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: #2C2C2E;
    overflow-x: auto;
    overflow-y: hidden;
    max-height: 100%;
    padding-bottom: ${0}px;
    box-sizing: border-box;
`;

const WrapperInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: ${props => props.width}px;
    max-width: ${props => props.width}px;
    overflow: hidden;
    position: relative;
`;


const RulerPlaceholder = styled.div`
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0,0,0,.3);
    position: relative;
`;

const RulerOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: ${props => props.height}px;
    z-index: 10;
    background: rgba(255, 255, 255, 0.03);
`;


const XTickItem = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: ${props => (props.left)}%;
    font-size: 12px;
    color: ${props => props.color};
`;

const AreaPlaceholder = styled.div`
    width: 100%;
    padding-top: 20px;
    //padding-bottom: 20px;
    position: relative;
    flex: 1;
    box-sizing: border-box;
    overflow-y: hidden;
    max-height: 100%;
`;

const CurrentTimeHandle = styled.div`
    width: 2px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 1000;
    left: 0px;
    background: rgba(255, 255, 255, 0.7);
`;

const HoverTimeHandle = styled.div`
    width: 2px;
    position: absolute;
    top: 0px;
    height: ${props => props.height}px;
    z-index: 9;
    left: 20px;
    background: rgba(255,172,48,0.75);
    display: none;
    ${WrapperInner}:hover &{
      //display: block !important;
    }
`;

const HoverTimeHandleInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const HoverTimeHandleBubble = styled.div`
    position: absolute;
    height: 15px;
    line-height: 15px;
    box-sizing: border-box;
    top: 31px;
    font-size: 13px;
    width: 60px;
    left: -30px;
    text-align: center;
    //color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //background: #FFAC30;
    color: #FFAC30;
    font-weight: bold;
    border-radius: 4px;
    z-index: 100000;
`;


const CurrentTimeHandleInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const TickHandImg = styled.img`
    height: 14px;
    width: 22px;
    position: absolute;
    top: -2px;
    left: -10.5px;
`;

const AreaInner = styled.div`
    width: ${props => props.width}px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
    max-height: 100%;
`;

const Box = styled.div`
    box-sizing: border-box;
    border: 1px solid ${props => props.selected ? '#1a90a8' : '#3a3a3c'};
    height: calc(100%);
    left: ${props => props.left}%;
    width: ${props => props.width}%;
    position: absolute;
    z-index: ${props => (props.zIndex == undefined ? 2 : props.zIndex)};
    cursor: move;
    border-radius: 2px;
    :hover{
      border-color: #1a90a8;
    }
`;

const BoxInner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid ${props => props.selected ? '#FFAC30' : '#3a3a3c'};
    background: rgba(20,20,56,0.90);
    color: white;
    font-size: 12px;
    padding-left: 10px;
    :hover{
      border-color: #FFAC30;
    }
`;

const LeftBoxHandle = styled.div`
    display: none;
    height: calc(100% + 8px);
    width: 8px;
    left: -4px;
    top: -4px;
    background: #3F435A;
    border-radius: 2px;
    position: absolute;
    z-index: 1000;
    cursor: ew-resize;
    ${BoxInner}:hover & {
      display: block;
    }
`;

