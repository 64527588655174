import * as firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyB8l831-_7qA6YatVQ7jzjpT_OeZPik-SQ",
    authDomain: "subtitles-love-b78f2.firebaseapp.com",
    databaseURL: "https://subtitles-love-b78f2.firebaseio.com",
    projectId: "subtitles-love-b78f2",
    storageBucket: "subtitles-love-b78f2.appspot.com",
    messagingSenderId: "948491145996",
    appId: "1:948491145996:web:7581913352b0064406ef29",
    measurementId: "G-H4K0D30ZD9"
};

firebase.initializeApp(firebaseConfig)

export default firebase;
