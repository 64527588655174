import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'
import {Input} from "../../ui/Input";

import {SketchPicker} from 'react-color';
import {Button} from "../../ui/Button";

const TYPES_OPTIONS = [
    {
        label: 'Number',
        value: 'number'
    },
    {
        label: 'Boolean',
        value: 'boolean'
    },
    {
        label: 'Color',
        value: 'color'
    },
];

const BOOL_OPTIONS = [
    {
        label: 'True',
        value: true
    },
    {
        label: 'False',
        value: false
    },

]

export default function UpdateParamForm(props) {

    const {
        loading = false,
        onSave = d => {

        },
        canDelete = false,
        onDelete = () => {

        }
    } = props;

    const [label, setLabel] = useState(props.label == undefined ? '' : props.label);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [type, setType] = useState(props.type == undefined ? 'number' : props.type);

    const [min, setMin] = useState(props.min == undefined ? 0 : props.min);
    const [max, setMax] = useState(props.max == undefined ? 100 : props.max);

    const [defaultValue, setDefaultValue] = useState(props.defaultValue);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Name
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    {'Label'}
                </Label>
                <Input value={label} onChange={evt => {
                    setLabel(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Тип
                </Label>
                <Select options={TYPES_OPTIONS}
                        value={TYPES_OPTIONS.filter(a => (a.value == type))[0]}
                        onChange={a => {
                            setType(a.value);
                        }}
                />
            </Field>

            {type != 'number' ? null :
                <div>
                    <Field>
                        <Label>
                            Min
                        </Label>
                        <Input value={min} onChange={evt => {
                            setMin(evt.target.value);
                        }}/>
                    </Field>
                    <Field>
                        <Label>
                            Max
                        </Label>
                        <Input value={max} onChange={evt => {
                            setMax(evt.target.value);
                        }}/>
                    </Field>
                </div>
            }

            <Field>
                <Label>
                    Default value
                </Label>
                {type != 'number' ? null :
                    <Input value={defaultValue} onChange={evt => {
                        setDefaultValue(evt.target.value);
                    }}/>
                }
                {type != 'boolean' ? null :
                    <Select options={BOOL_OPTIONS}
                            value={BOOL_OPTIONS.filter(a => (a.value == defaultValue))[0]}
                            onChange={a => {
                                setDefaultValue(a.value);
                            }}
                    />
                }

                {type != 'color' ? null :
                    <SketchPicker color={defaultValue} onChangeComplete={a => {
                        setDefaultValue(a.hex)
                    }}/>
                }

            </Field>

            <Field>
                <Button onClick={() => {
                    if (loading == true){
                        return;
                    }
                    onSave({
                        name,
                        label,
                        defaultValue,
                        min,
                        max,
                        type
                    });
                }} >
                    <Spin visible={loading}/>
                    Save
                </Button>
            </Field>

            {canDelete == false ? null :
                <Field style={{marginTop: 50}} >
                    <DeleteSpan onClick={() => {
                        onDelete();
                    }} >
                        Delete parameter
                    </DeleteSpan>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const DeleteSpan = styled.span`
    cursor: pointer;
    color: red;
    :hover{
      opacity: 0.7;
    }
`;
