import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/ArtsActions";
import {Button} from "../../ui/Button";
import UpdateArtForm from "../forms/UpdateArtForm";
import CommonHelper from "../../../helpers/CommonHelper";

export default function AllArtsPanel(props) {
    const {loading, arts, isAdmin, currentUserId} = useMappedState(useCallback(state => {
        return {
            loading: state.arts.loading,
            arts: state.arts.artsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, []));
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllArts());
    }, []);

    if (arts.length == 0 && loading == true) {
        return (<Code/>)
    }

    let selectedArt = (selectedId == undefined) ? undefined : arts.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddModalVisible(true);
                }}>
                    add new wave
                </Button>
            </TopPlaceholder>

            <ArtsListPlaceholder>

                {arts.map((a, j) => {
                    return (
                        <ArtItem key={`${j}_${a.id}`} onClick={() => {
                            // setSelectedId(a.id)
                            CommonHelper.linkTo(`/art/${a.id}`);
                        }}>
                            {a.name}
                        </ArtItem>
                    )
                })}

            </ArtsListPlaceholder>

            <Sidebar visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>
                {addModalVisible == false ? null :
                    <div>
                        <UpdateArtForm loading={loading} onSave={async d => {
                            let pld = await dispatch(actions.createArt(d));
                            setSelectedId(pld.art.id);
                        }}/>
                    </div>
                }
            </Sidebar>

            <Sidebar visible={(selectedArt != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                {selectedArt == undefined ? null :
                    <div>

                        <div style={{marginBottom: 20, textAlign: 'right'}}>
                            <span onClick={() => {
                                CommonHelper.linkTo(`/art/${selectedArt.id}`);
                            }}>
                                write art code
                            </span>
                        </div>

                        <UpdateArtForm {...selectedArt}
                                       loading={loading}
                                       onSave={async d => {
                                           await dispatch(actions.updateArt({id: selectedArt.id, ...d}));
                                           setSelectedId(undefined);
                                       }}/>
                    </div>
                }
            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ArtsListPlaceholder = styled.div`
    
`;

const ArtItem = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    padding: 5px;
    background: white;
    color: black;
    cursor: pointer;
    :hover{
      opacity: 0.6;
    }
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;
