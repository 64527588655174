import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import chImg from './gr_check.svg'
import securedImage from './secured_ssl.svg'
import paddleImage from './paddle.svg'

import './style.css'
import {useDispatch, useMappedState} from "redux-react-hook";
import PlansAPI from "../../../api/PlansAPI";
import moment from "moment";
import ls from "local-storage";
import {Analytics} from "../../../utils/AnalyticsUtility";
import AnalyticsAPI from "../../../api/AnalyticsAPI";

const ITEMS = [
    'Payment through a trusted payment service',
    'SSL Secure / 256-bit SSL secure checkout',
    'Advanced payment data encryption'
]

export default function PaddleCheckoutTool(props) {
    const {
        paddle_id = 633614
    } = props;
    const [loaded, setLoaded] = useState(false);
    const [sub, setSub] = useState(undefined);

    const {email, currentUserId} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = ((currentUser != undefined) && (currentUser.userRole == 'admin'));
        return {
            loading: state.users.loading,
            users: state.users.usersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            isAdmin: isAdmin,
            email: (currentUser == undefined) ? undefined : currentUser.email
        }
    }, []));

    useEffect(() => {
        PlansAPI.getAllPlans().then(arr => {
            let a = arr.filter(x => (x.paddle_id == paddle_id))[0];
            setSub(a);
        });
    }, [paddle_id]);

    useEffect(() => {
        try {
            window.Paddle.Checkout.open({
                loadCallback: () => {
                    setLoaded(true);
                },
                email: email,
                passthrough: currentUserId,
                success: `https://my.subtitles.love/#/payment_success/${paddle_id}`,

                method: 'inline',
                product: paddle_id,       // Replace with your Product or Plan ID
                allowQuantity: false,
                disableLogout: true,
                frameTarget: 'checkout-container',
                frameInitialHeight: 416,
                frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;'    // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
            });
        } catch (exc) {

        }
    }, [paddle_id]);

    console.log('PaddleCheckoutTool: paddle_id, email, currentUserId = ', paddle_id, email, currentUserId);
    console.log('render: sub = ', sub);
    let durLabel = (sub == undefined) ? '' : (sub.name.indexOf('year') > -1 ? 'year' : 'month');

    return (
        <Wrapper>

            <Half>
                <TopHeading>
                    <div>
                        {sub == undefined ? null : `${sub.label} plan`}
                    </div>
                    {sub == undefined ? null :
                        <div>
                            {`${sub.price}$`}
                            <span style={{fontSize: 16}} >
                                {`/${durLabel}`}
                            </span>
                        </div>
                    }

                </TopHeading>

                <ItemsList>
                    {ITEMS.map((x, i) => {
                        return (
                            <Item key={i}>
                                <ItLeft src={chImg}/>
                                <ItRight>
                                    {x}
                                </ItRight>
                            </Item>
                        )
                    })}
                </ItemsList>

                <SubHeading>
                    We make sure your data is safe
                </SubHeading>

                <P>
                    Your payment data is encrypted and secure. We do not store or share it and we use the best payment
                    providers available.
                </P>

                <SubHeading>
                    How can I cancel?
                </SubHeading>

                <P>
                    If you want to cancel your plan, simply go to your profile and cancel in the account page.
                </P>

                <BottomPlaceholder>
                    <SecuredImg src={securedImage}/>
                    <SecuredImg src={paddleImage}/>
                </BottomPlaceholder>

            </Half>

            <Half>
                <PaddleWidgetPlaceholder>
                    {loaded == true ? null :
                        <div style={{color: 'white', padding: 20}}>
                            loading...
                        </div>
                    }
                    <div className="checkout-container"></div>
                </PaddleWidgetPlaceholder>
            </Half>

        </Wrapper>
    );
}

const SecuredImg = styled.img`
  height: 42px;
  margin-right: 30px;
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubHeading = styled.div`
  color: white;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const P = styled.div`
  color: #ECECEC;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
`;


const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const ItLeft = styled.img`
  margin-right: 10px;
  height: 15px;
`;

const ItRight = styled.div`
  color: #ECECEC;
`;

const ItemsList = styled.div`
  margin-bottom: 30px;
`;

const TopHeading = styled.div`
  color: white;
  font-size: 32px;
  line-height: 46px;
  font-weight: bold;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

const PaddleWidgetPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 1020px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 20px;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const Half = styled.div`
  flex: 1;

  :first-of-type {
    padding-right: 30px;
  }

  :last-of-type {
    padding-left: 10px;
  }
`;