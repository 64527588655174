import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/ArtsActions";
import {Button} from "../../ui/Button";
import UpdateArtForm from "../forms/UpdateArtForm";
import CommonHelper from "../../../helpers/CommonHelper";
import SimpleWaveEditor from "../../wave/tools/SimpleWaveEditor";

export default function UpdateArtCodePanel(props) {
    const {id} = props;
    const {loading, art, isAdmin, currentUserId} = useMappedState(useCallback(state => {
        return {
            loading: state.arts.loading,
            art: state.arts.artsMap.get(id),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllArts());
    }, []);

    if (art == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <EditorPlaceholder>
                <SimpleWaveEditor {...art}
                                  loading={loading}
                                  onSave={async d => {
                                      await dispatch(actions.updateArt({id: id, ...d}));
                                  }}
                                  onDelete={async () => {
                                      await dispatch(actions.deleteArt(id));
                                      CommonHelper.linkTo(`/arts`);
                                  }}
                                  onDuplicate={async () => {
                                      let d = JSON.parse(JSON.stringify({...art}));
                                      delete d.id;
                                      d.name = `${d.name} (Copy)`;
                                      let pld = await dispatch(actions.createArt(d));
                                      CommonHelper.linkTo(`/art/${pld.art.id}`);
                                  }}
                />
            </EditorPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const EditorPlaceholder = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 1;
    background: white;
`;
