import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleProgressbar from "../progress/SimpleProgressbar";
import ReactHelper from "../../helpers/ReactHelper";
import SubtitlesHelper from "../../helpers/SubtitlesHelper";

export default function FakeProgressBar(props) {
    const {duration = 300} = props;
    const nowRef = useRef(+new Date());
    const [progress, setProgress] = useState(0);
    // const prevNameRef = ``;

    useEffect(() => {
        nowRef.current = +new Date();
    }, []);

    ReactHelper.useInterval(() => {
        try {
            let dt = +new Date() - nowRef.current;
            setProgress(SubtitlesHelper.getFakeProgressPercents(dt / 1000.0, duration));
        } catch (e) {

        }
    }, 100);

    return (
        <Wrapper>
            <SimpleProgressbar progress={progress}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
