import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';
import axios from 'axios'

import WaveTemplateApp from './WaveTemplateApp'
import UserWaveTemplatesApp from './UserWaveTemplatesApp'
import DevApp from './DevApp'
import AdminArtsApp from './AdminArtsApp'
import AdminServersApp from './AdminServersApp'
import AdminSubscriptionsApp from './AdminSubscriptionsApp'
import AdminIndexApp from './AdminIndexApp'
import AdminUsersApp from './AdminUsersApp'
import UserIndexApp from "./UserIndexApp";
import UserSettingsApp from "./UserSettingsApp";
import UserBillingApp from "./UserBillingApp";
import ProjectApp from "./ProjectApp";
import ProjectViewApp from "./ProjectViewApp";
import ArtApp from "./ArtApp";

import RecoverPasswordApp from "./RecoverPasswordApp";
import PaymentSuccessApp from "./PaymentSuccessApp";
import AdminConfigsApp from "./AdminConfigsApp";

import MobileHelpApp from './MobileHelpApp'

import FullPagePreloader from '../preloader/FullPagePreloader'

import * as waveTemplatesActions from '../../redux/actions/WaveTemplatesActions'
import * as artsActions from '../../redux/actions/ArtsActions'
import * as usersActions from '../../redux/actions/UsersActions'
import * as jobsActions from '../../redux/actions/JobsActions'
import * as projectsActions from '../../redux/actions/ProjectsActions'
import * as plansActions from '../../redux/actions/PlansActions'
import LoginApp from "./LoginApp";
import UserPricingApp from "./UserPricingApp";
import UserHelpApp from "./UserHelpApp";
import UserProfileApp from "./UserProfileApp";
import {API_ENDPOINT} from "../../constants/config";
import FacebookPlugin from "../ui/FacebookPlugin";
import AnalyticsAPI from "../../api/AnalyticsAPI";
import BuyApp from "./BuyApp";


const adminRoute = (
    <Switch>
        <Route exact path='/arts' component={AdminArtsApp}/>
        <Route exact path='/art/:id' component={ArtApp}/>

        <Route exact path='/wave_template/:id' component={WaveTemplateApp}/>
        <Route exact path='/wave_templates' component={UserWaveTemplatesApp}/>

        <Route exact path='/subscriptions' component={AdminSubscriptionsApp}/>
        <Route exact path='/users' component={AdminUsersApp}/>
        <Route exact path='/servers' component={AdminServersApp}/>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/payment_success/:paddle_id' component={PaymentSuccessApp}/>

        <Route exact path='/project/:id' component={ProjectApp}/>
        <Route exact path='/project/:id/view' component={ProjectViewApp}/>

        <Route exact path='/buy/:paddle_id' component={BuyApp}/>

        <Route exact path='/profile' component={UserProfileApp}/>
        <Route exact path='/configs' component={AdminConfigsApp}/>

        <Route component={AdminIndexApp}/>

    </Switch>
);


const userRoute = (
    <Switch>
        <Route exact path='/arts' component={AdminArtsApp}/>
        <Route exact path='/art/:id' component={ArtApp}/>

        <Route exact path='/payment_success/:paddle_id' component={PaymentSuccessApp}/>
        <Route exact path='/buy/:paddle_id' component={BuyApp}/>

        <Route exact path='/wave_template/:id' component={WaveTemplateApp}/>
        <Route exact path='/wave_templates' component={UserWaveTemplatesApp}/>

        <Route exact path='/help' component={UserHelpApp}/>
        <Route exact path='/profile' component={UserProfileApp}/>
        <Route exact path='/pricing' component={UserPricingApp}/>
        <Route exact path='/billing' component={UserBillingApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/project/:id' component={ProjectApp}/>
        <Route exact path='/project/:id/view' component={ProjectViewApp}/>
        <Route exact path='/settings' component={UserSettingsApp}/>
        <Route component={UserIndexApp}/>
    </Switch>
);

const doctorRoute = (
    <Switch>
        <Route component={UserIndexApp}/>
    </Switch>
);

const guestRoute = (
    <>
        <Switch>
            <Route exact path='/dev' component={LoginApp}/>
            <Route exact path='/support' component={LoginApp}/>
            <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
            <Route exact path='/mobile_help' component={MobileHelpApp}/>
            <Route component={LoginApp}/>
        </Switch>
        {/* <FacebookPlugin/> */}
    </>
);


function RouterApp(props) {
    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser} = useMappedState(mapState);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => {
            if (pld.user != undefined) {
                window.currentUserId = pld.user.id;
                console.log('==>>> pld = ', pld);
                AnalyticsAPI.mixpanelIdentifyUser(pld.user);
                if (pld.uploadLimits != undefined) {
                    window.uploadLimits = pld.uploadLimits;
                }
                if (pld != undefined && pld.subscriptionIsActive != true) {
                    window.IS_FREE_USER = true;
                }
                if (pld != undefined) {
                    window.CURRENT_PLAN = pld.plan;
                    if (pld.plan != undefined) {
                        window.CURRENT_PLAN_PADDLE_ID = pld.plan.paddle_id;
                    }
                }

                let event_time = pld.subscriptionDetails == undefined ? undefined : pld.subscriptionDetails?.timestamp;
                // let startTimestamp = (event_time == undefined) ? undefined : +moment(event_time, 'YYYY-MM-DD HH:mm:ss');

                // subscriptionDetails
                dispatch(plansActions.loadAllPlans());
                dispatch(jobsActions.getUserJobs(pld.user.id));
                dispatch(plansActions.setActivePlan(pld.activePlanId, pld.activePlanExpirationTimestamp, event_time))
            }
        });
    }, []);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/upload/url`).then(d => d.data.result).then(url => {
            window.VIDEO_UPLOAD_PREFIX = url;
            console.log('window.VIDEO_UPLOAD_PREFIX = ', window.VIDEO_UPLOAD_PREFIX);
        });
    }, []);

    if (initialized == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }

    let route = guestRoute;
    let isGuest = (currentUser == undefined);
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');

    if (isGuest == false) {
        route = isAdmin == true ? adminRoute : userRoute;
    }


    return (
        <HashRouter>
            <Wrapper>
                {route}
            </Wrapper>
        </HashRouter>
    );
}

const Wrapper = styled.div`
  font-family: "Avenir Next Cyr";
`;

export default (RouterApp)
