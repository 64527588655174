import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const ProjectsAPI = {

    // async updateProjectSubtitles(id, subtitles = []) {
    //     let pld = (await axios.post(`${API_ENDPOINT}/project/update_subtitles`, {id: id, subtitles: subtitles})).data;
    //     if (pld.error != undefined) {
    //         throw pld.error
    //     }
    //     return pld.result;
    // },

    async getProjectSubtitles(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}/subtitles`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        let subs = (pld.result.subtitles == undefined) ? [] : pld.result.subtitles;
        return subs;
    },

    async getProjectOriginalSubtitles(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}/subtitles/original`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        let subs = (pld.result.subtitles == undefined) ? [] : pld.result.subtitles;
        return subs;
    },

    async updateProjectSubtitles(id, subtitles = []) {
        let pld = (await axios.put(`${API_ENDPOINT}/project/${id}/subtitles`, {
            subtitles: subtitles
        })).data;
        if (pld.error != undefined) {
            throw pld.error
        }
    },

    async translateProject(id, lang) {
        let pld = (await axios.post(`${API_ENDPOINT}/project/${id}/translate/${lang}`)).data;
        return pld;
    },

    async revertTranslation(id, lang) {
        let pld = (await axios.post(`${API_ENDPOINT}/project/${id}/translate-revert`)).data;
        return pld;
    },

    async getProject(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        console.log('getProject: pld = ', pld);

        return {
            ...pld.result.project
            // subtitles: pld.result.project.subtitles.map((v, i) => {
            //     return {
            //         ...v,
            //         words: (pld.result.awsSubtitles == undefined ? [] : pld.result.awsSubtitles[i].words)
            //     }
            // })
        };
    },

    async getProjectAwsSubtitles(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return (pld.result.awsSubtitles == undefined ? [] : pld.result.awsSubtitles);
    },

    async getAllProjects() {
        let pld = (await axios.get(`${API_ENDPOINT}/projects/all`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getUserProjects(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/projects`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateBalance(data) {
        let pld = (await axios.get(`${API_ENDPOINT}/project/balance`, {})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


    async createProject(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/project`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async cloneProject(oldProjectId) {
        let pld = (await axios.post(`${API_ENDPOINT}/project/${oldProjectId}/clone`,)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateProject(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/project`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async deleteProject(id) {
        let pld = (await axios.post(`${API_ENDPOINT}/project/delete`, {id: id})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


}

export default ProjectsAPI;
