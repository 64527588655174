import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ProjectsAPI from "../../../api/ProjectsAPI";

export default function UpdateTranslatedSubtitlesTool(props) {
    const {
        id,
        onRevert = () => {

        }
    } = props;


    const [loading, setLoading] = useState(false);
    const [oldSubs, setOldSubs] = useState([]);
    const [newSubs, setNewSubs] = useState([]);

    const [saving, setSaving] = useState(false);

    const [oldSubsMap, setOldSubsMap] = useState(Map());

    useEffect(() => {
        setLoading(true);
        ProjectsAPI.getProjectSubtitles(id).then(arr => {
            setNewSubs(arr);
            ProjectsAPI.getProjectOriginalSubtitles(id).then(arr2 => {
                setOldSubs(arr2);
                setOldSubsMap(arr2.reduce((mp, st) => mp.set(st.id, st), Map()));
                setLoading(false);
            })
        })
    }, [id]);

    if (loading == true && newSubs.length == 0) {
        return (
            <Code/>
        )
    }


    return (
        <Wrapper>

            <TopPlaceholder>

            </TopPlaceholder>

            <ContentPlaceholder>

                <ListPlaceholder>
                    {newSubs.map((x, i) => {
                        let oldSub = oldSubsMap.get(x.id);
                        let oldText = (oldSub == undefined) ? '' : oldSub.text;
                        return (
                            <ListItem key={x.id}>
                                <Half>
                                    <OldText>
                                        {oldText}
                                    </OldText>
                                </Half>
                                <Half>
                                    <Textarea value={x.text} onChange={evt => {
                                        let nSubs = newSubs.map(a => (a.id == x.id ? ({
                                            ...a,
                                            text: evt.target.value
                                        }) : a));
                                        setNewSubs(nSubs);
                                    }}/>
                                </Half>
                            </ListItem>
                        )
                    })}
                </ListPlaceholder>

            </ContentPlaceholder>

            <BottomPlaceholder>
                <BackButton onClick={async () => {
                    if (loading == true) {
                        return;
                    }
                    setLoading(true);
                    await ProjectsAPI.revertTranslation(id);
                    await timeout(1500);
                    setLoading(false);
                    onRevert();
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null : 'Back to original'}
                </BackButton>

                <SaveButton onClick={async () => {
                    setSaving(true);
                    await ProjectsAPI.updateProjectSubtitles(id, newSubs);
                    setSaving(false);
                    onRevert();
                }}>
                    <Spin visible={saving} theme={'alfa-on-color'}/>
                    {saving == true ? null : 'Save translation'}
                </SaveButton>

            </BottomPlaceholder>

        </Wrapper>
    );
}

const ListPlaceholder = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

const OldText = styled.div`

`;

const Textarea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  padding: 7px;
  background: #212330;
  border: 2px solid rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  color: white;
  font-size: 16px;

  :focus {
    border: 2px solid #FFAC30;
    outline: none;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  :last-of-type {
    margin-bottom: 0px;
  }
`;

const Half = styled.div`
  flex: 1;

  :first-of-type {
    padding-right: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.070613);;
  }

  :last-of-type {
    padding-left: 15px;
  }
`;

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`

`;

const ContentPlaceholder = styled.div`
  color: white;
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const BackButton = styled.div`
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  background: #171822;
  color: white;
  font-weight: bold;
  font-size: 16px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
`;

const SaveButton = styled.div`
  background: #EB5E4F;
  color: white;
  border-radius: 5px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;

  :hover {
    opacity: 0.8;
  }
`;

const timeout = ms => new Promise(res => setTimeout(res, ms));