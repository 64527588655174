import sanitizeHtml from "sanitize-html";

function ballanceTimeLine(startIndex, subtitles) {
    const timeMultiplier = 2;
    let s = subtitles.map((value, index) => {
        if(index <= startIndex) {
            return value;
        } else {
            return {
                ...value,
                from: subtitles[index - 1].to + timeMultiplier,
                to: value.to + timeMultiplier
            }
        }
    });
}

function buildNewFrame(subtitles, startIndex, data) {
    return {
        text: data,
        from: subtitles[startIndex].to,
        to: subtitles[startIndex].to + 1,
    }
}

function fixSlicedCaptionTiming(caption, text) {
    const captionCopy = {...caption};
    const currentWords = captionCopy.text.split(' ');

    captionCopy.to = captionCopy.words[ currentWords.length - 1].end;
    captionCopy.words = captionCopy.words.slice(0, currentWords.length);

    const newCaption = {...caption};
    newCaption.text = text.trim();
    newCaption.from = captionCopy.to;
    newCaption.to = caption.words[ caption.words.length - 1].end;
    newCaption.words = newCaption.words.slice( captionCopy.words.length, newCaption.words.length - 1);

    return {
        partOne: captionCopy,
        partTwo: newCaption
    };
}

export class SubtitlesService {
    static cleanSubtitles(data) {
        return sanitizeHtml(data);
    }

    static moveSelectedToNewFrame(subtitlesList, currentIndex, data=null) {
        subtitlesList[currentIndex].text = subtitlesList[currentIndex].text.replace(data, '').trim();
        const fixedCaption = fixSlicedCaptionTiming(subtitlesList[currentIndex], data);
        const after = subtitlesList.splice(currentIndex + 1);
        subtitlesList[currentIndex] = fixedCaption.partOne;
        subtitlesList.push(fixedCaption.partTwo);
        subtitlesList = subtitlesList.concat(after);
        return subtitlesList;
    }


    static getSelectedText() {
        const text = window.getSelection().toString();
        return text === '' ? null : text;
    }
}
