import moment from 'moment'

const DrawHelper = {

    getFineGridsForDay(dayTimestamp) {
        let steps = [
            1 * 1000, // 1 sec
            2 * 1000,
            5 * 1000,
            10 * 1000,
            20 * 1000,
            30 * 1000,
            60 * 1000, // 1 min
            2 * 60 * 1000,
            4 * 60 * 1000,
            5 * 60 * 1000,
            10 * 60 * 1000,
            15 * 60 * 1000,
            20 * 60 * 1000,
            30 * 60 * 1000,
            60 * 60 * 1000,
            2 * 60 * 60 * 1000,
            3 * 60 * 60 * 1000,
            4 * 60 * 60 * 1000,
            6 * 60 * 60 * 1000,
        ];
        let from = +moment(dayTimestamp).startOf('day');
        let getArr = (step = steps[0], totalDur = 24 * 60 * 60 * 1000) => {
            let n = Math.floor(totalDur / step);
            let arr = [];
            for (let i = 0; i < n; i++) {
                arr.push(+from + i * step);
            }
            return arr;
        };
        return steps.map(a => getArr(a)).reverse();
    },

    getGridForTimeSpan(from, to, maxN = 10) {
        // console.log('getGridForTimeSpan: from, to = ', new Date(from), new Date(to));
        let grids = this.getFineGridsForDay(from).map(a => a.filter(x => (+x >= +from && +x <= +to)));
        // console.log('getGridForTimeSpan: grids = ', grids);
        let getIn = (arr, a, b) => arr.reduce((sm, x) => (+x >= +a && +x <= +b ? (+sm + 1) : +sm), 0);
        let scores = grids.map(a => getIn(a, from, to));
        // console.log('scores = ', scores);
        // console.log('maxN = ', maxN);
        let resIndex = grids.length - 1;
        for (let i in scores) {
            let sc = scores[i];
            if (sc <= maxN) {
                resIndex = i;
                // break;
            }
        }
        // console.log('resIndex = ', resIndex);
        return grids[resIndex].filter(a => (+a >= +from && +a <= +to));
    }


}

export default DrawHelper;
