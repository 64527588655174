import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
    forwardRef,
    useImperativeHandle
} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SubtitlesHelper from "../../../helpers/SubtitlesHelper";

import './textboxes.css'

let t;
const debounce = (value, fn) => {
    clearTimeout(t)
    t = setTimeout(() => fn(value), 650)
};

const SubsTextsEditor = forwardRef((props, ref) => {
    const {
        totalDuration = 10,
        subtitles = [],
        expanded = false,

        onChange = newSubs => {

        },
        onSeek = t => {

        },
        onPause = () => {

        },

        onExpand = () => {

        },

        onBoxSelect = () => {

        }

    } = props;

    const subsRef = useRef(subtitles);
    const [updT, setUpdT] = useState(0);

    useImperativeHandle(ref, () => ({

        setSubtitles(newSubs) {
            subsRef.current = newSubs;
            try {
                for (let i in newSubs) {
                    let ss = newSubs[i];
                    try {
                        document.getElementById(`area_${ss.id}`).value = ss.text;
                    } catch (ee) {

                    }
                }
            } catch (ee) {

            }
            setUpdT(+new Date());
        }
    }));

    const subs = subsRef.current.sort((a, b) => (+a.from - +b.from));

    return (
        <Wrapper>


            {subs.map((a, i) => {
                let nextSub = (i == subsRef.current.length - 1) ? undefined : subsRef.current[+i + 1];
                let nextFrom = (nextSub == undefined) ? totalDuration : nextSub.from;
                let dist = +nextFrom - +a.to;
                let canAddSubtitle = (+dist > 1.0);
                let newFrom = +a.to + 0.1;
                let newTo = newFrom + 2.0; // 2 seconds for new subtitle

                return (
                    <Item key={`sub_${a.id}_${i}`} id={`text_box_${a.id}`} className={'sub-left-text-box'}
                          onClick={() => {
                              console.log('onFocus!');
                              onSeek(a.from);
                              onBoxSelect(a.id);
                          }}>
                        <Left>
                            <LeftItem>
                                {SubtitlesHelper.toHHMMSS(a.from)}
                            </LeftItem>
                            <LeftItem>
                                {SubtitlesHelper.toHHMMSS(a.to)}
                            </LeftItem>
                        </Left>
                        <Right>
                            <Textarea defaultValue={a.text}
                                      id={`area_${a.id}`}
                                      onChange={evt => {
                                          clearTimeout(t);
                                          var s = evt.target.value;

                                          debounce(evt.target.value, (value) => {
                                              let newSubs = subsRef.current.map((b, j) => (a.id == b.id ? {
                                                  ...b,
                                                  text: value
                                              } : b));
                                              subsRef.current = newSubs;
                                              onChange(newSubs);
                                              onPause();
                                              setUpdT(+new Date());
                                          })

                                      }}
                                      onFocus={() => {
                                          // onBoxSelect(a.id);
                                          // try {
                                          //     document.getElementById(`box_${a.id}`).scrollIntoView({
                                          //         behavior: 'smooth',
                                          //         inline: 'center'
                                          //     })
                                          // } catch (e) {
                                          //
                                          // }
                                      }}

                            />
                        </Right>
                        <VeryRight key={`${a.id}_${updT}`}>
                            <ContImg src={require('../images/remove_ic.svg')} onClick={() => {
                                let newSubs = subs.filter((b, x) => (b.id != a.id));
                                subsRef.current = newSubs;
                                onChange(newSubs);
                                onPause();
                                setUpdT(+new Date());
                            }}/>
                            {canAddSubtitle == false ? null :
                                <ContImg src={require('../images/plus_ic.svg')} onClick={() => {
                                    let leftSubs = subsRef.current.slice(0, +i + 1);
                                    let rightSubs = subsRef.current.slice(+i + 1);
                                    let newSub = {
                                        id: uuid(),
                                        from: newFrom,
                                        to: newTo,
                                        text: ''
                                    };
                                    let newSubs = leftSubs.concat([newSub]).concat(rightSubs);
                                    subsRef.current = newSubs;
                                    onChange(newSubs);
                                    onPause();
                                    setUpdT(+new Date());
                                    setTimeout(() => {
                                        try {
                                            document.getElementById(`area_${newSub.id}`).focus();
                                            onSeek(newSub.from);
                                        } catch (e) {

                                        }
                                    }, 100);
                                }}/>
                            }
                        </VeryRight>
                    </Item>
                )
            })}

            {subs.length > 0 ? null :
                <AddPlaceholder>
                    <AddButton onClick={() => {
                        let lastSub = (subs.length == 0) ? undefined : subs[subs.length - 1];
                        let from = (lastSub == undefined) ? 0 : (+lastSub.to + 0.1);
                        let to = Math.min(totalDuration, +from + 2);
                        let newSubs = subs.concat({id: uuid(), text: '', from: from, to: to});
                        onChange(newSubs);
                        subsRef.current = newSubs;
                        setUpdT(+new Date());
                    }}>
                        add subtitle
                    </AddButton>
                </AddPlaceholder>
            }


        </Wrapper>
    );
});

export default SubsTextsEditor;

const AddPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const AddButton = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: white;
    opacity: 0.7;
    :hover{
      opacity: 1;
    }
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const LinkSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    opacity: 0.5;
    color: white;
    cursor: pointer;
    margin-right: 15px;
    :hover{
      opacity: 1;  
    }
`;

const LinkImage = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 5px;
`;

const LinkLabel = styled.div`
    color: white;
`;

const Wrapper = styled.div`
    box-sizing: border-box;
`;

const Item = styled.div`
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    height: 100px;
    border-bottom: 1px solid #3a3a3c;
    border-left: 3px solid transparent;
    :hover{
      background: #3F435A;
      border-left-color: #FFAC30;
    }
`;

const Left = styled.div`
    width: 80px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
`;

const LeftItem = styled.div`
    color: white;
    box-sizing: border-box;
    padding: 3px;
    text-align: center;
    font-size: 12px;
`;

const LeftDeleteItem = styled(LeftItem)`
    cursor: pointer;
    color: red;
    font-size: 10px;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;

const Right = styled.div`
    flex: 1;
    box-sizing: border-box;
`;

const VeryRight = styled.div`
    width: 30px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
`;

const Textarea = styled.textarea`
    outline: none;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    border: 1px solid #3a3a3c;
    background: transparent;
    color: white;
    box-sizing: border-box;
    font-size: 14px;
    padding: 3px;
    border-radius: 5px;
    :focus{
      outline: none;
      border-color: #FFAC30;
      background: #212330;
    }
`;

const ContImg = styled.img`
    height: 16px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    opacity: 0.5;
    :hover{
      opacity: 0.9;
    }
`;
