import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import moment from 'moment'

import * as projectsActions from '../../../redux/actions/ProjectsActions'
import * as plansActions from '../../../redux/actions/PlansActions'

import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import {useDispatch, useMappedState} from "redux-react-hook";
import StateHelper from "../../../helpers/StateHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import {Analytics} from "../../../utils/AnalyticsUtility";

export default function UserPlansStatsPanel(props) {
    const {balance} = useMappedState(useCallback(state => {
        let allJobs = state.jobs.jobsMap.toArray();
        let getProjectJobs = prId => allJobs.filter(a => (a.projectId == prId));
        let {currentUserId} = state.users;
        return {
            balance: state.users.balance
        }
    }, []));
    const dispatch = useDispatch();

    return (
        <Wrapper>
            {balance == 0 ? null :
                <LeftSection>
                    <NumSpan>
                        {balance}
                    </NumSpan>
                    {` videos left`}
                </LeftSection>
            }

            {/*{leftNumber > 0 ? null :*/}
            {/*<UpdateButton onClick={() => {*/}
            {/*Analytics.send('billing.plan.update', 'billing');*/}
            {/*CommonHelper.linkTo(`/pricing`);*/}

            {/*}}>*/}
            {/*{`Update plan`}*/}
            {/*</UpdateButton>*/}
            {/*}*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    color: white;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
`;

const LeftSection = styled.div`
    color: white;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
`;

const NumSpan = styled.span`
    color: #FFAC30;
    margin-right: 5px;
    font-weight: bold;
`;

const UpdateButton = styled.div`
    background: #EB5E4F;
    border-radius: 5px;
    padding-left: 34px;
    padding-right: 34px;
    color: white;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 26px;
    :hover{
      opacity: 0.8;
    }
`;
