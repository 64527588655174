import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import WaveHelper from "../../../helpers/WaveHelper";

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

export default function UpdateArtForm(props) {
    const {
        loading = false,
        onSave = d => {

        },
        onDelete = () => {

        },
        canDelete = false,
        canDuplicate = false,
        onDuplicate = () => {

        }
    } = props;
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [code, setCode] = useState(props.code == undefined ? WaveHelper.deafultWaveCode : props.code);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Name
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Description
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            name,
                            description
                        })
                    }}>
                        Save
                    </Button>
                }
            </Field>

            <RowField style={{marginTop: 50}}>
                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        onDelete();
                    }}>
                        Delete
                    </DeleteSpan>
                }

                {canDuplicate == false ? null :
                    <DuplicateSpan onClick={() => {
                        onDuplicate();
                    }}>
                        Duplicate
                    </DuplicateSpan>
                }

            </RowField>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const RowField = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const DeleteSpan = styled.span`
    cursor: pointer;
    color: red;
    :hover{
      opacity: 0.7;
    }
`;

const DuplicateSpan = styled.span`
    cursor: pointer;
    color: blue;
    :hover{
      opacity: 0.7;
    }
`;

