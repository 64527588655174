import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const PROPORTIONS = [
    {
        label: '1:1',
        k: 1.0 / 1.0
    },
    {
        label: '5:4',
        k: 5.0 / 4.0
    },
    {
        label: '4:5',
        k: 4.0 / 5.0
    },
    {
        label: '9:16',
        k: 9.0 / 16.0
    },
    {
        label: '16:9',
        k: 16.0 / 9.0
    }
];

export default function ProportionsSelector(props) {
    const {
        selectedProportion, onChange = x => {

        }
    } = props;


    return (
        <Wrapper>

            {PROPORTIONS.map((a, j) => {
                let isSelected = (selectedProportion == a.k);
                return (
                    <SlideItem selected={isSelected} key={j} onClick={() => {
                        onChange(a.k);
                    }}>
                        <SlideLabel selected={isSelected}>
                            {a.label}
                        </SlideLabel>
                    </SlideItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


const SlideItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 35px;
    margin-right: 35px;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    width: 120px;
    background: ${props => (props.selected == true ? 'rgba(211,211,211,0.5)' : 'transparent')};
    :hover{
      opacity: 0.8;
      background: rgba(211,211,211,0.5);
    }
`;


const SlideLabel = styled.div`
    text-align: center;
    font-size: 22px;
    padding: 10px;
`;
