import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import Spin from 'arui-feather/spin'

export default function RecoverPasswordForm(props) {
    const {
        onSubmit = () => {

        },
        onSignUpClick = () => {
        },
        onLoginClick = () => {
        }, loading = false
    } = props;
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);

    return (
        <Wrapper>

            <TopPlaceholder>
                Recover password
            </TopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Label>
                        Email
                    </Label>
                    <Input value={email} type={'email'} placeholder={'Your E-Mail'} onChange={e => {
                        setEmail(e.target.value);
                    }}/>
                </Field>
                <Field>
                    <Button onClick={() => {
                        onSubmit(email)
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Recover'
                        }

                    </Button>
                </Field>

                <BottomField>
                    <BackSpan onClick={() => {
                        onLoginClick();
                    }}>
                        Back
                    </BackSpan>
                </BottomField>

            </ContentPlaceholder>

        </Wrapper>
    );
}

const Label = styled.div`
    font-size: 16px;
    line-height: 154.3%;
    color: #7B7F9E;
    margin-bottom: 10px;
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
`;

const BackSpan = styled.span`
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF;
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Wrapper = styled.div`
    //width: 100%;
    //height: 100%;
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
    @media(max-width: 720px){
      width: 100vw;
    }
`;

const TopPlaceholder = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
`;

const ContentPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;

const LogoPlaceholder = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
`;

const Logo = styled.img`
    display: inline-block;
    width: 80%;
`;

const Input = styled.input`
    background: #212330;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    height: 50px;
    width: 100%;
    padding-left: 15px;
    
    font-weight: bold;
    
    font-size: 17px;
    line-height: 23px;
    
    color: #FFFFFF;
    
    :focus{
      border: 2px solid rgba(255, 255, 255, 0.03);
    }
`;

const Button = styled.div`
    background: #EA523D;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: pointer;
    
    :hover{
      opacity: 0.9;
    }
`;
