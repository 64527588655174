import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/WaveTemplatesActions";
import * as artsActions from "../../../redux/actions/ArtsActions";
import WaveTemplateItemView from "../views/WaveTemplateItemView";
import CoolModal from "../../modals/CoolModal";
import UpdateProjectNameForm from "../../workspace/forms/UpdateProjectNameForm";
import CommonHelper from "../../../helpers/CommonHelper";

export default function UserWaveTemplatesPanel(props) {
    const {userId} = props;

    const {loading, uId, servers, isAdmin, currentUserId, waveTemplates} = useMappedState(useCallback(state => {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        return {
            uId: uId,
            loading: state.waveTemplates.loading,
            waveTemplates: state.waveTemplates.waveTemplatesMap.toArray().filter(a => (a.userId == uId)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId
        }
    }, []));
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadUserAllWaveTemplates(uId));
        dispatch(artsActions.loadAllArts());
    }, []);

    let selectedWaveTemplate = (selectedId == undefined) ? undefined : waveTemplates.filter(a => (a.id == selectedId))[0];


    return (
        <Wrapper>

            <TopPlaceholder>
                <TopName>
                    Wave templates
                </TopName>
                <TopRight>
                    <Button onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        Add template
                    </Button>
                </TopRight>
            </TopPlaceholder>

            <Content>
                {waveTemplates.map((w, j) => {
                    return (
                        <InlineBox key={`w_${w.id}_${j}`}>
                            <WaveTemplateItemView {...w} onRename={() => {
                                setSelectedId(w.id);
                            }} onEdit={() => {
                                CommonHelper.linkTo(`/wave_template/${w.id}`);
                            }}
                                                  onGo={() => {
                                                      CommonHelper.linkTo(`/wave_template/${w.id}`);
                                                  }}
                            />
                        </InlineBox>

                    )
                })}
            </Content>

            {addModalVisible == false ? null :
                <CoolModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <UpdateProjectNameForm
                        heading={'Template name'}
                        loading={loading} onSave={async d => {
                        await dispatch(actions.createWaveTemplate({...d, userId: uId}));
                        setAddModalVisible(false);
                    }}/>

                </CoolModal>
            }

            {selectedWaveTemplate == undefined ? null :
                <CoolModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <UpdateProjectNameForm
                        heading={'Template name'}
                        {...selectedWaveTemplate} loading={loading} onSave={async d => {
                        await dispatch(actions.updateWaveTemplate({id: selectedId, ...d}));
                        setSelectedId(undefined);
                    }}/>

                </CoolModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Content = styled.div`
    margin-top: 40px;
    margin-left: -10px;
`;


const InlineBox = styled.div`
    width: calc(33% - 20px);
    box-sizing: border-box;
    margin: 10px;
    padding: 20px;
    background: #212330;
    border-radius: 12px;
    display: inline-block;
    color: white;
    vertical-align: top;
    height: 230px;
`;


const Control = styled.div`
    width: 16px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;


const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: #FFFFFF;
`;

const TopRight = styled.div`
    
`;

const Button = styled.div`
    background: #24B6BC;
    border-radius: 5px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 46px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
  
    cursor: pointer;
    
    :hover{
      opacity: 0.9;
    }
`;
