import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ReactSlider from 'react-slider'
import Select from 'react-select'
import {Input} from "../../ui/Input";
import {SketchPicker} from 'react-color';

const BOOL_OPTIONS = [
    {
        label: 'True',
        value: true
    },
    {
        label: 'False',
        value: false
    },

]

export default function ParamsConfigurator(props) {
    const {
        params = [],
        optionsData = {},
        onUpdate = newOptionsData => {

        }
    } = props;

    return (
        <Wrapper>

            <ParamsListPlaceholder>
                {params.map((p, j) => {
                    let val = (optionsData[p.name] == undefined) ? p.defaultValue : optionsData[p.name];
                    return (
                        <ParamItem key={p.id} onClick={() => {

                        }}>

                            <Label>
                                {`${p.label}`}
                                <CSpan>
                                    {val}
                                </CSpan>
                            </Label>

                            {p.type != 'number' ? null :
                                <div>
                                    <StyledSlider
                                        step={((+p.max - +p.min)) / 1.0}
                                        min={(+p.min) * 100.0}
                                        max={(+p.max) * 100.0}
                                        value={val * 100.0}
                                        renderTrack={Track}
                                        renderThumb={Thumb}
                                        onAfterChange={a => {
                                            let d = {...optionsData};
                                            d[p.name] = a / 100.0;
                                            onUpdate(d);
                                        }}
                                    />
                                </div>
                            }
                            {p.type != 'boolean' ? null :
                                <div>
                                    <Select options={BOOL_OPTIONS}
                                            value={BOOL_OPTIONS.filter(a => (a.value == val))[0]}
                                            onChange={a => {
                                                let d = {...optionsData};
                                                d[p.name] = a.value;
                                                onUpdate(d);
                                            }}
                                    />
                                </div>
                            }

                            {p.type != 'color' ? null :
                                <div>
                                    <SketchPicker color={val} onChangeComplete={a => {
                                        let d = {...optionsData};
                                        d[p.name] = a.hex;
                                        onUpdate(d);
                                    }}/>
                                </div>
                            }

                        </ParamItem>
                    )
                })}
            </ParamsListPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 10px;
`;

const ParamsListPlaceholder = styled.div`
    
`;

const ParamItem = styled.div`
    padding: 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 50px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const Label = styled.div`
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 12.5px;
`;

const StyledThumb = styled.div`
    margin-top: -6.25px;

    height: 25px;
    line-height: 25px;
    width: 25px;
    
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
`;

const CSpan = styled.div`
    font-weight: normal;
    opacity: 0.5;
    margin-left: 5px;
`;

// const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;
const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => props.index === 2 ? '#f00' : props.index === 1 ? '#ddd' : 'green'};
    border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index}/>;
