import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import Spin from 'arui-feather/spin'

import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import './style.css'
import WaveHelper from "../../../helpers/WaveHelper";
import CachedAnimationPlayer from "./CachedAnimationPlayer";

import {Button} from "../../ui/Button";
import CommonHelper from "../../../helpers/CommonHelper";
import Tabs, {TabItem} from "../../ui/Tabs";
import UpdateParamForm from "../../arts/forms/UpdateParamForm";
import ParamsEditor from "../../arts/tools/ParamsEditor";
import UpdateArtForm from "../../arts/forms/UpdateArtForm";
import ParamsConfigurator from "../../arts/tools/ParamsConfigurator";
import DraggableWaveLayer from "../../arts/view/DraggableWaveLayer";


export default function SimpleWaveEditor(props) {
    const {
        loading = false,
        onSave = d => {

        },
        onDelete = () => {

        },
        onDuplicate = () => {

        }

    } = props;
    const defParams = props.params == undefined ? [] : props.params;

    const [code, setCode] = useState(props.code == undefined ? WaveHelper.deafultWaveCode : props.code);
    const [appliedCode, setAlliedCode] = useState(props.code == undefined ? WaveHelper.deafultWaveCode : props.code);
    const [params, setParams] = useState(defParams);

    const [customOptions, setCustomOptions] = useState({...WaveHelper.paramsToOptionsMap(defParams)});
    const [ready, setReady] = useState(false);

    return (
        <Wrapper>
            <Left>

                <TopLeftPlaceholder>
                    <BackSpan onClick={() => {
                        CommonHelper.linkTo('/arts');
                    }}>
                        {'<<<'}
                    </BackSpan>
                    <NameSpan>
                        {props.name}
                    </NameSpan>
                </TopLeftPlaceholder>

                <Tabs tabs={[
                    {
                        label: 'Code',
                        content: (
                            <TabItem>
                                <div style={{textAlign: 'right'}}>
                                    <BackSpan onClick={() => {
                                        setAlliedCode(code);
                                    }}>
                                        refresh
                                    </BackSpan>
                                </div>
                                <Editor
                                    value={code}
                                    onValueChange={c => {
                                        setCode(c)
                                    }}
                                    highlight={code => highlight(code, languages.js)}
                                    padding={10}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                        fontSize: 12,
                                    }}
                                />
                            </TabItem>
                        )
                    },
                    {
                        label: 'Params',
                        content: (
                            <TabItem>
                                <ParamsEditor loading={loading}
                                              params={params}
                                              onSave={nParams => {
                                                  setParams(nParams);
                                                  onSave({params: nParams});
                                                  setAlliedCode(appliedCode + ' ');
                                                  // setCode(code + ' ');
                                              }}/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Settings',
                        content: (
                            <TabItem>
                                <UpdateArtForm {...props}
                                               loading={loading}
                                               params={params}
                                               canDelete={true}
                                               canDuplicate={true}
                                               onSave={d => {
                                                   onSave(d);
                                               }}
                                               onDelete={() => {
                                                   onDelete();
                                               }}
                                               onDuplicate={() => {
                                                   onDuplicate();
                                               }}

                                />
                            </TabItem>
                        )
                    }
                ]}/>


            </Left>
            <Right >
                <div style={{height: 400}} key={appliedCode} >
                    <CachedAnimationPlayer
                        width={400}
                        height={400}
                        drawFunction={window.eval(`(${appliedCode})`)}
                        options={{...WaveHelper.paramsToOptionsMap(params), ...customOptions}}
                        onReady={() => {
                            setReady(true);
                        }}
                    />
                </div>

                {/*<DraggableWaveLayer*/}
                    {/*width={500}*/}
                    {/*height={500}*/}
                    {/*waveHeight={500}*/}
                    {/*waveWidth={500}*/}
                    {/*drawFunction={window.eval(`(${appliedCode})`)}*/}
                    {/*options={{...WaveHelper.paramsToOptionsMap(params), ...customOptions}}*/}
                    {/*onReady={() => {*/}
                        {/*// setReady(true);*/}
                    {/*}}*/}
                {/*/>*/}

                <RightOptPlaceholder  >
                    <ParamsConfigurator optionsData={customOptions} params={params} onUpdate={d => {
                        console.log('ParamsConfigurator: d = ', d);
                        setCustomOptions(d);
                        setAlliedCode(appliedCode + ' ');
                    }} />

                    <Button onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSave({code: code});
                    }}>
                        <Spin visible={loading}/>
                        Save
                    </Button>
                </RightOptPlaceholder>

            </Right>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
`;

const Left = styled.div`
    flex: 1;
    background: white;
    overflow-y: auto;
`;

const Right = styled.div`
    background: whitesmoke;
    box-sizing: border-box;
    padding: 0px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const RightOptPlaceholder = styled.div`
    height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    flex: 1;
    background: grey;
    padding: 5px;
`;

const TopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid darkgray;
    padding-bottom: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
`;

const BackSpan = styled.span`
    cursor: pointer;
    margin-right: 5px;
`;

const NameSpan = styled.span`
    font-weight: bold;
`;
