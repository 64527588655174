import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Handles, Slider, Tracks } from "react-compound-slider";


export const SliderBar = (props) => {
  const { onChange = () => {}, value = 0, from = 0, to = 100, step = 1} = props;

  const sliderStyle = {
    // Give the slider some width
    position: "relative",
    width: "100%",
    height: 50,
    display: "flex",
  };

  const railStyle = {
    position: "absolute",
    width: "100%",
    height: 5,
    marginTop: 35,
    borderRadius: 9,
    background: "rgba(255,231,196,0.38)",
  };

  const Handle = ({ handle: { id, value, percent }, getHandleProps }) => {
    return (
      <Bullet value={value} percent={percent} {...getHandleProps(id)}>
        <div className={"value"}>
          {/*{value} */}
        </div>
      </Bullet>
    );
  };

  const Track = ({ source, target, getTrackProps }) => {
    return (
      <TackLine
        left={source.percent}
        width={target.percent - source.percent}
        {
          ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
        }
      />
    );
  };

  return (
    <Wrapper>
      <Slider
        rootStyle={sliderStyle}
        domain={[from, to]}
        step={step}
        mode={2}
        values={[value]}
        onChange={(value) => onChange(Array.isArray(value) ? value[0] : value)}
      >
        <div style={railStyle} />
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
`;

const TackLine = styled.div`
  position: absolute;
  height: 5px;
  z-index: 1;
  margin-top: 35px;
  background-color: rgb(255, 200, 120);
  border-radius: 5px;
  cursor: pointer;
  left: ${(props) => props.left}%;
  width: ${(props) => props.width}%;
`;


const Bullet = styled.div`
  left ${(props) => `${props.percent}%`};
  position: absolute;
  margin-left: -15px;
  margin-top: 25px;
  z-index: 2;
  width: 25px;
  height: 25px;
  border: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background: #ffac31;
  box-shadow: 0 0 6px rgba(36, 78, 178, 0.5);
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  
    :hover {
      box-shadow: 0px 0px 3px 5px #ffac31;
    }
  
   :after {
     content: '';
     background-repeat: no-repeat;
     background-size: contain;
     width: 13px;
     height: 13px;
     position: absolute;
     right: -20px;
   }

  :before {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    width: 13px;
    height: 13px;
    position: absolute;
    left: -20px;
  }
  
  .value {
    font-size: ${(props) =>
      props?.value >= 1000 ? "9px" : "12px"};
    display: flex;
    align-items: center;
    color: #FFFFFF;

  }
`;
