import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UserSubscriptionsPanel from "../../subscriptions/panels/UserSubscriptionsPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import * as actions from "../../../redux/actions/PlansActions";
import CoolModal from "../../modals/CoolModal";
import BillingAPI from "../../../api/BillingAPI";
import SidebarContentTemplate from "../../templates/SidebarContentTemplate";

export default function UserBillingPanel(props) {
    const {heading = 'Billing'} = props;
    const {loading, plans, isAdmin, currentUser, currentPlanId, currentPlan} = useMappedState(useCallback(state => {
        return {
            loading: state.users.loading || state.plans.loading,
            plans: state.plans.plansMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentPlanId: state.plans.currentPlanId,
            currentPlan: state.plans.plansMap.get(state.plans.currentPlanId)
        }
    }, []));

    const [unsModalVisible, setUnsModalVisible] = useState(false);
    const [unsubscribing, setUnsubscribing] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllPlans());
    }, []);

    useEffect(() => {
        try {
            window.mixpanel.track('BillingPageVisit');
        } catch (exc) {

        }
    }, []);

    console.log('UserBillingPanel: render: currentPlan, currentPlanId = ', currentPlan, currentPlanId);
    console.log('all plans = ', plans);

    return (
        <SidebarContentTemplate>

            <TopPlaceholder>
                <TopName>
                    {heading}
                </TopName>
                <TopRight>

                    {currentPlan == undefined ? null :
                        <UnsubscribePlaceholder onClick={() => {
                            setUnsModalVisible(true);
                        }}>
                            Unsubscribe
                        </UnsubscribePlaceholder>
                    }

                    <UpdateButton onClick={() => {
                        CommonHelper.linkTo(`/pricing`);
                    }}>
                        Update plan
                    </UpdateButton>
                </TopRight>
            </TopPlaceholder>

            <Content>
                <UserSubscriptionsPanel/>
            </Content>

            {unsModalVisible == false ? null :
                <CoolModal onClose={() => {
                    setUnsModalVisible(false);
                }}>

                    <UnsText>
                        Are you sure? Your credits will be lost!
                    </UnsText>

                    <UpdateButton onClick={async () => {
                        setUnsubscribing(true);
                        let link = await BillingAPI.getCurrentUserSubLink();
                        console.log('link = ', link);
                        setUnsubscribing(false);
                        try {
                            let {cancel_url} = link;
                            let win = window.open(cancel_url, '_blank');
                            win.focus();
                        } catch (ee) {

                        }
                    }}>
                        {unsubscribing == true ? <Spin theme={'alfa-on-color'} visible={true}/> : 'Unsubscribe!'}
                    </UpdateButton>

                </CoolModal>
            }

        </SidebarContentTemplate>
    );
}

const UnsText = styled.div`
    color: white;
    font-size: 17px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
`;

const UpdateButton = styled.div`
    background: #EB5E4F;
    border-radius: 5px;
    color: white;
    height: 46px;
    padding-left: 34px;
    padding-right: 34px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    font-weight: bold;
    :hover{
      opacity: 0.9;
    }
`;

const UnsubscribePlaceholder = styled.div`
    margin-right: 26px;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const Wrapper = styled.div`
    width: 100%;
`;

const Content = styled.div`
    
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const TopName = styled.div`
    font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 46px;
/* identical to box height, or 135% */


color: #FFFFFF;

`;

const TopRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
