import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {API_ENDPOINT} from "../../constants/config";

import AdminServersPanel from "../servers/panels/AdminServersPanel";
import SubtitlesTemplate from "../templates/SubtitlesTemplate";

export default function AdminSubscriptionsApp() {

    return (
        <SubtitlesTemplate active={'servers'}>

            <AdminServersPanel/>

        </SubtitlesTemplate>
    );
}

const DownloadAppPlaceholder = styled.div`
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const Link = styled.a`
    color: white;
    opacity: 0.5;
    :hover{
      opacity: 1;
    }
`;
