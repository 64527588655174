import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import parseSRT from 'parse-srt'

export default function UploadSrtForm(props) {
    const {
        onSubtitlesUpdate = subs => {

        }
    } = props;

    const [text, setText] = useState('');
    const [error, setError] = useState(undefined);

    return (
        <Wrapper>

            <Heading>
                Please past here the content of your .SRT file to replace existing subtitles
            </Heading>

            <TextareaPlaceholder>
                <Textarea autoFocus={true} value={text} onChange={evt => {
                    setText(evt.target.value);
                }}/>
            </TextareaPlaceholder>

            {error == undefined ? null :
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            }

            <div style={{marginTop: 10}}>
                <Button onClick={() => {
                    setError(undefined);
                    try {
                        let jsonSubs = parseSRT(text);
                        console.log('jsonSubs = ', jsonSubs);
                        let arr = jsonSubs.map(x => ({id: uuid(), from: x.start, to: x.end, text: x.text}));
                        onSubtitlesUpdate(arr);
                    } catch (exc) {
                        setError('Incorrect SRT content');
                    }
                }}>
                    Apply
                </Button>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    color: white;
`;

const ErrorMessage = styled.div`
    color: red;
    padding: 5px;
    text-align: center;
`;

const Heading = styled.div`
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
`;

const TextareaPlaceholder = styled.div`
    
`;

const Textarea = styled.textarea`
    outline: none;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    border: 1px solid #3a3a3c;
    background: transparent;
    color: white;
    box-sizing: border-box;
    font-size: 14px;
    padding: 3px;
    border-radius: 5px;
    min-height: 8em;
    :focus{
      outline: none;
      border-color: #FFAC30;
      background: #212330;
    }
`;

const Button = styled.div`
    font-weight: bold;
    background: #EA523D;
    border-radius: 5px;
    color: white;
    height: 48px;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 25px;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;
