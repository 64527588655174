import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Analytics} from "../../../utils/AnalyticsUtility";

export default function ProjectItemView(props) {
    const {
        name = '', thumbnail, onGo = () => {

        }, width, height,
        onEdit = () => {

        },
        onRename = () => {

        },
        onDuplicate = () => {

        },
        onDelete = () => {

        },
        onShare = () => {

        },
        onDownload = () => {

        },
        onDownloadSrt = () => {

        },
        onImportSrt = () => {

        },
        hasWatermark,
        paid = true
    } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const node = useRef();
    const handleClick = e => {
        if (node == undefined || node.current == undefined) {
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        setDropdownVisible(false);
    };


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Wrapper>
            <ThumbPlaceholder onClick={() => {
                onGo();
            }}>
                {thumbnail == undefined ?
                    <VoidPlaceholder>
                        <Logo src={require('../../../assets/images/sub_logo_.svg')}/>
                    </VoidPlaceholder>
                    :
                    <ThumbImage src={thumbnail}>
                    </ThumbImage>
                }
            </ThumbPlaceholder>
            <InfoPlaceholder>
                <MetaPlaceholder>
                    <Name>
                        {name}
                    </Name>
                    {width == undefined || height == undefined ? null :
                        <SizeInfoPlaceholder>
                            {`${width}x${height}`}
                        </SizeInfoPlaceholder>
                    }
                </MetaPlaceholder>
                <Control ref={node}>
                    <DotsImg src={require('../../../assets/images/vert_dots.svg')} onClick={() => {
                        setDropdownVisible(!dropdownVisible);
                    }}/>
                    {dropdownVisible == false ? null :
                        <DropdownPlaceholder>
                            <DropdownItem onClick={() => {
                                onEdit();
                            }}>
                                Edit
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                Analytics.send('editor.download_video', 'editor');
                                onDownload();
                            }}>
                                Download Video
                            </DropdownItem>

                            <DropdownItem style={{opacity: (hasWatermark != true) ? 1 : 0.5}} onClick={() => {
                                Analytics.send('editor.download_srt', 'editor');
                                onDownloadSrt();
                            }}>
                                {hasWatermark != true ? 'Download .SRT' : '$ Download .SRT'}
                            </DropdownItem>


                            <DropdownItem onClick={() => {
                                onRename();
                            }}>
                                Rename
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                onDuplicate();
                            }}>
                                Duplicate
                            </DropdownItem>

                            <DropdownItem onClick={() => {
                                onImportSrt();
                            }}>
                                Import .SRT
                            </DropdownItem>

                            {/*<DropdownItem onClick={() => {*/}
                            {/*onShare();*/}
                            {/*}}>*/}
                            {/*Share*/}
                            {/*</DropdownItem>*/}
                            <DropdownItem onClick={() => {
                                onDelete();
                            }}>
                                <DeleteSpan>
                                    Delete
                                </DeleteSpan>
                            </DropdownItem>
                        </DropdownPlaceholder>
                    }
                </Control>
            </InfoPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
`;

const Logo = styled.img`
    height: 80px;
`;

const VoidPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const ThumbPlaceholder = styled.div`
    flex: 1;
    width: 100%;
    cursor: pointer;
    :hover{
      opacity: 0.9;
    }
`;

const ThumbImage = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
`;

const InfoPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;
`;

const MetaPlaceholder = styled.div`
    flex: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
    width: 100%;
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
    box-sizing: border-box;
    padding-right: 7px;
    max-width: 100%;
    //word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Control = styled.div`
    width: 16px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;


const DotsImg = styled.img`
    width: 5px;
    height: 16px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const SizeInfoPlaceholder = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #7B7F9E;
`;

const DropdownPlaceholder = styled.div`
    position: absolute;
    top: 23px;
    z-index: 1000;
    right: 0px;
    width: 180px;
    background: #3F435A;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 15px;
`;

const DropdownItem = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 36px;
    color: #FFFFFF;
    text-align: right;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const DeleteSpan = styled.span`
    color: #EA523D;
`;
